import { memo } from 'react';
import styled from '@emotion/styled';
import { PlusCircle } from 'react-feather';
import { useTheme } from '@/components/Theme';
import { shouldForwardProp } from '@/utils';

type Props = {
  disabled?: boolean;
  onClick: () => void;
};

export const AddCustomFilter = memo(({ disabled, onClick }: Props) => {

  const theme = useTheme();

  return (
    <Root>
      <Button disabled={disabled} onClick={disabled ? undefined : onClick}>
        <StyledPlusCircle color={disabled ? theme.palette.gray.main : theme.palette.primary.main} />
        Add Filter
      </Button>
    </Root>
  );
});

const Button = styled('div', { shouldForwardProp: shouldForwardProp('disabled') })<{ disabled: boolean }>(({ theme, disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  color: disabled ? theme.palette.gray.main : theme.palette.primary.main,
  fontFamily: theme.fonts.semiBold,
  fontSize: 15,
}));

const StyledPlusCircle = styled(PlusCircle)(({ theme }) => ({
  marginRight: 8,
}));

const Root = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 12,
  borderTop: `1px solid ${theme.palette.gray.light1}`,
  width: '100%',
}));