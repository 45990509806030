import type { KeyboardEventHandler } from 'react';
import { useCallback, useContext, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { KolSearchStatus } from '@/enums/kol';
import { AnalysisQueryHint } from '../presentation';
import {
  DispatchKolSearchFormContext,
  KolSearchStatusContext,
  KolSearchSubmitContext,
  KolSearchFormContext,
} from './context';
import { BasicSystemUserMessage } from './Message.System.Basic';

export const KolSearchFormMessage = () => {
  const searchStatus = useContext(KolSearchStatusContext);
  const handleSubmit = useContext(KolSearchSubmitContext);
  const formState = useContext(KolSearchFormContext);
  const setFormState = useContext(DispatchKolSearchFormContext);
  const inputFocused = useRef(false);

  const copy = `Let's get started. First lets find the HCP you'd like to profile!`;
  const instructions = `Enter details to find the HCP you would like to profile.`;

  const submitDisabled = useMemo(() => {
    return !formState.name || searchStatus !== KolSearchStatus.FormEntry;
  }, [formState.name, searchStatus]);

  const inputDisabled = useMemo(() => {
    return searchStatus !== KolSearchStatus.FormEntry;
  }, [searchStatus]);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(e => {

    if (!inputFocused.current) return;

    if (submitDisabled) return;

    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  }, [submitDisabled, handleSubmit]);

  const handleInputBlur = useCallback(() => {
    inputFocused.current = false;
  }, []);

  const handleInputFocus = useCallback(() => {
    inputFocused.current = true;
  }, []);

  return (
    <MessageContainer>
      <BasicSystemUserMessage>
        <Body>
          {copy}
          <Divider />
          <Instructions>{instructions}</Instructions>
          <Form>
            <FormItem>
              <Label>Name</Label>
              <Input
                disabled={inputDisabled}
                onKeyDown={handleKeyDown}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                type="text"
                placeholder="Name"
                value={formState.name}
                onChange={e => setFormState({ ...formState, name: e.target.value })} />
            </FormItem>
          </Form>
          <Divider />
          <AnalysisQueryHint
            disabled={submitDisabled}
            onClick={handleSubmit}
            value="Search for profiles." />
        </Body>
      </BasicSystemUserMessage>
    </MessageContainer>
  );
};

const Body = styled.div({
  width: 550,
});

const MessageContainer = styled.div({
  paddingRight: 20,
  paddingLeft: 20,
});

const Divider = styled.div(({ theme }) => ({
  width: '100%',
  height: 2,
  backgroundColor: theme.palette.gray.light2,
  margin: '15px 0',
}));

const Instructions = styled.div(({ theme }) => ({
  fontFamily: theme.fonts.semiBold,
  marginBottom: 20,
}));

const Label = styled.div(({ theme }) => ({
  fontFamily: theme.fonts.semiBold,
  width: 100,
}));

const Form = styled.div(({ theme }) => ({

}));

const FormItem = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  '&:not(:last-child)': {
    marginBottom: 15,
  },
}));

const Input = styled.input(({ theme }) => ({
  fontSize: 16,
  padding: 8,
  // width: 250,
  width: '100%',
  borderRadius: 5,
  border: `2px solid ${theme.palette.gray.light1} `,
  fontFamily: theme.fonts.regular,

  '&:focus': {
    borderColor: theme.palette.blue.main,
  },

  '&::placeholder': {
    // color: theme.palette.gray.light1,
    fontFamily: theme.fonts.regular,
  },
}));