import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import * as api from '@/api';
import type * as App from '@/api/interfaces/app';

export const useRemoveLogoMutation = <Context = unknown>(options: RemoveLogo.Options<Context> = {}) => {

  const mutation = useMutation({
    mutationKey: [`delete:app/theming/logo`],
    mutationFn: (params: RemoveLogo.Variables) => {
      return api.removeLogo({
        orientation: params.orientation,
      });
    },
    ...options,
  });

  return mutation;
};

export const useUpdateLogoMutation = (options: UpdateLogo.Options = {}) => {

  const mutation = useMutation({
    mutationKey: [`post:app/theming/logo`],
    mutationFn: (params: UpdateLogo.Variables) => {
      return api.updateLogo({
        blob: params.blob,
        orientation: params.orientation,
      });
    },
    ...options,
  });

  return mutation;
};

export declare namespace RemoveLogo {
  export type Variables = App.RemoveLogo.Request;

  export type Options<Context = unknown> = {
    onMutate?: (variables: Variables) => unknown;
  } & Omit<
        UseMutationOptions<
          App.RemoveLogo.Response,
          unknown,
          Variables,
          Context>, 'onMutate'>;
}

export declare namespace UpdateLogo {
  export type Variables = App.UpdateLogo.Request;

  export type Options =
    UseMutationOptions<
      App.UpdateLogo.Response,
      unknown,
      Variables>;
}