import { memo } from 'react';
import styled from '@emotion/styled';
import { shouldForwardProp } from '@/utils/emotion';

export type ChipProps = {
  selected: boolean;
};

export const Chip = memo(
  styled('div', { shouldForwardProp: shouldForwardProp('selected') }) <ChipProps>`
    display: inline-flex;
    border-style: solid;
    border-width: 1.5px;
    border-radius: 20px;
    font-size: 12px;
    font-family: ${({ theme }) => theme.fonts.semiBold};
    transition: background-color 200ms ease-in-out, border 140ms ease-in, border-color 140ms ease-in, color 140ms ease-in;
    line-height: 19px;

    background-color: ${({ selected, theme }) => selected ? theme.palette.btnPrimary.bg : 'transparent'};
    border-color: ${({ selected, theme }) => selected ? theme.palette.btnPrimary.border : theme.palette.gray.light1};
    color: ${({ selected, theme }) => selected ? theme.palette.btnPrimary.color : theme.palette.gray.dark1};

    cursor: pointer;
    padding: 6px;

    &:hover {
      color: ${({ theme }) => theme.palette.btnPrimary.active.color};
      background-color: ${({ theme }) => theme.palette.btnPrimary.active.bg};
      border-color: ${({ theme }) => theme.palette.btnPrimary.active.border};
    }
  `
);