import { useCallback } from 'react';
import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { PrimaryButton, SecondaryButton } from '@/components/presentation';
import type { Chat } from '@/types';
import type { BaseModalProps } from '@/hooks/useModal';
import { useModal } from '@/hooks/useModal';
import { useDeleteChatInstanceMutation } from './hooks';

type Props = {
  chatInstance: Pick<Chat.Instance, 'id' | 'name'>;
} & BaseModalProps;

export const DeleteChatModal = ({ chatInstance, open, onClose }: Props) => {

  const mutation = useDeleteChatInstanceMutation({ chatInstanceId: chatInstance.id, onSuccess: onClose });

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleConfirmation = useCallback(() => {
    return mutation.mutateAsync();
  }, [mutation]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="confirmationDialog"
      onClose={onClose}
      open={open}>

      <Title>
        <TitleLine1>Are you sure you want to delete</TitleLine1>
        <TitleLine2>&quot;{chatInstance.name || 'Untitled Chat'}&quot;</TitleLine2>
      </Title>

      <Footer>
        <SecondaryButton
          onClick={handleCancel}>
          Cancel
        </SecondaryButton>
        <PrimaryButton
          onClick={handleConfirmation}>
          Confirm
        </PrimaryButton>
      </Footer>

    </Dialog>
  );
};

const Title = styled(DialogTitle)`
  color: ${props => props.theme.palette.text.primary};
  font-family: ${props => props.theme.fonts.bold};
  padding: 20px 24px;
`;

const TitleLine1 = styled.div`
  font-size: 22px;
  text-align: center;
  line-height: 30px;
  margin-bottom: 8px;
`;

const TitleLine2 = styled.div`
  font-size: 18px;
  text-align: center;
  line-height: 22px;
`;

const Footer = styled(DialogActions)`
  display: 'flex';
  justify-content: center;
  padding: 20px;
`;

export const useDeleteChatModal = () => useModal(DeleteChatModal);