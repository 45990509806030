import { useCallback, useContext, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LinkContext, LinkDispatchContext } from '@/components/BrandInsights/context';
import { useLoadChatInstance } from '@/components/Chat.History/hooks';
import { ChatHistoryManagerContext } from '@/components/Chat.History/context';
import { ActiveChatSessionContext } from '@/components/Chat/context';
import { useIsFullPageChat } from '@/components/Chat/hooks';

export function useMonitorChatIdentifier() {
  const initializedIdentifier = useRef<string>(null);

  const link = useContext(LinkContext);
  const dispatch = useContext(LinkDispatchContext);
  const historyManager = useContext(ChatHistoryManagerContext);
  const location = useLocation();
  const session = useContext(ActiveChatSessionContext);
  const navigate = useNavigate();

  const handleChatLoaded = useCallback(() => {
    dispatch({
      type: 'update-chat-initialized',
      payload: {
        error: false,
      },
    });
  }, [dispatch]);

  const handleChatLoadError = useCallback(() => {
    dispatch({
      type: 'state-reset',
      payload: {
        chatIdentifier: null,
        initialized: true,
        error: false,
      },
    });
  }, [dispatch]);

  const loadChat = useLoadChatInstance({
    onError: handleChatLoadError,
    onSuccess: handleChatLoaded,
  });

  const isFullPageChat = useIsFullPageChat();

  // Updates the url to reflect the active session's chat identifier
  useEffect(() => {
    if (session?.identifier && link.initialized) {
      // console.log('Session updated, redirecting to chat', session.identifier);
      if (isFullPageChat) {
        navigate(`/chat/${session.identifier}`, {
          replace: true,
        });
      } else {
        // history.replace(location.pathname + '#bi:ocid:' + session.identifier);
      }
    }
  }, [isFullPageChat, navigate, location.pathname, link.initialized, session?.identifier]);

  // Loads chat instance one time from the first chat identifier
  useEffect(() => {
    if (!historyManager.initialized || !link.chatIdentifier) return;

    if (!initializedIdentifier.current || initializedIdentifier.current !== link.chatIdentifier) {
      initializedIdentifier.current = link.chatIdentifier;

      loadChat(link.chatIdentifier);
    }
  }, [
    loadChat,
    historyManager.initialized,
    link.chatIdentifier,
  ]);
}