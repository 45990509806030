import { forwardRef, useCallback, useContext, useState } from 'react';
import format from 'date-fns/format';
import styled from '@emotion/styled';
import { Constants } from '@/components/Theme';
import { ActiveChatSessionContext } from '@/components/Chat/context';
import { ChatHistoryItemContext } from './context';
import { useLoadChatInstance } from './hooks';
import { SharedChatInfo } from './Item.Actions';
import { ChatActionsAnchor, ChatActionsMenu } from './Item.ContextMenu';

type Props = {
  className?: string;
  isActive: boolean;
  isHovering: boolean;
};

export const ChatHistoryItem = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { item } = useContext(ChatHistoryItemContext);
  const session = useContext(ActiveChatSessionContext);

  const [menuOpen, setMenuOpen] = useState(false);
  const [actionsRefElement, setActionsRefElement] = useState<HTMLDivElement>(null);

  const loadInstance = useLoadChatInstance();

  const handleTileClick = useCallback((e: React.MouseEvent) => {
    if (item.identifier === session.identifier) return;
    loadInstance(item.identifier);
  }, [item.identifier, loadInstance, session.identifier]);

  const handleOpenMenu = useCallback((e: React.MouseEvent) => {
    setMenuOpen(true);
    e.stopPropagation();
  }, []);

  const name = item.name || 'Untitled Chat';

  return (
    <>
      <Root
        ref={ref}
        className={props.className}
        onClick={handleTileClick}>
        <Wrap>
          <Tile isActive={props.isActive}>
            <Title>{name}</Title>

            <Footer>
              <Date>
                {format(item.createdOn, 'MMM dd, yyyy')}
              </Date>

              <Actions>
                {item.shared && <StyledSharedInfoIcon />}
                <ChatActionsAnchor
                  ref={setActionsRefElement}
                  open={menuOpen}
                  onClick={handleOpenMenu} />
              </Actions>
            </Footer>
          </Tile>
        </Wrap>
      </Root>
      <ChatActionsMenu
        open={menuOpen}
        setOpen={setMenuOpen}
        refElement={actionsRefElement} />
    </>
  );
});

const Root = styled.div`
  &:not(:last-child) {
    margin-bottom: ${Constants.Sidebar.ItemVerticalPadding}px;
  }
`;

const Wrap = styled.div`
  box-sizing: border-box;
  display: flex;
  margin: 0 ${Constants.Sidebar.ItemHorizontalPadding}px;
`;

const Actions = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const Tile = styled.div<TileProps>`
  box-sizing: border-box;
  border: 1.5px solid ${props => props.isActive ? props.theme.palette.gray.main : props.theme.palette.gray.light4};
  border-radius: 6px;
  background-color: ${props => props.isActive ? props.theme.palette.gray.light1 : 'transparent'};
  cursor: ${props => props.isActive ? 'default' : 'pointer'};
  padding: 10px;
  min-height: 65px;
  width: 100%;

  transition: border 0.3s;

  &:hover {
    border: 1.5px solid ${props => props.theme.palette.gray.main};
  }
`;

const Title = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  color: theme.palette.black.light1,
  fontFamily: theme.fonts.semiBold,
  fontSize: 16,
}));

const Date = styled('div')(({ theme }) => ({
  color: theme.palette.gray.dark1,
  fontFamily: theme.fonts.regular,
  fontSize: 16,
  dispaly: 'flex',
  width: '50%',
}));

const Footer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginTop: 20,
});

const StyledSharedInfoIcon = styled(SharedChatInfo)({
  marginRight: 5,
});

type TileProps = {
  isActive: boolean;
};