export enum KolSearchStatus {
  FormEntry = 'form-entry',
  FormSubmitted = 'form-submitted',
  FormSubmissionErrored = 'form-submission-errored',
  NoResultsReturned = 'no-results-returned',
  SingleResultReturned = 'single-result-returned',
  MultipleResultsReturned = 'results-returned',
  SearchCancelled = 'search-cancelled',
  ProfileSelectionSubmitted = 'profile-selection-submitted',
  ProfileSelected = 'profile-selected',
  ProfileSelectionErrored = 'profile-selection-errored',
  ProfileRecommendations = 'profile-recommendations',
  QuestionsBank = 'questions-bank',
}