import { createElement, useMemo } from 'react';
import { cx } from '@/utils';
import { Button } from '@/components/presentation/Button';
import type { ButtonProps } from '@/components/presentation/Button/interfaces';
import { AnimatedLoader, type AnimatedLoaderProps } from '@/components/presentation/AnimatedLoader';

type Props = {
  classes?: {
    root?: string;
    indicator?: string;
  };
  as?: React.ComponentType<AsProps>;
  indicatorColor?: string;
  indicatorSize?: number;
  loading?: boolean;
} & ButtonProps;

export const ButtonActivityIndicator = ({ as: component = null, children, classes = {}, loading = false, indicatorColor, indicatorSize, style = {}, variant = 'brick', color = 'primary', implicitDisable = true, ...props }: Props) => {
  const Indicator = useMemo(() => {
    if (component) {
      return () => createElement(component, {
        color: indicatorColor,
        size: indicatorSize,
      });
    }

    return () => (
      <ActivityIndicator
        className={classes.indicator}
        color={color}
        indicatorColor={indicatorColor}
        indicatorSize={indicatorSize} />
    );
  }, [
    classes.indicator,
    component,
    indicatorColor,
    indicatorSize,
    color,
  ]);

  const content = loading
    ? createElement(Indicator)
    : children;

  return (
    <Button
      {...props}
      variant={variant}
      color={color}
      implicitDisable={implicitDisable}
      className={cx(classes.root, props.className)}
      disabled={props.disabled ?? loading}
      style={style}>
      {content}
    </Button>
  );
};

ButtonActivityIndicator.displayName = 'Button.ActivityIndicator';

type AsProps =
  & Pick<AnimatedLoaderProps, 'color' | 'size'>
  & Record<string, unknown>;

type ActivityIndicatorProps =
  Pick<Props,
    | 'className'
    | 'color'
    | 'indicatorColor'
    | 'indicatorSize'>;

const ActivityIndicator = (props: ActivityIndicatorProps) => {
  const color = useMemo(() => {
    const defaultColor = {
      affirmative: `var(--pri-01)`,
      primary: `var(--pri-01)`,
      secondary: `var(--pri-02)`,
      destructive: `var(--gray-d)`,
      transparent: `var(--blue-l)`,
      delete: `var(--pri-04)`,
    }[props.color];

    return props.indicatorColor ?? defaultColor;
  }, [
    props.color,
    props.indicatorColor,
  ]);

  return (
    <AnimatedLoader
      className={props.className}
      color={color}
      size={props.indicatorSize} />
  );
};

export type { Props as ButtonActivityIndicatorProps };