/** @jsxImportSource @emotion/react */
import { Global, css } from '@emotion/react';

const GlobalStyles = css`
  *, *::before, *::after {
    box-sizing: border-box;
  }
`;

export const GlobalStylesContainer = (props: ChildrenProps) => {

  return (
    <>
      <Global styles={GlobalStyles} />
      {props.children}
    </>
  );
};