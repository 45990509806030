import EventEmitter from 'events';

export interface BaseStorageProvider extends Storage {
  addListener(listener: (e: AppStorageEvent) => void): EventEmitter;
  removeListener(listener: (e: AppStorageEvent) => void): EventEmitter;
}

export interface AppStorageEvent {
  key: string | null;
  oldValue: string | null;
  newValue: string | null;
}

class StorageEventEmitter {
  #eventEmitter: EventEmitter;

  constructor() {
    this.#eventEmitter = new EventEmitter();
  }

  emit(e: AppStorageEvent) {
    return this.#eventEmitter.emit('storage', e);
  }

  addListener(listener: (e: AppStorageEvent) => void) {
    return this.#eventEmitter.addListener('storage', listener);
  }

  removeListener(listener: (e: AppStorageEvent) => void) {
    return this.#eventEmitter.removeListener('storage', listener);
  }
}

class LocalStorageProvider extends StorageEventEmitter implements BaseStorageProvider {
  key(index: number): string | null {
    return localStorage.key(index);
  }

  setItem(key: string, value: string): void {
    const oldValue = this.getItem(key);
    localStorage.setItem(key, value);
    this.emit({
      key,
      oldValue,
      newValue: value,
    });
  }

  removeItem(key: string): void {
    const oldValue = this.getItem(key);
    localStorage.removeItem(key);
    this.emit({
      key,
      oldValue,
      newValue: null,
    });
  }

  getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  clear(): void {
    localStorage.clear();
    this.emit({
      key: null,
      oldValue: null,
      newValue: null,
    });
  }

  get length() {
    return localStorage.length;
  }
}

class SessionStorageProvider extends StorageEventEmitter implements BaseStorageProvider {
  key(index: number): string | null {
    return sessionStorage.key(index);
  }

  setItem(key: string, value: string): void {
    const oldValue = this.getItem(key);
    sessionStorage.setItem(key, value);
    this.emit({
      key,
      oldValue,
      newValue: value,
    });
  }

  removeItem(key: string): void {
    const oldValue = this.getItem(key);
    sessionStorage.removeItem(key);
    this.emit({
      key,
      oldValue,
      newValue: null,
    });
  }

  getItem(key: string): string | null {
    return sessionStorage.getItem(key);
  }

  clear(): void {
    sessionStorage.clear();
    this.emit({
      key: null,
      oldValue: null,
      newValue: null,
    });
  }

  get length() {
    return sessionStorage.length;
  }
}

class MemoryStorageProvider extends StorageEventEmitter implements BaseStorageProvider {
  #storage: Map<string, string>;

  constructor() {
    super();
    this.#storage = new Map();
  }

  key(index: number): string | null {
    const keys = Array.from(this.#storage.keys());
    if (index >= keys.length) return null;
    return keys[index];
  }

  setItem(key: string, value: string): void {
    const oldValue = this.getItem(key);
    this.#storage.set(key, value);
    this.emit({
      key,
      oldValue,
      newValue: value,
    });
  }

  removeItem(key: string): void {
    const oldValue = this.getItem(key);
    this.#storage.delete(key);
    this.emit({
      key,
      oldValue,
      newValue: null,
    });
  }

  getItem(key: string): string | null {
    if (this.#storage.has(key)) return this.#storage.get(key);
    return null;
  }

  clear(): void {
    this.#storage.clear();
    this.emit({
      key: null,
      oldValue: null,
      newValue: null,
    });
  }

  get length() {
    return this.#storage.size;
  }
}

function hasLocalStorage() {
  try {
    const key = `_${Date.now()}`;
    localStorage.setItem(key, key);
    localStorage.removeItem(key);
    return true;
  } catch {
    return false;
  }
}

function hasSessionStorage() {
  try {
    const key = `_${Date.now()}`;
    sessionStorage.setItem(key, key);
    sessionStorage.removeItem(key);
    return true;
  } catch {
    return false;
  }
}

let localStorageInstance: BaseStorageProvider = null;
export function getLocalStorage() {
  if (localStorageInstance) return localStorageInstance;
  localStorageInstance = hasLocalStorage()
    ? new LocalStorageProvider()
    : new MemoryStorageProvider();
  return localStorageInstance;
}

let sessionStorageInstance: BaseStorageProvider = null;
export function getSessionStorage() {
  if (sessionStorageInstance) return sessionStorageInstance;
  sessionStorageInstance = hasSessionStorage()
    ? new SessionStorageProvider()
    : new MemoryStorageProvider();
  return sessionStorageInstance;
}