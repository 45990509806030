import { useCallback, useContext, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ChatHintType } from '@/enums/chat';
import type { Chat } from '@/types';
import * as $api from '@/api';
import {
  ActiveChatSessionContext,
  AuthorContext,
  ChatHintsContext,
  ChatHintsLoadingContext,
} from './context';

type Props = ChildrenProps;

export const ChatHintsContainer = (props: Props) => {
  const chat = useContext(ActiveChatSessionContext);
  const author = useContext(AuthorContext);

  const query = useQuery({
    queryKey: ['chat-hints', chat.context, author.id],
    queryFn: () => {
      return $api.getChatHints({
        context: chat.context,
        userId: author.id,
      });
    },
    enabled: false,
  });

  const items: Chat.QueryHint.Item[] = useMemo(() => {
    const hints: Chat.QueryHint.Item[] = (query.data?.items || []).map(hint => ({ ...hint, disabled: false }));
    return hints;
  }, [
    query.data?.items,
  ]);

  return (
    <ChatHintsContext.Provider value={items}>
      <ChatHintsLoadingContext.Provider value={query.isLoading}>
        {props.children}
      </ChatHintsLoadingContext.Provider>
    </ChatHintsContext.Provider>
  );
};