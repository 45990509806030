import type { ReactNode } from 'react';
import { useMemo } from 'react';
import styled from '@emotion/styled';
import type { Chat } from '@/types';
import { ChatHintType } from '@/enums/chat';
import * as Presentation from './presentation';

type Props = {
  className?: string;
  hint: Chat.QueryHint.Item;
  onClick: () => unknown;
  disabled?: boolean;
};

export const MessageQueryHint = (props: Props) => {
  switch (props.hint.type) {
    case ChatHintType.FindQuotes: return <AnalysisQueryHint {...props} value={props.hint.displayValue} />;
    case ChatHintType.AnalysisTags: return <AnalysisQueryHint {...props} value={props.hint.displayValue} />;
    case ChatHintType.Text: return <TextQueryHint {...props} value={props.hint.displayValue} />;
    default: throw new UnreachableCaseError(props.hint.type);
  }
};

type TextHintProps = {
  className?: string;
  onClick: () => unknown;
  disabled?: boolean;
  value: ReactNode;
};

export const TextQueryHint = ({ className, value, disabled, onClick }: TextHintProps) => {
  return (
    <Presentation.Root className={className} onClick={disabled ? undefined : onClick}>
      <TextHintWrap isDisabled={disabled}>
        <TextHintIcon isDisabled={disabled} /> {value}
      </TextHintWrap>
    </Presentation.Root>
  );
};

type AnalysisProps = {
  className?: string;
  onClick: () => unknown;
  disabled?: boolean;
  value: string;
};

export const AnalysisQueryHint = ({ className, value, disabled, onClick }: AnalysisProps) => {
  return (
    <Presentation.Root className={className} onClick={disabled ? undefined : onClick}>
      <AnalysisWrap isDisabled={disabled}>
        <AnalysisIcon isDisabled={disabled} /> {value}
      </AnalysisWrap>
    </Presentation.Root>
  );
};

export const TextHintWrap = styled(Presentation.Wrap)(({ isDisabled, theme }) => {

  const { Text } = useMemo(() => Presentation.Colors(theme), [
    theme,
  ]);

  const values = {
    enabled: Text.enabled,
    disabled: Text.disabled,
  };

  return {
    backgroundColor: `${isDisabled ? values.disabled.backgroundColor : values.enabled.backgroundColor}`,
    '&:hover': {
      backgroundColor: `${isDisabled ? values.disabled.hover.backgroundColor : values.enabled.hover.backgroundColor}`,
    },
  };
});

export const TextHintIcon = styled(Presentation.TextStyledIcon)(({ isDisabled, theme }) => {

  const { TextIcon } = useMemo(() => Presentation.Colors(theme), [
    theme,
  ]);

  const values = {
    enabled: TextIcon.enabled,
    disabled: TextIcon.disabled,
  };

  return {
    color: `${isDisabled ? values.disabled.color : values.enabled.color}`,
  };
});

const AnalysisWrap = styled(Presentation.Wrap)(({ isDisabled, theme }) => {

  const { Analysis } = useMemo(() => Presentation.Colors(theme), [
    theme,
  ]);

  const values = {
    enabled: Analysis.enabled,
    disabled: Analysis.disabled,
  };

  return {
    backgroundColor: `${isDisabled ? values.disabled.backgroundColor : values.enabled.backgroundColor}`,
    '&:hover': {
      backgroundColor: `${isDisabled ? values.disabled.hover.backgroundColor : values.enabled.hover.backgroundColor}`,
    },
  };
});

const AnalysisIcon = styled(Presentation.AnalysisStyledIcon)(({ isDisabled, theme }) => {

  const { AnalysisIcon } = useMemo(() => Presentation.Colors(theme), [
    theme,
  ]);

  const values = {
    enabled: AnalysisIcon.enabled,
    disabled: AnalysisIcon.disabled,
  };

  return {
    color: `${isDisabled ? values.disabled.color : values.enabled.color}`,
  };
});