import { useQueryDownloader } from '@/containers/QueryDownloader/hooks';
import * as $api from '@/api/kol';

type Params = {
  kol: {
    id: number;
    name: string;
  };
};

export const useDownloadKolProfile = (params: Params) => {

  return useQueryDownloader({
    queryKey: [
      `kol-profile:download`,
      params.kol.id,
    ],
    queryFn: ({ queryKey: [, kolId] }) => {
      return $api.downloadKolProfile({ kolId });
    },
    meta: {
      downloader: true,
    },
    downloaderOptions: {
      onResponse: res => ({
        filename: `HCP 360 Profile: ${params.kol.name}.docx`,
        type: 'blob',
        value: res.data,
      }),
    },
  });

};