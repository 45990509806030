namespace BuildBrandInsightsChatLink {
  export type Params = {
    chatIdentifier: string;
    isStandaloneChat: boolean;
  };
}

export function buildBrandInsightsChatLink({ chatIdentifier, isStandaloneChat }: BuildBrandInsightsChatLink.Params) {
  const url = new URL(window.location.href);

  if (!isStandaloneChat) {
    return `${url.origin}${url.pathname}${url.search}#bi:ocid:${chatIdentifier}`;
  }

  return `${url.origin}/chat/${chatIdentifier}`;
}

namespace BuildGoogleMapsAddrssLink {
  export type Params = {
    address: string;
  };
}

export function buildGoogleMapsAddressLink({ address }: BuildGoogleMapsAddrssLink.Params) {
  const encoded = encodeURIComponent(address).replaceAll('%20', '+');
  return `https://www.google.com/maps/search/?api=1&query=${encoded}`;
}

namespace BuildKolMailTo {
  export type Params = {
    email: string;
  };
}

export function buildKolMailTo({ email }: BuildKolMailTo.Params) {
  return `mailto:${email}`;
}