import { useContext, useEffect, useMemo, useReducer } from 'react';
import {
  ChatSessionInputContext, ChatSessionQueriesContext, ActiveChatSessionContext,
  ChatPageContextDataContext, ChatSessionSelectedQueryContext, ChatSessionFiltersContext,
  ChatSessionFileUploadsContext,
  ChatSessionKolSearchesContext,
} from '@/components/Chat/context';
import { ChatHistoryItemsContext, ChatHistoryManagerContext, ChatHistorySharedContext } from '@/components/Chat.History/context';
import { ChatStateDispatchContext } from './context';
import { chatReducer, generateEmptyChatState } from './state';

export type Props = {
  children: React.ReactNode;
};

export const ChatStateContainer = ({ children }: Props) => {
  const [state, dispatch] = useReducer(chatReducer, generateEmptyChatState());
  const context = useContext(ChatPageContextDataContext);

  const memoizedContext = useMemo(() => context, [context]);

  useEffect(() => {
    dispatch({
      type: 'reset-chat-state',
      payload: {
        context: memoizedContext,
      },
    });
  }, [memoizedContext, dispatch]);

  const selectedQuery = useMemo(() => {
    return state.session.queries[state.session.selectedQueryIdentifier];
  }, [state.session.queries, state.session.selectedQueryIdentifier]);

  return (
    <ChatStateDispatchContext.Provider value={dispatch}>
      <ChatSessionKolSearchesContext.Provider value={state.session.kolSearches}>
        <ChatSessionQueriesContext.Provider value={state.session.queries}>
          <ChatSessionInputContext.Provider value={state.session.input}>
            <ChatSessionFiltersContext.Provider value={state.session.filters}>
              <ActiveChatSessionContext.Provider value={state.session.chatInstance}>
                <ChatSessionSelectedQueryContext.Provider value={selectedQuery}>
                  <ChatHistoryItemsContext.Provider value={state.history.items}>
                    <ChatHistorySharedContext.Provider value={state.history.shared}>
                      <ChatHistoryManagerContext.Provider value={state.history.manager}>
                        <ChatSessionFileUploadsContext.Provider value={state.session.fileUploads}>
                          {children}
                        </ChatSessionFileUploadsContext.Provider>
                      </ChatHistoryManagerContext.Provider>
                    </ChatHistorySharedContext.Provider>
                  </ChatHistoryItemsContext.Provider>
                </ChatSessionSelectedQueryContext.Provider>
              </ActiveChatSessionContext.Provider>
            </ChatSessionFiltersContext.Provider>
          </ChatSessionInputContext.Provider>
        </ChatSessionQueriesContext.Provider>
      </ChatSessionKolSearchesContext.Provider>
    </ChatStateDispatchContext.Provider>
  );
};