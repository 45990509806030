import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useAppSelector } from '@/store';
import { AppReadyStateContext, ReadyStateSubscriberContext, ReadyStateSubscriptionContext } from './Context';
import type { ReadyStateChangeHandler, ReadyStateRun, ReadyStateSubscriber } from './interfaces';
import { ReadyState } from './interfaces';

type Props = {
  children: React.ReactElement;
};

const SubscriberContainer = (props: Props) => {

  const run = useContext(ReadyStateSubscriberContext);

  const authenticated = useAppSelector(state => state.appState.authenticated);
  const authenticatedRef = useRef(authenticated);

  const error = useAppSelector(state => state.appState.error);
  const errorRef = useRef(error);

  const initialized = useAppSelector(state => state.appState.initialized);
  const initializedRef = useRef(initialized);

  const hydrated = useAppSelector(state => !!state.user?.id);
  const hydratedRef = useRef(hydrated);

  const hydrating = useAppSelector(state => state.appState.authenticated && !state.user?.id);

  useEffect(() => {

    if (error) {
      run(ReadyState.Error);
    } else {
      if (!authenticatedRef.current && authenticated) {
        run(ReadyState.UserSignedIn);
      }

      if (!initializedRef.current && initialized && !hydrated) {
        run(ReadyState.NoCredentialsFound);
      }

      if (initializedRef.current && authenticatedRef.current && !authenticated) {
        run(ReadyState.UserSignedOut);
      }

      if (!hydratedRef.current && hydrated) {
        run(ReadyState.AppDataLoaded);
      }

      if (hydratedRef.current && !hydrated) {
        run(ReadyState.AppDataReset);
      }
    }

    authenticatedRef.current = authenticated;
    errorRef.current = error;
    hydratedRef.current = hydrated;
    initializedRef.current = initialized;

  }, [run, authenticated, error, hydrated, initialized]);

  const state = useMemo(() => ({
    authenticated,
    error,
    hydrated,
    hydrating,
    initialized,
  }), [
    authenticated,
    error,
    hydrated,
    hydrating,
    initialized,
  ]);

  return (
    <AppReadyStateContext.Provider value={state}>
      {props.children}
    </AppReadyStateContext.Provider>
  );
};

SubscriberContainer.displayName = 'AppReadyState.Subscriber';

const SubscriptionContainer = (props: Props) => {
  const handlers = useRef({
    [ReadyState.AppDataLoaded]: new Set<ReadyStateChangeHandler>(),
    [ReadyState.AppDataReset]: new Set<ReadyStateChangeHandler>(),
    [ReadyState.Error]: new Set<ReadyStateChangeHandler>(),
    [ReadyState.NoCredentialsFound]: new Set<ReadyStateChangeHandler>(),
    [ReadyState.UserSignedIn]: new Set<ReadyStateChangeHandler>(),
    [ReadyState.UserSignedOut]: new Set<ReadyStateChangeHandler>(),
  }).current;

  const run = useCallback<ReadyStateRun>(state => {
    handlers[state].forEach(fn => fn());
  }, [handlers]);

  const on = useCallback<ReadyStateSubscriber>((state, fn) => {
    handlers[state].add(fn);
  }, [handlers]);

  const off = useCallback<ReadyStateSubscriber>((state, fn) => {
    handlers[state].delete(fn);
  }, [handlers]);

  return (
    <ReadyStateSubscriptionContext.Provider value={{ off, on }}>
      <ReadyStateSubscriberContext.Provider value={run}>
        {props.children}
      </ReadyStateSubscriberContext.Provider>
    </ReadyStateSubscriptionContext.Provider>
  );
};

SubscriptionContainer.displayName = 'AppReadyState.Subscription';

export const AppReadyStateContainer = (props: Props) => {
  return (
    <SubscriptionContainer>
      <SubscriberContainer>
        {props.children}
      </SubscriberContainer>
    </SubscriptionContainer>
  );
};