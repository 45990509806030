import { useCallback, useContext } from 'react';
import { useDownloader } from '@/hooks/useDownloader';
import type { Reports } from '@/websocket/interfaces';
import { FileDownloadActivityItem } from '@/components/FileDownloadActivity';
import { AsyncActivity } from '@/enums/reports';
import { QueryDownloaderContext } from './Context';
import { SocketSubscriber } from './SocketSubscriber';
import type { DownloaderItem } from './interfaces';

type Props = {
  index: number;
  item: DownloaderItem;
};

export const QueryDownloaderItem = ({ index, item }: Props) => {
  const { remove, set } = useContext(QueryDownloaderContext);
  const download = useDownloader();

  const { extension, id, status, websocketKey } = item;

  const onRemove = useCallback(() => {
    remove(id);
  }, [id, remove]);

  const handleComplete = useCallback((event: Reports.ReportAvailable.Payload) => {
    if (event.type === AsyncActivity.FileDownload) {
      download({
        url: event.data.url,
      });
      set(id, {
        status: `transferring`,
      });
    }
  }, [download, id, set]);

  const handleError = useCallback(() => {
    set(id, {
      status: `error`,
      websocketKey: null,
    });
  }, [
    id,
    set,
  ]);

  const renderItem = useCallback(() => {
    return (
      <FileDownloadActivityItem
        extension={extension}
        filename={item.name}
        index={index}
        onCancel={onRemove}
        onRemove={onRemove}
        status={status}
        title={item.title} />
    );
  }, [
    extension,
    index,
    item.name,
    item.title,
    onRemove,
    status,
  ]);

  if (!websocketKey) {
    return renderItem();
  }

  return (
    <SocketSubscriber
      onError={handleError}
      onReady={handleComplete}
      socketKey={websocketKey}>
      {renderItem()}
    </SocketSubscriber>
  );
};

QueryDownloaderItem.displayName = 'QueryDownloader.Item';