import { RootVendors } from '@/containers/Subscriber/RootVendors';
import { NavBar } from '@/components/Nav/NavBar';
import { AppSubscriber } from '@/containers/Subscriber/AppSubscriber';
import { AppRoutes } from './Routes';

export const Layout = () => {

  return (
    <RootVendors>
      <AppSubscriber>
        <div>

          <NavBar />

          <AppRoutes />
        </div>
      </AppSubscriber>
    </RootVendors>
  );
};