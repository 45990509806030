import { MediaOrientation } from '@/enums';
import { getAuthenticatedClient, getUnauthenticatedClient } from './client';
import type * as AppApi from './interfaces/app';
import { transformDates } from './transformers';

export const getAppState = async () => {
  const res = await getAuthenticatedClient().get<AppApi.GetAppState.Response>('/app/state', {
    transformResponse: transformDates,
  });
  return res.data;
};

export const getAppTheming = async () => {
  return getUnauthenticatedClient().get<AppApi.GetAppTheming.Response>('/app/theming')
    .then(res => res.data);
};

export const updateAppTheming = async (data: AppApi.UpdateAppTheming.Request) => {
  return getAuthenticatedClient().patch<AppApi.UpdateAppTheming.Response>('/app/theming', data);
};

export const removeLogo = (data: AppApi.RemoveLogo.Request) => {
  const orientation = data.orientation === MediaOrientation.Horizontal ? 'horizontal' : 'square';

  return getAuthenticatedClient().delete<AppApi.RemoveLogo.Response>(`/app/theming/logo/${orientation}`)
    .then(res => res.data);
};

export const updateLogo = (data: AppApi.UpdateLogo.Request) => {
  const formData = new FormData();
  formData.append('blob', data.blob);

  const orientation = data.orientation === MediaOrientation.Horizontal ? 'horizontal' : 'square';

  return getAuthenticatedClient().post<AppApi.UpdateLogo.Response>(`/app/theming/logo/${orientation}`, formData, {
    headers: {
      'Content-Type': `multipart/form-data`,
    },
    transformRequest: () => formData,
  })
    .then(res => res.data);
};

export const updateThemeColor = (data: AppApi.UpdateThemeColor.Request) => {
  return getAuthenticatedClient().put<AppApi.UpdateThemeColor.Response>(`/app/theming/color`, data)
    .then(res => res.data);
};