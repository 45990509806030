import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';
import { X } from 'react-feather';
import type { BaseModalProps } from '@/hooks/useModal';

type Props = BaseModalProps;

export const ChatGuidelinesModal = ({ open, onClose }: Props) => {

  const guidelinesHref = 'https://jnj.sharepoint.com/sites/LawCenter/JJ%20Online%20Engagement%20Policy/Guidelines%20on%20the%20Responsible%20Use%20of%20Generative%20AI%20at%20Johnson%20and%20Johnson_FINAL.pdf';

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      onClose={onClose}
      open={open}>

      <Header>
        <Title>Usage Guidelines</Title>
        <StyledX onClick={onClose} />
      </Header>

      <Content>

        <Copy>As you engage with our Gen AI, please adhere to the following:</Copy>

        <StyleUL>
          <StyledLi>All content shared is for internal use only and should not be distributed, shared or otherwise communicated with customers.</StyledLi>
          <StyledLi>Gen AI should not be used to generate or distribute content violating our Code of Business Conduct, Privacy Policies, Information Asset Protection Policies (IAPPs), Open-Source Licensing Policy, and other relevant policies.</StyledLi>
          <StyledLi>Do not disclose confidential information or personal data through Gen AI.</StyledLi>
          <StyledLi>Gen AI's output is not a substitute for professional or legal advice. All generated content should be reviewed and substantiated against known risks, including inaccuracy, bias, and accountability.</StyledLi>
          <StyledLi>We encourage you to review our
            <StyledA
              href={guidelinesHref}
              target="_blank"
              rel="noopener noreferrer">Guidelines on the Responsible Use of Generative AI
            </StyledA>
          </StyledLi>
        </StyleUL>

        <BottomCopy>Your interaction with Gen AI signifies your understanding and agreement to these terms.</BottomCopy>

      </Content>
    </Dialog>
  );
};

const StyledX = styled(X)({
  cursor: 'pointer',
  position: 'absolute',
  top: 15,
  right: 10,
  height: 24,
  width: 24,
});

const Header = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: `15px 10px`,
  borderBottom: `1px solid ${theme.palette.gray.dark1}`,
  position: 'relative',
}));

const Title = styled.div(({ theme }) => ({
  fontFamily: theme.fonts.bold,
  fontSize: 20,
  color: theme.palette.black.main,
}));

const Content = styled.div({
  padding: 30,
});

const Copy = styled.div(({ theme }) => ({
  marginBottom: 15,
  fontFamily: theme.fonts.semiBold,
}));

const BottomCopy = styled.div(({ theme }) => ({
  fontFamily: theme.fonts.semiBold,
}));

const StyledA = styled.a(({ theme }) => ({
  color: theme.palette.hyperlink,
  textDecoration: 'underline',
  marginLeft: 5,
}));

const StyleUL = styled.ul({
  paddingInlineStart: 16,
});

const StyledLi = styled.li({
  marginBottom: 15,
});