import { useCallback } from 'react';
import * as Sentry from '@sentry/react';
import { useAppSelector } from '@/store';

export const useInitSentry = () => {
  const email = useAppSelector(state => state.user?.email);
  const id = useAppSelector(state => state.user?.id);

  return useCallback(() => {
    Sentry.setUser({ email, id });
  }, [email, id]);
};