import { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { getLastLoginStrategy } from '@/auth/session';
import { EnterpriseLogin } from './Login.Enterprise';
import { PasswordLogin } from './Login.Password';

export const LoginScreen = () => {

  const [strategy, setStrategy] = useState<'standard' | 'enterprise'>(getLastLoginStrategy()?.strategy || 'enterprise');

  const toggleStrategy = useCallback(() => {
    setStrategy(strategy => strategy === 'standard' ? 'enterprise' : 'standard');
  }, []);

  return (
    <Root>
      <Wrap>

        <Card>
          <Title>Log In <StrategyToggleText onClick={toggleStrategy}>{`(${strategy === 'standard' ? 'Enterprise' : 'Password'} login)`}</StrategyToggleText></Title>

          {strategy === 'enterprise' && <EnterpriseLogin />}
          {strategy === 'standard' && <PasswordLogin />}
        </Card>

      </Wrap>
    </Root>
  );
};

const Root = styled.div({
  height: `calc(100vh - var(--navbar-height))`,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  paddingTop: 100,
});

const Wrap = styled.div({
});

const StrategyToggleText = styled.span(({ theme }) => ({
  fontSize: 12,
  cursor: 'pointer',
  color: theme.palette.hyperlink,
  marginLeft: 5,
}));

const Title = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 20,
  fontFamily: theme.fonts.semiBold,
  color: theme.palette.black.main,
  marginBottom: 20,
}));

const Card = styled.div({
  width: 450,
  padding: 20,
  borderRadius: 5,
  boxShadow: '0 0 10px rgba(0,0,0,0.1)',
  backgroundColor: 'white',
});

const Footer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 20,
});