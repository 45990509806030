import queryString from 'query-string';
import { getAuthenticatedClient } from './client';
import type * as ChatApi from './interfaces/chat-instances';
import { transformChatInstance, transformChatSession, transformChatShareableUsers } from './transformers';

export const getChatInstance = async (data: ChatApi.GetChatInstance.Request) => {
  const res = await getAuthenticatedClient().get<ChatApi.GetChatInstance.Response>(`/chat/${data.chatInstanceIdentifier}`);
  return transformChatSession(res.data);
};

export const renameChatInstance = async ({ chatInstanceId, ...data }: ChatApi.RenameChatInstance.Request) => {
  const res = await getAuthenticatedClient().patch<ChatApi.RenameChatInstance.Response>(`/chat/${chatInstanceId}/name`, data);
  return {
    chatInstance: transformChatInstance(res.data.chatInstance),
  };
};

export const deleteChatInstance = async (data: ChatApi.DeleteChatInstance.Request) => {
  const res = await getAuthenticatedClient().delete<ChatApi.RenameChatInstance.Response>(`/chat/${data.chatInstanceId}`);
  return res.data;
};

export const getChatShareableUsers = async (data: ChatApi.GetChatShareableUsers.Request) => {
  const qs = queryString.stringify({
    search: data.search,
  }, {
    skipNull: true,
  });

  const res = await getAuthenticatedClient().get<ChatApi.GetChatShareableUsers.Response>(`/chat/${data.chatInstanceId}/shareable-users?${qs}`);
  return {
    items: transformChatShareableUsers(res.data),
  };
};

export const shareChatInstance = async (data: ChatApi.ShareChatInstance.Request) => {
  return getAuthenticatedClient().post(`/chat/${data.chatInstanceId}/share`, {
    standaloneLink: data.standaloneLink,
    userIds: data.userIds,
  });
};

export const downloadChatInstance = async (data: ChatApi.DownloadChatInstance.Request) => {
  return getAuthenticatedClient().get<Blob>(`/chat/${data.chatInstanceId}/report`, {
    responseType: 'blob',
  });
};