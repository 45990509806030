import { memo } from 'react';
import { ExternalLink } from 'react-feather';
import styled from '@emotion/styled';
import { Tooltip } from '@/components/presentation/Tooltip';

type Props = {
  className?: string;
  url: string;
};

export const GoToTraceUrl = memo(({ className, url }: Props) => {

  return (
    <Tooltip title="View Langfuse Trace">
      <a
        className={className}
        href={url}
        target="_blank"
        rel="noreferrer">
        <StyledLinkIcon size={18} />
      </a>
    </Tooltip>
  );
});

const StyledLinkIcon = styled(ExternalLink)(({ theme }) => ({
  color: theme.palette.badge.citation.color,
}));