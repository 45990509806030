import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';
import type { BrandInsightsCapabilities } from '@/types';
import type { ILink } from './interfaces';

type SideBarExpandedContextValue = [boolean, Dispatch<SetStateAction<boolean>>];

export const SideBarExpandedContext = createContext<SideBarExpandedContextValue>(null);

export const LinkContext = createContext<ILink.LinkContextValue>(undefined);
export const LinkDispatchContext = createContext<Dispatch<ILink.Action>>(undefined);
export const CapabilitiesContext = createContext<BrandInsightsCapabilities>({
  canApplyFilters: false,
  canViewTraces: false,
  canViewExternalLinks: false,
});