import { useContext, useMemo } from 'react';
import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { LoginScreen } from '@/screens/Login';
import { LogoutScreen } from '@/screens/Logout';
import {
  OAuth2,
  OAuth2Logout,
} from '@/screens/Inbound';
import { ChatScreen } from '@/screens/Chat';
import { AppInitializing } from '@/screens/Initializing';
import { Branding } from '@/screens/Branding';
import { useAppSelector } from './store';
import { AppThemingContext } from './containers/AppTheming/Context';
import { OAuth2Login } from './screens/Inbound/Auth.OAuth2.Login';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const AppRoutes = () => {

  const appState = useAppSelector(state => state.appState);
  const { query } = useContext(AppThemingContext);
  const defaultPath = appState.authenticated ? '/chat' : '/_login';

  const brandingInitialized = useMemo(() => !query.isInitialLoading && !query.isFetching, [query.isInitialLoading, query.isFetching]);
  const appInitialized = useMemo(() => appState.initialized && brandingInitialized, [appState.initialized, brandingInitialized]);

  return (
    <SentryRoutes>
      {appState.authenticated && (
        <>
          <Route
            path="/chat/:chatIdentifier?"

            element={appInitialized ? <ChatScreen /> : <AppInitializing />} />
          <Route
            path="/branding"
            element={appInitialized ? <Branding /> : <AppInitializing />} />
        </>
      )}
      {!appState.authenticated && <Route path="/_login" element={<OAuth2Login />} />}
      {!appState.authenticated && <Route path="/login" element={brandingInitialized ? <LoginScreen /> : <AppInitializing />} />}
      {appState.authenticated && <Route path="/logout" element={<LogoutScreen />} />}

      <Route path="/_/auth/oauth2/logout" element={<OAuth2Logout />} />
      <Route path="/_/auth/oauth2/:identifier" element={<OAuth2 />} />

      <Route path="*" element={<Navigate to={defaultPath} />} />
    </SentryRoutes>
  );
};