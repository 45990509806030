import { useCallback, useMemo } from 'react';
import { ChatDateFilter, chatDateFiltersMap } from '@/enums/chat.filters';
import type { CustomFilter } from '@/types/chat.filters';
import { CustomFilterSingleSelect } from './CustomFilter.SingleSelect';

type Props = {
  onSelect: (value: ChatDateFilter) => void;
  options?: ChatDateFilter[];
  value: ChatDateFilter;
};

export const CustomFilterDateFilter = ({
  onSelect,
  options = [
    ChatDateFilter.Last7Days,
    ChatDateFilter.Last30Days,
    ChatDateFilter.Last90Days,
    ChatDateFilter.LastYear,
  ],
  value,
}: Props) => {

  const items = useMemo(() => {
    return options.map(o => ({
      id: o,
      name: chatDateFiltersMap[o],
    }));
  }, [options]);

  const handleSelect = useCallback((value: CustomFilter.ItemValue) => {
    onSelect(value.id as ChatDateFilter);
  }, [onSelect]);

  const selectedValue = useMemo(() => {
    return items.find(i => i.id === value);
  }, [value, items]);

  return (
    <CustomFilterSingleSelect
      placeholder="Select time range"
      isSearchable={false}
      onSelect={handleSelect}
      options={items}
      value={selectedValue} />
  );
};