import { useCallback, useMemo } from 'react';
import Select from 'react-select';
import type { ActionMeta, SingleValue } from 'react-select';
import { useTheme } from '@/components/Theme';
import type { CustomFilter } from '@/types/chat.filters';
import { buildSelectStyles, buildSelectTheme } from './Presentation';

type Props = {
  isSearchable?: boolean;
  onSelect: (value: Item) => void;
  options: Item[];
  placeholder: string;
  value: Item;
};
type Item = CustomFilter.SingleSelectFilter;

export function CustomFilterSingleSelect({ isSearchable, onSelect, options, placeholder, value }: Props) {

  const theme = useTheme();

  const selectStyles = useMemo(() => buildSelectStyles(theme), [theme]);
  const selectTheme = useMemo(() => buildSelectTheme(theme), [theme]);

  const handleChange = useCallback((value: SingleValue<Item>, meta: ActionMeta<Item>) => {
    onSelect(value);
  }, [onSelect]);

  return (
    <Select<Item>
      styles={selectStyles}
      isSearchable={isSearchable}
      theme={selectTheme}
      value={value}
      options={options}
      placeholder={placeholder}
      getOptionLabel={o => o.name}
      getOptionValue={o => `${o.id}`}
      onChange={handleChange}
      menuPlacement="auto" />
  );
}