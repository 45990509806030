import { useEffect, useState } from 'react';

type Dimensions = {
  height: typeof window.innerHeight;
  width:  typeof window.innerWidth;
};

export const useDimensions = () => {
  const [state, setState] = useState<Dimensions>({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    const handler = () => {
      setState({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };

    window.addEventListener('resize', handler);
    window.addEventListener('orientationchange', handler);

    return () => {
      window.removeEventListener('resize', handler);
      window.removeEventListener('orientationchange', handler);
    };
  }, []);

  return state;
};