import { Controls } from './Branding.Switch';
import { Logo } from './Logo';
import { Theme } from './Theme';
import styles from './style/Branding.css';

type Props = unknown;

export const Branding = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>

          <div className={styles.header}>
            <h1 className={styles.title}>{`Themes & Branding`}</h1>
            <Controls />
          </div>
          <div className={styles.row}>
            <Logo />
          </div>
          <div className={styles.row}>
            <Theme />
          </div>
        </div>
      </div>
    </div>
  );
};

Branding.displayName = 'Branding';