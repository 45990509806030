import { useCallback, useEffect, useState } from 'react';
import { useQuery, focusManager } from '@tanstack/react-query';
import * as $session from '@/auth/session';
import * as $sessionHttp from '@/auth/session-http';
import useStorageListener from '@/hooks/useStorageListener';
import { getLocalStorage, type AppStorageEvent } from '@/lib/storage';
import { useAppSelector } from '@/store';

type Props = {
  enabled: boolean;
};

export function useValidSessionChecker({ enabled }: Props) {
  const userId = useAppSelector(state => state.user?.id);
  const [focused, setFocused] = useState<boolean>(focusManager.isFocused());

  const query = useQuery({
    queryKey: ['valid-session-checker'],
    queryFn: () => $sessionHttp.getSessionInfo(),
    refetchInterval: () => focused ? false : 15 * 60 * 1000,
    refetchIntervalInBackground: true,
    staleTime: 1000,
    enabled,
  });

  useEffect(() => {
    if (!query.data) return;

    if (!query.data.authenticated || !query.data.userId || query.data.userId !== userId) {
      window.location.reload();
    }
  }, [query.data, userId]);

  const handleFocusChange = useCallback(() => setFocused(focusManager.isFocused()), []);

  const handleStorageChange = useCallback((e: AppStorageEvent) => {
    if (!e.key && !e.oldValue && !e.newValue) {
      window.location.reload();
    } else {
      if (e.key === $session.LocalStorageKeys.SessionMeta) {
        if (e.oldValue && !e.newValue) {
          window.location.reload();
        }
      }
    }
  }, []);

  useEffect(() => {
    const unsubscribe = focusManager.subscribe(handleFocusChange);
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useStorageListener(getLocalStorage(), handleStorageChange);
}