import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useAppReadyState } from '@/containers/AppReadyState/hooks';
import { useLogoutMutation } from '@/containers/AppStateEffect/hooks';
import { Spinner } from '@/components/presentation/Spinner';

export function OAuth2Logout() {
  const navigate = useNavigate();
  const logoutMutation = useLogoutMutation();
  const app = useAppReadyState();

  useEffect(() => {
    if (app.initialized) {
      logoutMutation.mutateAsync()
        .then(() => navigate('/', { replace: true }));
    }
  }, [app.initialized, navigate, logoutMutation]);

  return (
    <Root>
      <Spinner />
    </Root>
  );
}

const Root = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '80vh',
});