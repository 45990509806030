import { useCallback, useRef, useState, useEffect } from 'react';

export function useHover<T extends HTMLElement = HTMLDivElement>(enabled = true) {
  const [hovering, setHovering] = useState(false);
  const ref = useRef<T>(null);

  const handleMouseEnter = useCallback(() => {
    setHovering(true);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setHovering(false);
  }, []);

  useEffect(() => {
    const node = ref.current;

    if (node && enabled) {
      node.addEventListener('mouseenter', handleMouseEnter);
      node.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        node.removeEventListener('mouseenter', handleMouseEnter);
        node.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, [enabled, handleMouseEnter, handleMouseLeave]);

  return [ref, hovering] as const;
}