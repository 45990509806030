import { Fragment, useEffect, useCallback } from 'react';
import { useReportsSocket } from '@/websocket/hooks';
import type { Reports } from '@/websocket/interfaces';
import { ReportSocketEvent } from '@/enums/websocket';

type Props = {
  children: React.ReactElement;
  onError: () => void;
  onReady: (data: unknown) => void; // (data: Reports.ReportAvailable.Payload) => void;
  socketKey: string;
};

export const SocketSubscriber = ({ socketKey, onReady, onError, ...props }: Props) => {

  const readyWrapper = useCallback((data: Reports.ReportAvailable.Payload) => {
    if (data.key === socketKey) onReady(data);
  }, [socketKey, onReady]);

  const errorWrapper = useCallback((data: Reports.ReportError.Payload) => {
    if (data.key === socketKey) onError();
  }, [socketKey, onError]);

  const socket = useReportsSocket(socketKey);

  useEffect(() => {
    if (!socket) return;

    socket.on(ReportSocketEvent.ReportAvailable, readyWrapper);
    socket.on(ReportSocketEvent.ReportError, errorWrapper);

    return () => {
      socket.off(ReportSocketEvent.ReportAvailable, readyWrapper);
      socket.off(ReportSocketEvent.ReportError, errorWrapper);
    };
  }, [errorWrapper, readyWrapper, socket, socketKey]);

  return (
    <Fragment>
      {props.children}
    </Fragment>
  );
};

SocketSubscriber.displayName = 'QueryDownloader.SocketSubscriber';