import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from 'axios';
import * as $api from '@/api';
import type { Chat } from '@/types';
import { ChatStateDispatchContext } from '@/components/Chat.State';
import { ActiveChatSessionContext, ChatSessionFiltersContext } from '../context';
import { useScrollToBottomOfMessages } from './useScrollMessageList';

type MutationVars = {
  identifier: string;
  sourceQuery: Chat.Query;
};

export const useRegenerateQueryMutation = () => {
  const chat = useContext(ActiveChatSessionContext);
  const filters = useContext(ChatSessionFiltersContext);
  const dispatch = useContext(ChatStateDispatchContext);
  const scrollToBottom = useScrollToBottomOfMessages();

  const mutation = useMutation({
    mutationKey: ['chat:message:regenerate'],
    mutationFn: (vars: MutationVars) => {
      return $api.regenerateQuery({
        chatIdentifier: chat.identifier,
        identifier: vars.identifier,
        parentQueryIdentifier: vars.sourceQuery.mapping.parentIdentifier,
        query: vars.sourceQuery.value,
        context: chat.context,
        filters,
      });
    },
    onMutate: vars => {
      dispatch({
        type: 'query-variant-submitted',
        payload: {
          chatIdentifier: chat.identifier,
          identifier: vars.identifier,
          sourceIdentifier: vars.sourceQuery.identifier,
        },
      });
      scrollToBottom();
    }, onError: (error: AxiosError<AxiosResponse>, vars) => {
      if (error.response?.status === 504 || error.status === 504) {
        return;
      }

      const traceId = error.response?.headers['x-sentry-trace-id'] as string;
      dispatch({
        type: 'query-variant-errored',
        payload: {
          queryIdentifier: vars.identifier,
          sourceIdentifier: vars.sourceQuery.identifier,
          traceId,
        },
      });
      scrollToBottom();
    },
    onSuccess: (data, vars) => {
      // dispatch({
      //   type: 'query-variant-response-available',
      //   payload: {
      //     chatInstance: data.chatInstance,
      //     query: data.query,
      //     sourceIdentifier: vars.sourceQuery.identifier,
      //   },
      // });
      // scrollToBottom();
    },
  });

  return mutation;
};