import { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { MessagesListRefContext, SetPendingScrollContext } from './context';
import type { ScrollParams } from './interfaces.context';

export const ChatScrollContainer = (props: ChildrenProps) => {

  const [state, setState] = useState<ScrollParams>(null);
  const scrollToBottom = useScrollToBottomOfMessages();

  const handleSetPendingScroll = useCallback((params?: ScrollParams) => {
    setState(params || {});
  }, []);

  useLayoutEffect(() => {
    if (state) {
      scrollToBottom(state);

      setState(null);
    }
  }, [scrollToBottom, state]);

  return (
    <SetPendingScrollContext.Provider value={handleSetPendingScroll}>
      {props.children}
    </SetPendingScrollContext.Provider>
  );
};

function useScrollToBottomOfMessages() {
  const [messagesRef] = useContext(MessagesListRefContext);

  return useCallback(({
    nthFromBottom,
    ...vars
  }: ScrollParams = {
      behavior: 'auto',
    }) => {
    setTimeout(() => {
      if (!messagesRef?.childElementCount) {
        console.log('No messages to scroll to');
        return;
      }

      if (nthFromBottom) {
        const scrollIndex = messagesRef.children.length - nthFromBottom;
        messagesRef.children[scrollIndex].scrollIntoView(vars);
        return;
      }

      const childScrollIndex = messagesRef.childElementCount >= 3
        ? messagesRef.children.length - 2
        : messagesRef.children.length - 1;

      const lastChild = messagesRef.children[childScrollIndex];

      lastChild.scrollIntoView(vars);
    }, 0);
  }, [messagesRef]);
}