export function parsePhoneDigits(value: string) {
  return ('' + value).replace(/\D/g, '').substring(0, 15);
}

export function formatPhoneNumber(value: string) {
  if (!value) return value;
  const sanitized = parsePhoneDigits(value);

  const match = /^(\+?\d{1,3})?(\d{3})(\d{3})(\d{4})$/.exec(sanitized);
  if (!match) return value;

  return ['+', match[1] ?? '1', ' ', '(', match[2], ') ', match[3], '-', match[4]].join('');
}

export function createOneTapLink(phone: string, pin?: string) {
  return `tel:${phone}${pin ? `,,${pin}#` : ``}`;
}