import { useCallback, useMemo } from 'react';
import Select, { components as ReactSelectComponents } from 'react-select';
import type { ActionMeta, GroupBase, MultiValue, OptionProps } from 'react-select';
import { useTheme } from '@/components/Theme';
import type { RecordFilter } from '@/types/chat.filters';
import * as Presentation from './Presentation';

type Props = {
  readOnly?: boolean;
  value: RecordFilter.Item[];
};

export function Record({ readOnly, value }: Props) {
  const theme = useTheme();

  const selectStyles = useMemo(() => Presentation.buildSelectStyles(theme), [theme]);
  const selectTheme = useMemo(() => Presentation.buildSelectTheme(theme), [theme]);

  const selected = useMemo(() => value, [value]);

  return (
    <Presentation.Row>
      <Presentation.Label>Record</Presentation.Label>
      <Presentation.Value>
        <Select<RecordFilter.Item>
          styles={selectStyles}
          components={{
            DropdownIndicator: null,
          }}
          isDisabled={readOnly}
          theme={selectTheme}
          value={selected}
          // isMulti
          options={[]}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isSearchable={false}
          isClearable={false}
          placeholder={`Search a record...`}
          getOptionLabel={o => o.name}
          getOptionValue={o => o.id}
          menuPlacement="auto" />
      </Presentation.Value>
    </Presentation.Row>
  );
}