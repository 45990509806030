import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { actions } from '@/store/actions';
import * as $sessionHttp from '@/auth/session-http';

export const useLogoutMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return $sessionHttp.logout();
    },
    onSuccess: (res, vars) => {
      Sentry.setUser(null);
      dispatch(actions.logout());
      queryClient.cancelQueries().then(() => queryClient.clear());
    },
  });
};