import { Provider } from 'react-redux';
import { store } from '@/store';

export const ReduxProvider = (props: ChildrenProps) => {

  return (
    <Provider store={store}>
      {props.children}
    </Provider>
  );
};