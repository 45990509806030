import { memo, useEffect, useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { cx } from '@/utils';
import { FileIcon } from '@/components/icons/File';
import styles from './style/FileDownloadActivity.css';

type Props = {
  extension?: string;
  filename?: string;
  index: number;
  onCancel?: () => void;
  onRemove?: () => void;
  status: 'init' | 'transferring' | 'error';
  title?: string;
};

export const FileDownloadActivityItem = ({ onRemove, ...props }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const node = ref.current;

    if (node !== null) {
      node.addEventListener('animationend', onRemove);
    }

    return () => {
      node?.removeEventListener('animationend', onRemove);
    };
  }, [
    onRemove,
  ]);

  const error = props.status === 'error';

  return (
    <div
      className={cx(styles.root, {
        [styles.transferring]: props.status === 'transferring',
      })}
      ref={ref}
      style={{ bottom: (props.index * 110) + ((props.index + 1) * 10) }}>
      <div
        className={styles.wrap}>
        <div className={styles.main}>
          {error &&
            <button
              className={styles.close}
              onClick={onRemove}>
              <CloseIcon />
            </button>}
          <div className={styles.title}>
            {error
              ? `There was an error`
              : props.title}
          </div>
          <div className={styles.content}>
            <div className={styles.icon}>
              <FileIcon
                extension={props.extension}
                size={30} />
            </div>
            <div className={styles.info}>
              <div
                className={styles.name}
                title={props.filename}>
                {props.filename ?? 'Untitled'}
              </div>
              <div className={styles.stats}>
                <div className={styles.bar}>
                  {!error && <span className={styles.one} />}
                  {!error && <span className={styles.two} />}
                </div>
                {!error &&
                  <button
                    className={styles.cancel}
                    onClick={props.onCancel}>
                    Cancel
                  </button>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FileDownloadActivityItem.displayName = 'FileDownloadActivity.Item';