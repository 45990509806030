import type { AxiosError } from 'axios';
import axios, { type AxiosInstance } from 'axios';
import { store } from '@/store';
import { actions } from '@/store/actions';
import {
  csrfHeaderRequestInterceptor,
  snakeCaseRequestInterceptor,
  logResponseErrorInterceptor,
  logRequestInterceptor,
  sessionValidationInterceptor,
  withCredentialsRequestInterceptor,
  sentryTraceRequestInterceptor,
} from './interceptors';

const baseURL = `${process.env.BRAND_INSIGHTS_API_URL}`;

let authenticatedClient: AxiosInstance = null;
export function getAuthenticatedClient() {
  if (authenticatedClient) return authenticatedClient;

  authenticatedClient = axios.create({
    baseURL,
  });

  authenticatedClient.interceptors.request.use(sentryTraceRequestInterceptor);
  authenticatedClient.interceptors.request.use(sessionValidationInterceptor);
  authenticatedClient.interceptors.request.use(withCredentialsRequestInterceptor);
  // authenticatedClient.interceptors.request.use(snakeCaseRequestInterceptor);
  authenticatedClient.interceptors.request.use(logRequestInterceptor);
  // authenticatedClient.interceptors.request.use(csrfHeaderRequestInterceptor(authenticatedClient));

  authenticatedClient.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
      const status = error?.status || error?.response?.status;

      if (status == 401) {
        store.dispatch(actions.logout());
      }

      logResponseErrorInterceptor(error);
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(error);
    },
  );

  return authenticatedClient;
}

let unauthenticatedClient: AxiosInstance = null;
export function getUnauthenticatedClient() {
  if (unauthenticatedClient) return unauthenticatedClient;

  unauthenticatedClient = axios.create({
    baseURL,
  });

  unauthenticatedClient.interceptors.request.use(sentryTraceRequestInterceptor);
  unauthenticatedClient.interceptors.request.use(withCredentialsRequestInterceptor);
  // unauthenticatedClient.interceptors.request.use(snakeCaseRequestInterceptor);
  unauthenticatedClient.interceptors.request.use(logRequestInterceptor);
  // unauthenticatedClient.interceptors.request.use(csrfHeaderRequestInterceptor(unauthenticatedClient));

  unauthenticatedClient.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
      logResponseErrorInterceptor(error);
      return Promise.reject(error);
    },
  );

  return unauthenticatedClient;
}