import type { UseQueryResult } from '@tanstack/react-query';
import type * as API from '@/api/interfaces';
import { createNamedContext } from '@/utils/react';
import type { Theme } from '@/types/theme';

export type AppThemingContextValue = {
  enabled: boolean;
  query: UseQueryResult<API.App.GetAppTheming.Response>;
  palette: Theme.WhiteLabelPalette;
  theme: API.App.GetAppTheming.Response;
};

export const AppThemingContext = createNamedContext<AppThemingContextValue>(undefined, 'AppTheming.Context');