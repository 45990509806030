import type { ReactNode, Dispatch, SetStateAction } from 'react';
import { useCallback, useState } from 'react';
import type { Chat } from '@/types';
import { useQueryFeedbackQuery } from '@/api/hooks';
import { QueryFeedbackModal } from './FeedbackModal';
import { OpenQueryFeedbackModalContext } from './context';

type Props = {
  children: ReactNode;
};

type State = {
  chatInstanceId: number;
  queryIdentifier: string;
  form: Chat.QueryFeedback;
};

type OpenFeedbackModalProps = {
  chatInstanceId: number;
  queryIdentifier: string;
  rating: Chat.Rating;
};

export const QueryFeedbackModalContainer = (props: Props) => {
  const [state, setState] = useState<State>(null);

  useQueryFeedbackQuery({
    queryIdentifier: state?.queryIdentifier,
    chatInstanceId: state?.chatInstanceId,
  }, {
    enabled: !!state,
    onSuccess: res => {
      setState(s => ({
        ...s,
        form: {
          notes: res.feedback.notes || '',
          rating: s.form.rating || res.feedback.rating,
          structuredFeedback: res.feedback.structuredFeedback,
        },
      }));
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const handleOpenFeedbackModal = useCallback((modalProps: OpenFeedbackModalProps) => {
    setState({
      chatInstanceId: modalProps.chatInstanceId,
      queryIdentifier: modalProps.queryIdentifier,
      form: {
        notes: '',
        rating: modalProps.rating,
        structuredFeedback: [],
      },
    });
  }, []);

  const handleCloseModal = useCallback(() => {
    setState(null);
  }, []);

  const handleUpdateForm: Dispatch<SetStateAction<Chat.QueryFeedback>> = useCallback(setFormAction => {
    if (typeof setFormAction === 'function') {
      setState(s => ({
        ...s,
        form: setFormAction(s.form),
      }));
    } else {
      setState(s => ({
        ...s,
        form: setFormAction,
      }));
    }
  }, []);

  return (
    <OpenQueryFeedbackModalContext.Provider value={handleOpenFeedbackModal}>
      {props.children}
      {!!state && (
        <QueryFeedbackModal
          chatInstanceId={state.chatInstanceId}
          queryIdentifier={state.queryIdentifier}
          form={state.form}
          setForm={handleUpdateForm}
          open={!!state}
          onClose={handleCloseModal} />
      )}
    </OpenQueryFeedbackModalContext.Provider>
  );
};