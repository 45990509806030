export enum ReportFormat {
  Csv = 'csv',
  Excel = 'xlsx',
  Pdf = 'pdf',
  PowerPoint = 'powerpoint',
  Word = 'docx',
}

export enum AsyncActivity {
  FileDownload = `file-download`,
}