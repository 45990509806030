import { useCallback, useMemo } from 'react';
import Select from 'react-select';
import type { ActionMeta, SingleValue } from 'react-select';
import { ChatCustomFilter, chatCustomFilterNamesMap } from '@/enums/chat.filters';
import { getEnumValues } from '@/enums/utils';
import { useTheme } from '@/components/Theme';
import { buildSelectStyles, buildSelectTheme } from './Presentation';

type Props = {
  onSelect: (value: ChatCustomFilter) => void;
  value: ChatCustomFilter;
};

type Item = {
  id: ChatCustomFilter;
  name: string;
};

export function CustomFilterTypeSelect({ onSelect, value }: Props) {
  const theme = useTheme();

  const selectStyles = useMemo(() => buildSelectStyles(theme), [theme]);
  const selectTheme = useMemo(() => buildSelectTheme(theme), [theme]);

  const items: Item[] = useMemo(() => {
    const items = [
      ChatCustomFilter.InsightType,
      ChatCustomFilter.InteractionDate,
      ChatCustomFilter.Product,
      ChatCustomFilter.Sentiment,
      ChatCustomFilter.TherapeuticArea,
      ChatCustomFilter.TherapeuticSubArea,
    ];
    const unsorted = items.map(m => ({
      id: m,
      name: chatCustomFilterNamesMap[m],
    }));

    return unsorted.sort((a, b) => a.name.localeCompare(b.name));
  }, []);

  const selectedValue = useMemo(() => items.find(f => f.id === value), [items, value]);

  const handleChange = useCallback((value: SingleValue<Item>, meta: ActionMeta<Item>) => {
    onSelect(value.id);
  }, [onSelect]);

  return (
    <Select<Item>
      styles={selectStyles}
      isSearchable={false}
      theme={selectTheme}
      value={selectedValue}
      options={items}
      placeholder={`Filter Type`}
      getOptionLabel={o => o.name}
      getOptionValue={o => `${o.id}`}
      onChange={handleChange}
      menuPlacement="auto" />
  );
}