import { Fragment, useLayoutEffect } from 'react';
import styled from '@emotion/styled';
import type { Chat } from '@/types';
import { useChatPermissions, useScrollToBottomOfMessages } from './hooks';
import { InitialSystemMessage } from './Message.System.Initial';
import { ChatItem } from './Item';

type Props = {
  items: Chat.InstanceItem[];
};

export const MessageList = ({ items }: Props) => {
  const scrollToBottom = useScrollToBottomOfMessages();
  const chatPermissions = useChatPermissions();

  useLayoutEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);

  const view = {
    regenerate: chatPermissions.canModify,
  };

  return (
    <Fragment>
      {/* <MessageContainer>
        <InitialSystemMessage />
      </MessageContainer> */}
      {items.map((item, i) => (
        <ChatItem
          key={i}
          canRegenerate={view.regenerate && i === items.length - 1}
          item={item} />
      ))}
    </Fragment>
  );
};

const MessageContainer = styled.div({
  paddingRight: 20,
  paddingLeft: 20,
});