import { memo } from 'react';
import styled from '@emotion/styled';
import { Constants } from '@/components/Theme';

type Props = {
  className?: string;
};

export const NoChatHistory = memo(({ className }: Props) => {
  return (
    <Root className={className}>
      <Wrap>
        {`No Chat History!`}
      </Wrap>
    </Root>
  );
});

const Root = styled.div`
  box-sizing: border-box;
  margin: 0 ${Constants.Sidebar.ItemHorizontalPadding}px;
  display: flex;
  justify-content: center;
`;

const Wrap = styled.div`
  padding: 8px;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  color: ${({ theme }) => theme.palette.gray.main};
`;