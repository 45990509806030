import { memo, useContext } from 'react';
import styled from '@emotion/styled';
import { toTitleCase } from '@/utils';
import {
  ActiveChatSessionContext,
} from './context';

export const NoAccessBanner = memo(() => {
  const chat = useContext(ActiveChatSessionContext);

  return (
    <Root>
      You do not have access to this {toTitleCase(chat.context.type)}.
    </Root>
  );
});

const Root = styled.div(({ theme }) => ({
  width: '100%',
  padding: 15,
  color: theme.palette.gray.dark1,
  fontFamily: theme.fonts.bold,
  backgroundColor: theme.palette.red.secondary,
  border: `1px solid ${theme.palette.red.main}`,
  boxSizing: 'border-box',
  borderRadius: 4,
}));