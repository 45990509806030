import { useCallback, useContext, useMemo, useState } from 'react';
import { ActiveChatSessionContext } from '@/components/Chat/context';
import type { ChatContextType } from '@/enums/chat';
import { HeaderContextContext } from './context';

type Props = ChildrenProps;

export const ContextSelectorContainer = (props: Props) => {

  const [pendingContextType, setPendingContextType] = useState<ChatContextType>();
  const [pendingEverythingSearch, setPendingEverythingSearch] = useState(false);
  const session = useContext(ActiveChatSessionContext);

  const displayContextType = useMemo(() => {
    if (pendingContextType) {
      return pendingContextType;
    }
    return session.context.type;
  }, [session.context.type, pendingContextType]);

  const handleCancelPendingSearch = useCallback(() => {
    setPendingContextType(null);
    setPendingEverythingSearch(false);
  }, [setPendingContextType, setPendingEverythingSearch]);

  const ctxValue = {
    cancelPendingSearch: handleCancelPendingSearch,
    displayContextType,
    pendingContextType,
    setPendingContextType,
    pendingEverythingSearch,
    setPendingEverythingSearch,
  };

  return (
    <HeaderContextContext.Provider value={ctxValue}>
      {props.children}
    </HeaderContextContext.Provider>
  );
};