/** @jsxImportSource @emotion/react */
import { useCallback, useContext, useMemo } from 'react';
import { css } from '@emotion/react';
import { MessageVariantSelector } from '@/components/presentation';
import type { Chat } from '@/types';
import { VariantMessageContext } from './context';

type Props = {
  item: Chat.Query;
};

export const ChatMessageVariantSelector = ({ item }: Props) => {
  const { getVariants, onSelectVariant } = useContext(VariantMessageContext);

  const variants = useMemo(() => getVariants(item.mapping.parentIdentifier), [getVariants, item.mapping.parentIdentifier]);

  const index = useMemo(() => variants.findIndex(query => query.identifier === item.identifier), [variants, item.identifier]);

  const handleNext = useCallback(() => {
    const next = variants[index + 1];
    onSelectVariant(next.identifier);
  }, [variants, index, onSelectVariant]);

  const handlePrevious = useCallback(() => {
    const prev = variants[index - 1];
    onSelectVariant(prev.identifier);
  }, [variants, index, onSelectVariant]);

  if (variants.length < 2) return null;

  return (
    <MessageVariantSelector
      css={css({ marginTop: 4 })}
      index={index}
      count={variants.length}
      onNext={handleNext}
      onPrevious={handlePrevious} />
  );
};