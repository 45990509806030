import { useCallback, useContext } from 'react';
import { type UseMutationOptions, useMutation } from '@tanstack/react-query';
import * as api from '@/api';
import type * as App from '@/api/interfaces/app';
import { AppThemingContext } from '@/containers/AppTheming/Context';
import { Switch } from '@/components/presentation/Switch';
import { cx } from '@/utils';
import { useQueryDataAccessor } from './hooks/useQueryDataAccessor';
import styles from './style/Branding.css';

type Props = unknown;

export const Controls = (props: Props) => {
  const ctx = useContext(AppThemingContext);
  const mutationFn = useMutationFn();
  const helpers = useMutationHelpers();

  const mutation = useMutation({
    mutationKey: [`patch:app/theming`],
    mutationFn,
    onError: (e: Error, variables: UpdateAppTheming.Variables) => {
      helpers.onError(variables);
    },
    onMutate: variables => {
      helpers.onMutate(variables);
    },
  });

  const handleClick = useCallback(() => {
    mutation.mutate({
      enabled: !ctx.theme.enabled,
    });
  }, [
    ctx?.theme?.enabled,
    mutation,
  ]);

  return (
    <Switch
      active={ctx.theme?.enabled ? 'right' : 'left'}
      classes={{
        root: cx(styles.switch, {
          [styles.off]: !ctx.theme?.enabled,
          [styles.on]: ctx.theme?.enabled,
        }),
        toggle: cx(styles.toggle, {
          [styles.off]: !ctx.theme?.enabled,
          [styles.on]: ctx.theme?.enabled,
        }),
      }}
      className={styles.switch}
      labelLeft=""
      labelRight=""
      onClick={handleClick} />
  );
};

const useMutationFn = () => {
  const accessor = useQueryDataAccessor();

  const updateTheming = (params: UpdateAppTheming.Variables) => {
    const data = accessor.getQueryData();

    return api.updateAppTheming({
      ...data.color,
      ...params,
    });
  };

  return useCallback(updateTheming, [
    accessor,
  ]);
};

const useMutationHelpers = () => {
  const accessor = useQueryDataAccessor();

  const onMutate = useCallback((variables: UpdateAppTheming.Variables) => {
    accessor.setQueryData(data => {
      return {
        ...data,
        ...variables,
      };
    });
  }, [accessor]);

  const onError = useCallback((variables: UpdateAppTheming.Variables) => {
    accessor.setQueryData(data => {
      return {
        ...data,
        enabled: !variables.enabled,
      };
    });
  }, [accessor]);

  return {
    onError,
    onMutate,
  };
};

declare namespace UpdateAppTheming {
  export type Variables = App.UpdateAppTheming.Request;

  export type Options =
    UseMutationOptions<
      App.UpdateThemeColor.Response,
      unknown,
      Variables>;
}
