import {
  BrowserRouter,
} from 'react-router-dom';
import { SentryErrorBoundary } from '@/containers/Subscriber/Sentry.ErrorBoundary';
import { GlobalStylesContainer } from './GlobalStyles';
import ReactQueryClient from './ReactQueryClient';
import { ReduxProvider } from './ReduxProvider';
import { QueryParamsClient } from './QueryParamsClient';

type Props = ChildrenProps;

export default function RootVendors({ children }: Props) {
  return (
    <BrowserRouter>
      <SentryErrorBoundary>
        <ReactQueryClient>
          <QueryParamsClient>
            <ReduxProvider>
              <GlobalStylesContainer>
                {children}
              </GlobalStylesContainer>
            </ReduxProvider>
          </QueryParamsClient>
        </ReactQueryClient>
      </SentryErrorBoundary>
    </BrowserRouter>
  );
}

export { RootVendors };