import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as $api from '@/api';
import { ChatStateDispatchContext } from '@/components/Chat.State';

type Props = {
  chatInstanceId: number;
  onSuccess?: () => void;
};

export const useRenameChatInstanceMutation = ({ chatInstanceId, onSuccess }: Props) => {

  const dispatch = useContext(ChatStateDispatchContext);

  const mutation = useMutation(['chat:patch:rename'], (name: string) => {
    return $api.renameChatInstance({
      chatInstanceId,
      name,
    });
  }, {
    onSuccess: res => {
      dispatch({
        type: 'chat-instance-renamed',
        payload: {
          chatInstance: res.chatInstance,
        },
      });
      onSuccess?.();
    },
  });

  return mutation;
};