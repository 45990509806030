import styled from '@emotion/styled';
import type { Chat } from '@/types/chat';
import { MessageCitation } from './Citation';

type Props = {
  className?: string;
  items: Chat.Citation[];
};

export const MessageCitations = ({ className, items }: Props) => {

  return (
    <div className={className}>
      {items.map((item, i) => <StyledMessageCitation key={i} item={item} />)}
    </div>
  );
};

const StyledMessageCitation = styled(MessageCitation)(({ theme }) => ({
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.gray.light2}`,
  },
}));