import { createContext } from 'react';
import type { Chat } from '@/types';
import type { Context } from './interfaces';

export const SubmitMessageContext = createContext<Context.SubmitMessage>(undefined);
export const ChatPageContextDataContext = createContext<Context.ChatPageContextData>(undefined);
export const DispatchPageContextDataContext = createContext<Context.DispatchPageContextData>(undefined);
export const AuthorContext = createContext<Context.Author>(undefined);
export const RegenerateMessageContext = createContext<Context.RegenerateMessage>(undefined);
export const VariantMessageContext = createContext<Context.VariantMessage>(undefined);
export const UploadFilesContext = createContext<Context.UploadFiles>(undefined);
export const IsPreparingUploadContext = createContext<boolean>(undefined);
export const SetPendingScrollContext = createContext<Context.SetPendingScroll>(undefined);

export const ChatSessionFiltersContext = createContext<Chat.Filters>(undefined);
export const ChatSessionInputContext = createContext<string>(undefined);
export const ChatSessionQueriesContext = createContext<Record<string, Chat.Query>>(undefined);
export const ChatSessionFileUploadsContext = createContext<Chat.FileUpload[]>(undefined);
export const ChatSessionKolSearchesContext = createContext<Chat.KolSearch[]>(undefined);
export const ActiveChatSessionContext = createContext<Context.ActiveChatSession>(undefined);
export const ChatSessionSelectedQueryContext = createContext<Chat.Query>(undefined);

export const ChatHintsContext = createContext<Chat.QueryHint.Item[]>([]);
export const ChatHintsLoadingContext = createContext<boolean>(undefined);

export const ChatPermissionsContext = createContext<Context.ChatPermissions>(undefined);

export const MessagesListRefContext = createContext<Context.MessagesListRef>(undefined);

export const ChatSettingsToggleContext = createContext<Context.ChatSettingsToggle>(undefined);
export const MessageCitationsToggleContext = createContext<Context.MessageCitationsToggle>(undefined);
export const OpenSnippetContext = createContext<Context.OpenSnippet>(undefined);
export const ClickCitationContext = createContext<Context.ClickCitation>(undefined);
export const AssignCitationRefContext = createContext<Context.AssignCitationRef>(undefined);
export const CitationsContext = createContext<Chat.Citation[]>(undefined);
export const OpenCrmNoteContext = createContext<string>(undefined);
export const SetOpenCrmNoteContext = createContext<Context.SetOpenCrmNote>(undefined);

export const ChatHasAccessContext = createContext<boolean>(true);

export const KolSearchFormContext = createContext<Context.KolSearchFormState>(undefined);
export const DispatchKolSearchFormContext = createContext<Context.DispatchKolSearchForm>(undefined);
export const KolSearchStatusContext = createContext<Context.KolSearchStatus>(undefined);
export const KolSearchSubmitContext = createContext<Context.KolSearchSubmit>(undefined);
export const KolSearchResultsContext = createContext<Context.KolSearchResults>(undefined);
export const KolSearchCancelContext = createContext<Context.KolSearchCancel>(undefined);
export const KolResetSearchContext = createContext<Context.KolSearchCancel>(undefined);
export const KolProfileContext = createContext<Context.KolSearchProfile>(undefined);
export const UpdateKolSearchProfileContext = createContext<Context.UpdateKolSearchProfile>(undefined);
export const KolSearchErrorTraceIdContext = createContext<string>(undefined);
export const KolFollowupQuestionsContext = createContext<string[]>([]);
export const KolSearchFormBeginContext = createContext<Context.KolSearchFormBegin>(undefined);
export const KolSearchGetRecommendationsContext = createContext<Context.KolSearchGetRecommendations>(undefined);
export const KolSearchRecommendationsContext = createContext<Context.KolSearchRecommendations>(undefined);
export const KolSearchRecommendationProfileSelectedContext = createContext<Context.KolSearchRecommendationProfileSelected>(undefined);