import { ChatInstanceItemType } from '@/enums/chat';
import type { Chat } from '@/types';

type BuildMessageListParam = {
  selectedQueryIdentifier: string;
  queries: Record<string, Chat.Query>;
};

export function buildMessageQueries({ selectedQueryIdentifier, queries }: BuildMessageListParam): Chat.Query[] {
  // console.log('queries: ', Object.keys(queries).map(identifier => queries[identifier]).map(q => ({
  //   id: q.id,
  //   identifier: q.identifier,
  //   value: q.value,
  //   mapping: q.mapping,
  // })));

  if (!selectedQueryIdentifier) return [];

  function getNodeItem(queryIdentifier: string): Chat.Query[] {
    if (!queryIdentifier) return [];

    const node = queries[queryIdentifier];
    const parents = getNodeItem(node.mapping.parentIdentifier);

    return [...parents, node];
  }

  const nodes = getNodeItem(selectedQueryIdentifier);

  return nodes;
}

export function buildMessageItems({ fileUploads, kolSearches, queries }: BuildMessageItems): Chat.InstanceItem[] {
  // const fileUploadItems: Chat.InstanceFileUploadsItem[] = fileUploads.map(fileUpload => ({
  //   createdOn: fileUpload.createdOn,
  //   type: ChatInstanceItemType.FileUpload,
  //   items: fileUpload.items,
  //   identifier: fileUpload.identifier,
  // }));

  const queryItems: Chat.InstanceQueryItem[] = queries.map(m => ({
    createdOn: m.createdOn,
    type: ChatInstanceItemType.Query,
    query: m,
  }));

  const kolSearchItems: Chat.KolSearchItem[] = kolSearches.map(m => ({
    type: ChatInstanceItemType.KolSearch,
    ...m,
  }));

  const items = [
    //...fileUploadItems,
    ...queryItems,
    ...kolSearchItems,
  ];

  items.sort((a, b) => a.createdOn.getTime() - b.createdOn.getTime());

  return items;
}

type BuildMessageItems = {
  fileUploads: Chat.FileUpload[];
  queries: Chat.Query[];
  kolSearches: Chat.KolSearch[];
};

type GetLatestChildParams = {
  selectedQueryIdentifier: string;
  queries: Record<string, Chat.Query>;
};

export function getDeepestNode({ selectedQueryIdentifier, queries }: GetLatestChildParams): Chat.Query {
  function traverseNode(queryIdentifier: string): string {
    if (!queryIdentifier) return null;

    const node = queries[queryIdentifier];
    if (!node) {
      console.error(`Could not find query '${queryIdentifier}' in the current state's queries tree. Either bad data or it's not being returned.`);
    }
    if (!node.mapping.childrenIdentifiers.length) return node.identifier;
    const lastChildIdentifier = node.mapping.childrenIdentifiers[node.mapping.childrenIdentifiers.length - 1];
    const child = traverseNode(lastChildIdentifier);
    return child;
  }

  const deepest = traverseNode(selectedQueryIdentifier);

  return queries[deepest];
}

type GetQuerySiblingsParams = {
  queryIdentifier: string;
  queries: Record<string, Chat.Query>;
};

export function getQuerySiblings({ queryIdentifier, queries }: GetQuerySiblingsParams): Chat.Query[] {
  if (!queryIdentifier) return [];

  const query = queries[queryIdentifier];
  if (!query) return [];

  const parentIdentifier = query.mapping.parentIdentifier;
  if (!parentIdentifier) {
    const noParentQueryIdentifiers = Object.keys(queries).filter(identifier => !queries[identifier].mapping.parentIdentifier);
    const siblings = noParentQueryIdentifiers
      .filter(identifier => identifier !== query.identifier)
      .map(identifier => queries[identifier])
      .filter(Boolean);
    return siblings;
  }

  const parent = queries[parentIdentifier];
  if (!parent) return [];

  const siblings = parent.mapping.childrenIdentifiers
    .filter(identifier => identifier !== query.identifier)
    .map(identifier => queries[identifier])
    .filter(Boolean);

  return siblings;
}