import styled from '@emotion/styled';
import { ActivityIndicator } from '@/components/presentation/ActivityIndicator';

export const AppInitializing = () => {

  return (
    <Root>
      <ActivityIndicator />
    </Root>
  );
};

const Root = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: `calc(100vh - var(--navbar-height))`,
});