import { useCallback, useContext } from 'react';
import type { AxiosError } from 'axios';
import { ChatStateDispatchContext } from '@/components/Chat.State';
import { useScrollToBottomOfMessages } from '@/components/Chat';
import { useFetchChatInstanceMutation } from '@/api/hooks';
import Toast from '@/components/Toast';

type Props = {
  onError?: () => void;
  onSuccess?: () => void;
};

export const useLoadChatInstance = ({ onError, onSuccess }: Props = {}) => {

  const dispatch = useContext(ChatStateDispatchContext);
  const scrollToBottom = useScrollToBottomOfMessages();

  const mutation = useFetchChatInstanceMutation();

  return useCallback((chatInstanceIdentifier: string) => {
    dispatch({
      type: 'session-loading',
      payload: { chatInstanceIdentifier },
    });
    mutation.mutateAsync({ chatInstanceIdentifier }, {
      onSuccess: res => {
        dispatch({
          type: 'batch-actions',
          payload: {
            actions: [
              { type: 'chat-loaded', payload: res },
              { type: 'session-loaded', payload: { chatInstanceIdentifier } },
            ],
          },
        });
        onSuccess?.();
        scrollToBottom({
          nthFromBottom: Object.keys(res.queries).length > 0 ? undefined : 1,
        });
      },
    })
      .catch((error: AxiosError) => {
        onError?.();
        // Toast.error({
        //   title: `Insights Chat`,
        //   body: `Could not find this chat thread!`,
        // });
      });
  }, [dispatch, scrollToBottom, mutation, onError, onSuccess]);
};