import { useCallback, useMemo } from 'react';
import Select from 'react-select';
import type { ActionMeta, SingleValue } from 'react-select';
import { ChatFilterOperator, chatFilterOperatorsMap } from '@/enums/chat.filters';
import { useTheme } from '@/components/Theme';
import { buildSelectStyles, buildSelectTheme } from './Presentation';

type Props = {
  onSelect: (value: ChatFilterOperator) => void;
  options?: ChatFilterOperator[];
  value: ChatFilterOperator;
};
type Item = {
  id: ChatFilterOperator;
  name: string;
};

export function CustomFilterOperatorSelect({ onSelect, options = [ChatFilterOperator.Eq, ChatFilterOperator.Neq], value }: Props) {

  const theme = useTheme();

  const selectStyles = useMemo(() => buildSelectStyles(theme), [theme]);
  const selectTheme = useMemo(() => buildSelectTheme(theme), [theme]);

  const handleChange = useCallback((value: SingleValue<Item>, meta: ActionMeta<Item>) => {
    onSelect(value.id);
  }, [onSelect]);

  const items: Item[] = useMemo(() => {
    return options.map(o => ({
      id: o,
      name: chatFilterOperatorsMap[o],
    }));
  }, [options]);

  const selectedValue = useMemo(() => {
    return items.find(i => i.id === value);
  }, [items, value]);

  return (
    <Select<Item>
      styles={selectStyles}
      isSearchable={false}
      theme={selectTheme}
      value={selectedValue}
      options={items}
      placeholder="Operator"
      getOptionLabel={o => o.name}
      getOptionValue={o => `${o.id}`}
      onChange={handleChange}
      menuPlacement="auto" />
  );
}