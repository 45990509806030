import type { InternalAxiosRequestConfig, Axios } from 'axios';
import axios from 'axios';
import snake from 'snakecase-keys';
import * as Sentry from '@sentry/react';
import * as $session from '@/auth/session';
import { store } from '@/store';
import { actions } from '@/store/actions';

export * from './interceptors.logging';

export const snakeCaseRequestInterceptor = (config: InternalAxiosRequestConfig) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  config.data = config.data ? snake(config.data) : config.data;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  config.params = config.params ? snake(config.params) : config.params;
  return config;
};

export const csrfHeaderRequestInterceptor = (client: Axios) => async (config: InternalAxiosRequestConfig) => {
  const requiresCSRFToken = new Set(['patch', 'post', 'put', 'delete']);
  const csrfHeader = 'x-csrftoken';
  if (requiresCSRFToken.has(config.method)) {
    let csrf = $session.getCSRF();

    if (!csrf) {
      await client.get('/auth/csrf');
      csrf = $session.getCSRF();
    }

    config.headers[csrfHeader] = csrf;
  }
  return config;
};

export const sessionValidationInterceptor = (config: InternalAxiosRequestConfig) => {
  if (!$session.isAuthenticated()) {
    console.log('Aborting request: Session is expired or absent.');
    store.dispatch(actions.logout());
    return {
      ...config,
      cancelToken: new axios.CancelToken(c => c('Aborting request: Session is expired or absent.')),
    };
  }
  return config;
};

export const withCredentialsRequestInterceptor = (config: InternalAxiosRequestConfig) => {
  const url = new URL(config.baseURL);
  const BRAND_INSIGHTS_BASE_URL = new URL(process.env.BRAND_INSIGHTS_API_URL);
  if (url.hostname === BRAND_INSIGHTS_BASE_URL.hostname) {
    config.withCredentials = true;
  }
  return config;
};

export const sentryTraceRequestInterceptor = (config: InternalAxiosRequestConfig) => {
  const activeSpan = Sentry.getActiveSpan();

  if (activeSpan) {
    const rootSpan = Sentry.getRootSpan(activeSpan);
    const sentryTraceHeader = Sentry.spanToTraceHeader(rootSpan);
    const sentryBaggageHeader = Sentry.spanToBaggageHeader(rootSpan);
    config.headers['sentry-trace'] = sentryTraceHeader;
    config.headers['baggage'] = sentryBaggageHeader;
  } else {
    const spanOptions = {
      op: 'axios.request',
      name: `${config.method.toUpperCase()} ${config.url}`,
    };
    Sentry.startSpan(spanOptions, () => {
      if (config.data) {
        Sentry.getCurrentScope().addAttachment({
          filename: 'request-data.json',
          data: JSON.stringify(config.data),
          contentType: 'application/json',
        });
      }
      const activeSpan = Sentry.getActiveSpan();
      const rootSpan = Sentry.getRootSpan(activeSpan);
      config.headers['sentry-trace'] = Sentry.spanToTraceHeader(rootSpan);
      config.headers['baggage'] = Sentry.spanToBaggageHeader(rootSpan);
    });
  }

  return config;
};