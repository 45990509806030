import { useContext, useMemo, useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { Filter as FilterIcon, type IconProps } from 'react-feather';
import styled from '@emotion/styled';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { ActiveChatSessionContext, ChatSessionFiltersContext } from '@/components/Chat/context';
import { Constants } from '@/components/Theme';
import { useZIndexModifier } from '@/components/presentation/popper-modifiers';
import { ChatContextType } from '@/enums/chat';
import { shouldForwardProp } from '@/utils';
import { FilterPane } from './FilterPane';
import { calculateActiveFiltersCount } from './common';

type Props = {
  className?: string;
};

export function FilterButton({ className }: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);

  const zIndexModifier = useZIndexModifier();
  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      zIndexModifier,
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
    placement: 'bottom-start',
  });

  const handleClick = useCallback((e: React.MouseEvent) => {
    setOpen(prev => !prev);
    e.stopPropagation();
  }, []);

  const handleClickAway = useCallback(() => {
    setOpen(false);
  }, []);

  const activeFiltersCount = useActiveFiltersCount();

  const display = {
    activeFilters: activeFiltersCount > 0,
  };

  return (
    <Root className={className}>
      <Button
        ref={setReferenceElement}
        onClick={handleClick}>
        <IconContainer>
          <StyledFilterIcon active={display.activeFilters} />
          {display.activeFilters && (
            <FilterCount>
              {activeFiltersCount}
            </FilterCount>
          )}
          {/* <FilterCircle active={isActive} /> */}
        </IconContainer>
      </Button>
      {open && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <div>
            {createPortal(
              <Popper
                ref={setPopperElement}
                style={popperStyles.popper}
                {...attributes.popper}>
                <FilterPane active={display.activeFilters} />
              </Popper>,
              document.body,
            )}
          </div>
        </ClickAwayListener>
      )}
    </Root>
  );
}
function useActiveFiltersCount() {
  const filters = useContext(ChatSessionFiltersContext);
  const { context } = useContext(ActiveChatSessionContext);

  return useMemo(() => calculateActiveFiltersCount(context, filters), [context, filters]);
}

const Root = styled.div({});

const Button = styled.button(({ theme }) => ({
  height: Constants.Header.ItemHeight,
  display: 'flex',
  backgroundColor: theme.palette.gray.light2,
  borderRadius: Constants.Header.ItemBorderRadius,
  boxSizing: 'border-box',
  padding: '4px 5px',
  alignItems: 'center',
}));

const Popper = styled.div({});

const IconContainer = styled.span({
  boxSizing: 'border-box',
  display: 'flex',
  position: 'relative',
});

type FilterIconProps = {
  active?: boolean;
};

const SizedFilterIcon = (props: IconProps) => (
  <FilterIcon
    {...props}
    size="18px"
    viewBox="0 0 24 24" />
);

const StyledFilterIcon = styled(SizedFilterIcon, { shouldForwardProp: shouldForwardProp('active') })<FilterIconProps>(({ active, theme }) => ({
  color: active ? theme.palette.sentiment.primary.main : theme.palette.black.main,
}));

const FilterCount = styled.div(({ theme }) => ({
  color: theme.palette.black.light1,
  fontFamily: theme.fonts.semiBold,
  fontSize: '14px',
  marginLeft: '5px',
}));

// type FilterCircleProps = {
//   active?: boolean;
// };

// const FilterCircle = styled('div', { shouldForwardProp: shouldForwardProp('active') })<FilterCircleProps>`
//   position: absolute;
//   height: 7px;
//   width: 7px;
//   border-radius: 8px;
//   background-color: ${props => props.theme.palette.sentiment.primary.main};
//   right: -4px;
//   top: -1px;
//   border: 2px solid ${props => props.theme.palette.white.main};
//   opacity: ${props => props.active ? 1 : 0};
//   transition: opacity 0.2s ease-out;
// `;