import { generateEmptyChatSessionFiltersState } from '@/components/Chat.State/state.initial-state';
import type { BrandInsightsPageContext, Chat } from '@/types';

export function calculateIfFiltersActive(context: BrandInsightsPageContext.Item, data: Chat.Filters) {
  return calculateActiveFiltersCount(context, data) > 0;
}

export function calculateActiveFiltersCount(context: BrandInsightsPageContext.Item, data: Chat.Filters) {
  let count = data.custom.length;
  const defaults = generateEmptyChatSessionFiltersState({ context });

  if (JSON.stringify((data.sources || []).concat().sort((a, b) => a.id.localeCompare(b.id))) != JSON.stringify(defaults.sources.concat().sort((a, b) => a.id.localeCompare(b.id)))) count++;
  if (data.minDocumentDate != defaults.minDocumentDate) count++;
  // if (JSON.stringify((data.tags || []).concat().sort((a, b) => a.id - b.id)) != JSON.stringify(defaults.tags.concat().sort((a, b) => a.id - b.id))) count++;
  if (JSON.stringify((data.dataSources || []).concat().sort((a, b) => a.id.localeCompare(b.id))) != JSON.stringify(defaults.dataSources.concat().sort((a, b) => a.id.localeCompare(b.id)))) count++;

  return count;
}

export function calculateIfFiltersActiveForChat(data: Chat.Filters) {
  return data.custom.length > 0;
}