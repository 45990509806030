import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useLogoutMutation } from '@/containers/AppStateEffect/hooks';

export const LogoutScreen = () => {

  const { mutateAsync: logout } = useLogoutMutation();

  useEffect(() => {
    logout();
  }, [logout]);

  return (
    <></>
  );
};