import { useCallback } from 'react';
import { useAppDispatch } from '@/store';
import { actions } from '@/store/actions';
import * as $api from '@/api';
import * as $session from '@/auth/session';

export const useFetchApp = () => {
  const dispatch = useAppDispatch();

  const fetchApp = () => {
    dispatch(actions.appStateLoadStarted());

    $api.getAppState()
      .then(data => {
        if (data.session) {
          $session.set(data.session);
        }
        dispatch(actions.appStatedLoaded({
          group: data.group,
          user: {
            id: data.user.id,
            username: data.user.username || data.user.email,
            email: data.user.email,
            profile: {
              firstName: data.user.firstName,
              lastName: data.user.lastName,
            },
            permissions: data.user.permissions,
            roles: data.user.roles,
          },
        }));
      })
      .catch((e: Error) => {
        dispatch(actions.appStateLoadError({ error: e }));
      });
  };

  return useCallback(fetchApp, [
    dispatch,
  ]);
};