import { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { SecondaryButton, PrimaryButton } from '@/components/presentation';
import type { Chat } from '@/types';
import type { BaseModalProps } from '@/hooks/useModal';
import { useModal } from '@/hooks/useModal';
import { useRenameChatInstanceMutation } from './hooks';

type Props = {
  chatInstance: Pick<Chat.Instance, 'id' | 'name'>;
} & BaseModalProps;

export const RenameChatModal = ({ chatInstance, open, onClose }: Props) => {

  const [value, setValue] = useState(chatInstance.name || '');

  const mutation = useRenameChatInstanceMutation({ chatInstanceId: chatInstance.id, onSuccess: onClose });

  const handleConfirmation = useCallback(() => {
    return mutation.mutateAsync(value);
  }, [mutation, value]);

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  const canSubmit = !!value && !mutation.isLoading;

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      onClose={onClose}
      open={open}>

      <Title>Rename Chat</Title>

      <DialogContent>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            '& .MuiInputBase-input': {
              fontFamily: 'var(--font-reg)',
              fontSize: 16,
              lineHeight: 32,
              letterSpacing: -.25,
            },
          }}>
          <TextField
            autoFocus
            fullWidth
            onChange={handleChange}
            value={value}
            variant="standard" />
        </Box>
      </DialogContent>

      <Footer>
        <SecondaryButton
          onClick={handleCancel}>
          Cancel
        </SecondaryButton>
        <PrimaryButton
          disabled={!canSubmit}
          onClick={handleConfirmation}>
          Save
        </PrimaryButton>
      </Footer>

    </Dialog>
  );
};

const Title = styled(DialogTitle)(({ theme }) => ({
  marginBottom: 10,
  fontFamily: theme.fonts.bold,
  fontSize: 22,
  textAlign: 'center',
  color: theme.palette.text.primary,
}));

const Footer = styled(DialogActions)(({
  display: 'flex',
  justifyContent: 'center',
}));

export const useRenameChatModal = () => useModal(RenameChatModal);