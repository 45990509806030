import styled from '@emotion/styled';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CloseIcon from '@mui/icons-material/Close';
import type { AppThemingContextValue } from '@/containers/AppTheming/Context';
import { shouldForwardProp } from '@/utils/emotion';
import type { Theme } from '@/types/theme';

type WrapProps = {
  isDisabled?: boolean;
};

type IconProps = {
  isDisabled?: boolean;
};

type WLPalette = AppThemingContextValue['palette']['prompt'];

export const Root = styled.button`
  display: block;
`;

export const Wrap = styled('div', { shouldForwardProp: shouldForwardProp('isFullPageChat', 'isDisabled') }) <WrapProps>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  color: ${props => props.theme.palette.text.primary};
  cursor: ${props => props.isDisabled ? 'default' : 'pointer'};
  border-radius: 2px;
  font-size: 15px;
  font-family: ${props => props.theme.fonts.regular};
  padding: 6px 8px;
  transition: background-color 0.2s;
  text-align: left;
`;

export const TextStyledIcon = styled(ArrowForwardIcon, { shouldForwardProp: shouldForwardProp('isDisabled') }) <IconProps>`
  height: 16px;
  width: 16px;
  font-size: 18px;
  margin-right: 4px;
`;

export const AnalysisStyledIcon = styled(AutoAwesomeIcon, { shouldForwardProp: shouldForwardProp('isDisabled') }) <IconProps>`
  height: 16px;
  width: 16px;
  font-size: 18px;
  margin-right: 4px;
`;

export const CancelStyledIcon = styled(CloseIcon, { shouldForwardProp: shouldForwardProp('isDisabled') }) <IconProps>`
  height: 16px;
  width: 16px;
  font-size: 18px;
  margin-right: 4px;
`;

export const Colors = (theme: Theme.Theme) => {
  const { gray, green, purple, wl: wlBase } = theme.palette;

  const wl = wlBase?.prompt;

  return {
    Text: {
      enabled: {
        backgroundColor: wl?.main || gray.light5,
        hover: {
          backgroundColor: wl?.dark1 || gray.light6,
        },
      },
      disabled: {
        backgroundColor: theme.palette.gray.light2,
        hover: {
          backgroundColor: theme.palette.gray.light2,
        },
      },
    },
    TextIcon: {
      enabled: {
        color: wl?.icon || purple.light8,
      },
      disabled: {
        color: theme.palette.gray.main,
      },
    },
    Analysis: {
      enabled: {
        backgroundColor: wl?.main || green.light3,
        hover: {
          backgroundColor: wl?.dark1 || green.light2,
        },
      },
      disabled: {
        backgroundColor: theme.palette.gray.light2,
        hover: {
          backgroundColor: theme.palette.gray.light2,
        },
      },
    },
    AnalysisIcon: {
      enabled: {
        color: wl?.icon || green.dark2,
      },
      disabled: {
        color: theme.palette.gray.main,
      },
    },
    Cancel: {
      enabled: {
        backgroundColor: wl?.main || gray.light1,
        hover: {
          backgroundColor: wl?.dark1 || gray.light1,
        },
      },
      disabled: {
        backgroundColor: theme.palette.gray.light2,
        hover: {
          backgroundColor: theme.palette.gray.light2,
        },
      },
    },
    CancelIcon: {
      enabled: {
        color: wl?.icon || theme.palette.black.main,
      },
      disabled: {
        color: theme.palette.gray.main,
      },
    },
  };
};