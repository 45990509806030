import { memo } from 'react';
import styled from '@emotion/styled';
import type { Chat } from '@/types';
import { Lightbulb } from '@/components/Chat.Widget/Lightbulb';

type UserAuthorProps = {
  author: Chat.Creator;
  className?: string;
};

type SystemAuthorProps = {
  animate?: boolean;
  className?: string;
};

export const SystemAuthor = memo(({ animate, className }: SystemAuthorProps) => {

  return (
    <SystemAuthorRoot className={className}>
      <Lightbulb size={22} />
    </SystemAuthorRoot>
  );
});

export const UserAuthor = memo(({ author, className }: UserAuthorProps) => {

  return (
    <UserAuthorRoot className={className}>
      {getFirstLetter(author.profile.firstName)} {getFirstLetter(author.profile.lastName)}
    </UserAuthorRoot>
  );
});

function getFirstLetter(name: string) {
  if (!name) return '';

  return name.trim()[0].toUpperCase();
}

const AuthorRoot = styled('div')`
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const UserAuthorRoot = styled(AuthorRoot)(({ theme }) => `
  background-color: ${theme.palette.gray.light1};
  color: ${theme.palette.black.main};
  font-size: 18px;
  font-family: ${theme.fonts.semiBold};
  letter-spacing: -2px;
`);

const SystemAuthorRoot = styled(AuthorRoot)(({ theme }) => `
  background-color: ${theme.palette.primary.light6};
`);