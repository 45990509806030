export const pluralize = (word: string, count: number) => {
  return count == 1
    ? word
    : `${word}s`;
};

export const possessive = (word: string) => {
  return word.endsWith('s')
    ? `${word}'`
    : `${word}'s`;
};

export const trunc = (text: string, max = Infinity) => {
  if (!text) return '';

  return text.length > max
    ? text.substring(0, max).trimEnd() + '...'
    : text;
};

export const toAlphabetic = (num: number) => {
  return String.fromCharCode(num + 64);
};

export const toTitleCase = (str: string) => {
  if (!str) return '';
  return str
    .split(' ')
    .map(ch => `${ch.charAt(0).toUpperCase()}${ch.slice(1)}`)
    .join(' ');
};

type Slugify = {
  id?: number | string;
  name: string;
};

const SlugReplaceMapping = {
  Source: ['·', '/', '_', ',', ':', ';'],
  // eslint-disable-next-line key-spacing
  Dest: ['-', '-', '-', '-', '-', '-'],
};

const slugRegex = new RegExp(SlugReplaceMapping.Source.join('|'), 'g');

export function slugify({ id, name }: Slugify) {
  const value = name
    ? name.toLowerCase()
      .replace(/\s+/g, '-')
      .replace(slugRegex, r => SlugReplaceMapping.Dest[SlugReplaceMapping.Source.indexOf(r)])
      .replace(/&/g, '-and-')
      .replace(/[^\w-]+/g, '')
      .replace(/--+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '')
    : '';

  return [value, id].filter(v => v && v.toString().length).join('-');
}