import { useCallback, useEffect, useState } from 'react';
import { cx } from '@/utils';
import { Color } from './Color';
import type { PasteContext } from './Input';
import { Input } from './Input';
import styles from './style/ColorInput.css';
import { hex } from './utils';

type Props = {
  PreviewElement?: React.ElementType<{ value: string }>;
  className?: string;
  initialValue?: string;
  onChangeColor?: (val: string) => unknown;
  onChangeValidHex?: (val: string) => unknown;
  tabIndex?: number;
};

export const ColorInput = ({
  PreviewElement,
  initialValue = '#000000',
  onChangeColor,
  onChangeValidHex,
  tabIndex = 0,
  ...props
}: Props) => {
  const [value, setValue] = useState(hex.createInitialValue(initialValue));
  const [color, setColor] = useState(hex.validateColor(initialValue));

  const handleInputValue = useCallback((val: string) => {
    const value = val.toUpperCase();

    const next = hex.validateColor(value);

    if (next.valid) {
      onChangeValidHex?.(next.hex);
      setColor(next);
    }

    setValue(val);
  }, [onChangeValidHex]);

  const handleBlur = useCallback((e: React.FocusEvent) => {
    if (value !== color.value) {
      setValue(color.value);
    }

    onChangeColor?.(color.hex);
  }, [
    color,
    onChangeColor,
    value,
  ]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    handleInputValue(e.target.value);
  }, [handleInputValue]);

  const handlePasteFormat = useCallback((e: React.ClipboardEvent, context: PasteContext) => {
    if (context.selection.length === context.input.length) {
      handleInputValue(context.clipboard.value.replace('#', ''));
    }
  }, [handleInputValue]);

  return (
    <div className={cx(styles.root, props.className)}>
      <Input
        className={styles.main}
        onBlur={handleBlur}
        onChange={handleChange}
        onPasteFormat={handlePasteFormat}
        value={value} />
      <div className={styles.preview}>
        {PreviewElement
          ? <PreviewElement value={color.hex} />
          : <Color value={color.hex} />}
      </div>
    </div>
  );
};

ColorInput.displayName = 'ColorInput';