import { getLocalStorage } from '@/lib/storage';
import { getCookie } from '@/utils';
import type { Session, LastLoginStrategy } from './interfaces';

export const LocalStorageKeys = Object.freeze({
  CSRF: 'csrftoken',
  SessionMeta: 'msl-copilot:session-meta',
  LastLoginStrategy: 'msl-copilot:last-login-strategy',
});

export function set(data: Session.Set.Params) {
  const $storage = getLocalStorage();

  const value: Session.LocalStorage = {
    authenticated: data.authenticated,
    expiry: data.expiry.getTime(),
    userId: data.userId,
  };
  $storage.setItem(LocalStorageKeys.SessionMeta, JSON.stringify(value));
}

export function destroy(destroyAll = false) {
  const $storage = getLocalStorage();

  $storage.removeItem(LocalStorageKeys.CSRF);
  $storage.removeItem(LocalStorageKeys.SessionMeta);
  if (destroyAll) {
    $storage.removeItem(LocalStorageKeys.LastLoginStrategy);
  }
}

export function get(): Session.LocalStorage {
  const $storage = getLocalStorage();

  try {
    const value = $storage.getItem(LocalStorageKeys.SessionMeta);
    if (!value) return null;
    const session = JSON.parse(value) as Session.LocalStorage;
    return session;
  } catch (e) {
    $storage.removeItem(LocalStorageKeys.SessionMeta);
    return null;
  }
}

export function isAuthenticated() {
  const session = get();
  if (!session) return false;
  return session.authenticated && (Date.now() + (5 * 60 * 1000)) < session.expiry;
}

export function hasSession() {
  const session = get();
  return !session;
}

export function getCSRF() {
  return getCookie(LocalStorageKeys.CSRF);
}

export function setLastLoginStrategy(data: LastLoginStrategy.Set.Params) {
  getLocalStorage().setItem(LocalStorageKeys.LastLoginStrategy, JSON.stringify(data));
}

export function getLastLoginStrategy() {
  const $storage = getLocalStorage();

  try {
    const value = $storage.getItem(LocalStorageKeys.LastLoginStrategy);
    if (!value) return null;
    const strategy = JSON.parse(value) as LastLoginStrategy.LocalStorage;
    return strategy;
  } catch (e) {
    $storage.removeItem(LocalStorageKeys.LastLoginStrategy);
    return null;
  }
}