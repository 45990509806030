import { useCallback, useContext, useMemo } from 'react';
import { ChatStateDispatchContext } from '@/components/Chat.State';
import { getDeepestNode } from '@/utils/queries';
import { ChatSessionQueriesContext, VariantMessageContext } from './context';
import { useScrollToBottomOfMessages } from './hooks';

type Props = {
  children: React.ReactNode;
};

export const VariantMessageContainer = (props: Props) => {
  const dispatch = useContext(ChatStateDispatchContext);
  const queries = useContext(ChatSessionQueriesContext);

  const scrollToBottom = useScrollToBottomOfMessages();

  const handleSelectVariant = useCallback((queryIdentifier: string) => {
    const node = getDeepestNode({ selectedQueryIdentifier: queryIdentifier, queries });
    dispatch({
      type: 'message-variant-selected',
      payload: {
        queryIdentifier: node.identifier,
      },
    });
    scrollToBottom({ behavior: 'smooth' });
  }, [queries, scrollToBottom, dispatch]);

  const handleGetVariants = useCallback((parentQueryIdentifier: string) => {
    if (!parentQueryIdentifier) {
      const root = Object.keys(queries).map(identifier => queries[identifier]).filter(q => !q.mapping.parentIdentifier);
      return root.sort((a, b) => a.createdOn.getTime() - b.createdOn.getTime());
    }

    const parent = queries[parentQueryIdentifier];
    return parent.mapping.childrenIdentifiers.map(identifier => queries[identifier]).filter(Boolean);
  }, [queries]);

  const ctx = useMemo(() => ({
    onSelectVariant: handleSelectVariant,
    getVariants: handleGetVariants,
  }), [
    handleSelectVariant,
    handleGetVariants,
  ]);

  return (
    <VariantMessageContext.Provider value={ctx}>
      {props.children}
    </VariantMessageContext.Provider>
  );
};