import { memo } from 'react';
import styled from '@emotion/styled';
import { shouldForwardProp } from '@/utils';

type Props = {
  className?: string;
  value: string;
  selected: boolean;
  onClick: () => unknown;
};

type RootProps = Pick<Props, 'selected'>;

export const MessageCitationSnippetGroupSelector = memo(({ className, value, selected, onClick }: Props) => {
  return (
    <Root
      className={className}
      selected={selected}
      onClick={onClick}>
      <Wrap>
        {value}
      </Wrap>
    </Root>
  );
});

const Root = styled('button', { shouldForwardProp: shouldForwardProp('selected') })<RootProps>(({ theme, selected }) => ({
  boxSizing: 'border-box',
  backgroundColor: selected ? theme.palette.gray.light6 : theme.palette.gray.light5,
  borderRadius: 8,
  color: theme.palette.black.light1,
  // color: selected ? theme.palette.primary.light6 : theme.palette.black.light1,
  display: 'inline-block',
  padding: '4px 8px',
  fontSize: 12,
  // fontFamily: selected ? theme.fonts.semiBold : theme.fonts.regular,
  '&:hover': {
    backgroundColor: theme.palette.gray.light6,
  },
}));

const Wrap = styled.div({
  display: 'flex',
  alignItems: 'center',
});