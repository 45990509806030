import { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import { PrimaryButton } from '@/components/presentation';
import { usePasswordLoginMutation } from '@/containers/Auth';
import useEventListener from '@/hooks/useEventListener';

export const PasswordLogin = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const loginMutation = usePasswordLoginMutation();

  const canSubmit = useMemo(() => {
    return !loginMutation.isLoading && email.includes('@') && email.includes('.') && !!password;
  }, [email, loginMutation, password]);

  const handleLogin = useCallback(() => {
    return loginMutation.mutateAsync({
      email,
      password,
    });
  }, [email, loginMutation, password]);

  const handleKeydown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Enter' && canSubmit) {
      handleLogin();
    }
  }, [canSubmit, handleLogin]);

  useEventListener('keydown', handleKeydown);

  const handleEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }, [setEmail]);

  const handlePasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }, [setPassword]);

  return (
    <div>

      <TextField
        autoFocus
        fullWidth
        placeholder="Enter your email"
        onChange={handleEmailChange}
        value={email}
        variant="standard" />

      <PasswordField
        fullWidth
        placeholder="Enter your password"
        onChange={handlePasswordChange}
        type="password"
        value={password}
        variant="standard" />

      <Footer>
        <PrimaryButton
          disabled={!canSubmit}
          onClick={handleLogin}>
          Log In
        </PrimaryButton>
      </Footer>
    </div>
  );
};

const Footer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 20,
});

const PasswordField = styled(TextField)({
  marginTop: 20,
});