import { useMemo } from 'react';
import styled from '@emotion/styled';
import * as Presentation from './presentation';

type Props = {
  className?: string;
  displayValue: string;
  onClick: () => unknown;
  disabled?: boolean;
};

export const CancelAction = ({ className, displayValue, disabled, onClick }: Props) => {
  return (
    <Presentation.Root className={className} onClick={disabled ? undefined : onClick}>
      <CancelWrap isDisabled={disabled}>
        <CancelIcon isDisabled={disabled} /> {displayValue}
      </CancelWrap>
    </Presentation.Root>
  );
};

const CancelWrap = styled(Presentation.Wrap)(({ isDisabled, theme }) => {
  const { Cancel } = useMemo(() => Presentation.Colors(theme), [
    theme,
  ]);

  const values = {
    enabled: Cancel.enabled,
    disabled: Cancel.disabled,
  };

  return {
    backgroundColor: `${isDisabled ? values.disabled.backgroundColor : values.enabled.backgroundColor}`,
    '&:hover': {
      backgroundColor: `${isDisabled ? values.disabled.hover.backgroundColor : values.enabled.hover.backgroundColor}`,
    },
  };
});

const CancelIcon = styled(Presentation.CancelStyledIcon)(({ isDisabled, theme }) => {
  const { CancelIcon } = useMemo(() => Presentation.Colors(theme), [
    theme,
  ]);

  const values = {
    enabled: CancelIcon.enabled,
    disabled: CancelIcon.disabled,
  };

  return {
    color: `${isDisabled ? values.disabled.color : values.enabled.color}`,
  };
});