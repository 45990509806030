import { memo } from 'react';
import styled from '@emotion/styled';

type Props = {
  className?: string;
  traceId: string;
};

export const ErrorTrace = memo(({ traceId }: Props) => {

  return (
    <>
      <br />
      <br />
      Please reference the following <StyledTraceId>Trace ID</StyledTraceId> when contacting customer support:
      <br />
      <br />
      <div>{traceId.split('-')[0]}</div>
    </>
  );
});

const StyledTraceId = styled.span(({ theme }) => ({
  fontFamily: theme.fonts.semiBold,
}));