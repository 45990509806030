import { useEffect, useState } from 'react';
import type { Socket } from 'socket.io-client';
import { SocketNamespace } from '@/enums/websocket';
import { getSocket } from '@/websocket/socket';
import { useSetupDefaultSocketListeners } from './useSetupDefaultListeners';

export const useReportsSocket = (identifier: string) => {
  const [socket, setSocket] = useState<Socket>();

  useEffect(() => {
    if (identifier) {
      const newSocket = getSocket(SocketNamespace.AsyncActivity);

      setSocket(newSocket);

      newSocket.emit('subscribe', identifier);

      return () => {
        newSocket.emit('unsubscribe', identifier);
        newSocket.disconnect();
      };
    }
  }, [identifier]);

  useSetupDefaultSocketListeners(socket, SocketNamespace.AsyncActivity);

  return socket;
};