import { useContext, useMemo } from 'react';
import type { Chat } from '@/types';
import { ActiveChatSessionContext, AuthorContext } from '../context';

export function useChatInstanceAuthor(): Chat.Creator {
  const author = useContext(AuthorContext);
  const ctx = useContext(ActiveChatSessionContext);

  return useMemo(() => {
    if (!ctx.shared)
      return author;
    else
      return ctx.shared.sharedBy;
  }, [ctx.shared, author]);
}