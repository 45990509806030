import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import * as $session from '@/auth/session';
import * as $sessionHttp from '@/auth/session-http';
import { useAppDispatch } from '@/store';
import { actions } from '@/store/actions';
import type { Standard } from './interfaces';

export function usePasswordLoginMutation() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return useMutation({
    mutationFn: (values: Standard.Login.State) => {
      return $sessionHttp.login({
        email: values.email,
        password: values.password,
      });
    },
    onSuccess: session => {
      $session.set({
        authenticated: session.authenticated,
        expiry: session.expiry,
        userId: session.userId,
      });
      $session.setLastLoginStrategy({ strategy: 'standard' });
      dispatch(actions.authChange());
      navigate('/');
    },
  });
}