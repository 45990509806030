import styled from '@emotion/styled';
import type { CSSProperties } from 'react';

export const getCitationNumberStyles = (theme: Pick<Theme, 'palette' | 'fonts'>): CSSProperties => ({
  color: theme.palette.badge.citation.color,
  backgroundColor: theme.palette.badge.citation.bg,

  boxSizing: 'border-box',
  minHeight: 12,
  minWidth: 12,
  padding: 2,

  fontSize: 11,
  fontFamily: theme.fonts.bold,
  borderRadius: 3,

  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const CitationNumber = styled.div(({ theme }) => ({
  ...getCitationNumberStyles(theme),
}));