import { Link as BaseLink } from 'react-router-dom';
import styled from '@emotion/styled';

export const Link = styled(BaseLink)(({ theme }) => ({
  color: theme.palette.hyperlink,
  textDecoration: 'underline',
}));

export const StyledAnchor = styled.a(({ theme }) => ({
  color: theme.palette.hyperlink,
  textDecoration: 'underline',
}));