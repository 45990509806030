import { useEffect, Fragment } from 'react';
import { useAppReadyState, useAuthChangeEffect, useHydrateEffect } from '@/containers/AppReadyState';
import { useFetchApp, useInitSentry, useLogoutMutation } from '@/containers/AppStateEffect/hooks';
import { useValidSessionChecker } from './hooks/useValidSessionChecker';

type Props = {
  children: React.ReactNode;
};

export const AppStateEffectContainer = (props: Props) => {
  const app = useAppReadyState();
  const logoutMutation = useLogoutMutation();
  const fetchApp = useFetchApp();

  const initSentry = useInitSentry();

  useHydrateEffect(() => {
    initSentry();
  });

  useAuthChangeEffect(auth => {
    if (!auth) {
      logoutMutation.mutateAsync();
    }
  });

  useEffect(() => {
    if (app.authenticated) {
      fetchApp();
    }
  }, [
    app.authenticated,
    fetchApp,
  ]);

  useValidSessionChecker({
    enabled: app.initialized && app.authenticated,
  });

  return (
    <Fragment>
      {props.children}
    </Fragment>
  );
};

AppStateEffectContainer.displayName = 'AppStateEffectContainer';