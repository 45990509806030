import { useContext } from 'react';
import Cancel from '@mui/icons-material/Cancel';
import InsertPhoto from '@mui/icons-material/InsertPhoto';
import { AppThemingContext } from '@/containers/AppTheming/Context';
import { MediaOrientation } from '@/enums';
import { cx } from '@/utils';
import { ImageCrop } from '@/components/ImageCrop';
import { useRemoveLogoMutation, useUpdateLogoMutation } from './hooks';
import styles from './style/Settings.Branding.Logo.css';

type Props = {
  imageSrc?: string;
  onClose: () => void;
};

export const Image = (props: Omit<Props, 'onClose'>) => {
  const { query } = useContext(AppThemingContext);
  const mutation = useRemoveLogoMutation({
    onSuccess: () => query.refetch,
  });

  if (!props.imageSrc) {
    return (
      <div className={styles.landscape}>
        <div className={styles.clamp}>
          <InsertPhoto className={styles.noimage} />
        </div>
      </div>
    );
  }

  return (
    <div className={cx(styles.landscape, styles.content)}>
      <img
        className={styles.image}
        src={props.imageSrc} />
      <div
        className={styles.remove}
        onClick={() => mutation.mutate({
          orientation: MediaOrientation.Horizontal,
        })}>
        <Cancel />
      </div>
    </div>
  );
};

Image.displayName = 'Settings.Branding.Logo.Landscape.Image';

export const Cropper = (props: Props) => {
  const { query } = useContext(AppThemingContext);
  const mutation = useUpdateLogoMutation({ onSuccess: props.onClose });

  return (
    <ImageCrop
      aspectRatio={2.5}
      isSaving={mutation.isLoading}
      onSave={data => mutation.mutateAsync({
        blob: data.blob,
        orientation: MediaOrientation.Horizontal,
      }).then(() => {
        query.refetch();
        props.onClose();
      })}
      src={props.imageSrc} />
  );
};

Cropper.displayName = 'Settings.Branding.Logo.Landscape.Cropper';