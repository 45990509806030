import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import type * as App from '@/api/interfaces/app';
// import * as QKey from '@/consts/querykey';
// import { useSelectGroup } from '@/containers/Store';

export const useQueryDataAccessor = <T extends App.GetAppTheming.Response>() => {
  const qc = useQueryClient();

  const getQueryData = useCallback(() => {
    return qc.getQueryData<T>([`get:app/theming`]);
  }, [
    qc,
  ]);

  const setQueryData = useCallback((updater: Updater<T>) => {
    return qc.setQueryData<T>([`get:app/theming`], updater);
  }, [
    qc,
  ]);

  return {
    getQueryData,
    setQueryData,
  };
};

type Updater<T> = UpdaterParam<T | undefined, T>;
type UpdaterFn<I, O> = (data: I) => O;
type UpdaterParam<I, O> = O | UpdaterFn<I, O>;