import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

export const MessageCitationMaybeLink = (props: LinkProps) => {

  if (props.to) {
    const isExternal = !props.to.toString().startsWith(process.env.FRONT_BASE_URL);

    if (isExternal) {
      return (
        <StyledAnchor
          href={props.to.toString()}
          target="_blank"
          rel="noreferrer">
          {props.children}
        </StyledAnchor>
      );
    }

    return (
      <Root {...props}>
        {props.children}
      </Root>
    );
  } else {
    return (
      <>{props.children}</>
    );
  }
};

const StyledAnchor = styled.a(({ theme }) => ({
  display: 'inline-flex',

  '&:hover div': {
    color: theme.palette.hyperlink,
    textDecoration: 'underline',
  },
}));

const Root = styled(Link)(({ theme }) => ({
  display: 'inline-flex',

  '&:hover div': {
    color: theme.palette.hyperlink,
    textDecoration: 'underline',
  },
}));