import { useContext, useMemo } from 'react';
import type { Chat } from '@/types';
import { useHover } from '@/hooks/useHover';
import { ActiveChatSessionContext } from '@/components/Chat';
import { ChatHistoryItemContext } from './context';
import { ChatHistoryItem } from './Item';
import { ItemPlaceholder } from './Item.Placeholder';

type Props = {
  className?: string;
  item: Chat.Instance;
};

export const ChatHistoryItemContainer = ({ className, item }: Props) => {
  const chat = useContext(ActiveChatSessionContext);
  // const [contextMenuOpen, setContextMenuOpen] = useState(false);

  const isActive = useMemo(() => chat.id === item.id, [chat.id, item.id]);

  const [ref, isHovering] = useHover();

  const isPlaceholder = !item.id;

  if (isPlaceholder) {
    return <ItemPlaceholder className={className} />;
  }

  const ctx = {
    isActive,
    item,
  };

  return (
    // <ChatHistoryContextMenuVisibilityContext.Provider value={[contextMenuOpen, setContextMenuOpen]}>
    <ChatHistoryItemContext.Provider value={ctx}>
      <ChatHistoryItem
        className={className}
        ref={ref}
        isActive={isActive}
        isHovering={isHovering} />
    </ChatHistoryItemContext.Provider>
    // </ChatHistoryContextMenuVisibilityContext.Provider>
  );
};