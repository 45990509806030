/** @jsxImportSource @emotion/react */
import { useCallback, useContext, useMemo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ActivityIndicator } from '@/components/presentation';
import type { SwitchTab } from '@/components/presentation/Switch';
import { Switch } from '@/components/presentation/Switch';
import { ActiveChatSessionContext, useIsFullPageChat } from '@/components/Chat';
import { Constants } from '@/components/Theme';
import type { Chat } from '@/types';
import { ChatContextType } from '@/enums/chat';
import { ViewMoreHistory } from './ViewMore';
import { ChatHistoryItemContainer } from './Item.Container';
import { NoChatHistory } from './NoChatHistory';
import { FetchMoreHistoryContext, ChatHistoryManagerContext } from './context';
import { generateHistoryBuckets } from './history';
import { useChatHistoryMode } from './hooks';

type Props = {
  className?: string;
  items: Chat.Instance[];
  shared: Chat.Instance[];
};

export const ChatHistory = ({ className, items, shared }: Props) => {
  const historyManager = useContext(ChatHistoryManagerContext);
  const fetchMoreHistory = useContext(FetchMoreHistoryContext);
  const buckets = useMemo(() => generateHistoryBuckets(items, shared), [items, shared]);
  const [historyMode, updateHistoryMode] = useChatHistoryMode();

  const handleHistoryClick = useCallback((tab: SwitchTab) => updateHistoryMode(tab === 'left' ? 'all' : 'page'), [updateHistoryMode]);
  const labelRight = usePageLabel();
  // const useSwitcher = useHistorySwitcher();
  const isFullpageChat = useIsFullPageChat();

  const view = useMemo(() => ({
    switcher: false, // useSwitcher,
    empty: !buckets.length && !historyManager.loading,
    viewmore: !historyManager.loading && historyManager.hasMore,
    loader: historyManager.loading,
  }), [buckets, historyManager.loading, historyManager.hasMore]);

  return (
    <Root className={className}>
      {!isFullpageChat && <HistoryLabel>History</HistoryLabel>}

      {view.switcher && (
        <Switch
          css={css({ margin: `0 ${Constants.Sidebar.ItemHorizontalPadding}px 20px ${Constants.Sidebar.ItemHorizontalPadding}px` })}
          active={historyMode === 'all' ? 'left' : 'right'}
          labelLeft='All'
          labelRight={labelRight}
          onClick={handleHistoryClick} />
      )}
      {view.empty && <NoChatHistory />}
      {buckets.map(({ bucket, items }) => (
        <Bucket key={bucket}>
          {!isFullpageChat && <BucketTitle>{bucket}</BucketTitle>}
          {items.map(item => (
            <ChatHistoryItemContainer
              key={item.id}
              item={item} />
          ))}
        </Bucket>
      ))}
      {view.viewmore && (
        <ViewMoreHistory
          isLoading={historyManager.loading}
          onClick={fetchMoreHistory} />
      )}
      {view.loader && (
        <LoadingWrap>
          <ActivityIndicator />
        </LoadingWrap>
      )}
    </Root>
  );
};

function useHistorySwitcher() {
  const chat = useContext(ActiveChatSessionContext);
  return useMemo(() => chat?.context?.type !== ChatContextType.Global, [chat?.context?.type]);
}

function usePageLabel() {
  const chat = useContext(ActiveChatSessionContext);
  return useMemo(() => {
    switch (chat?.context?.type) {
      case ChatContextType.Document: return `Document`;
      case ChatContextType.Folder: return `Folder`;
      case ChatContextType.Transcript: return `Transcript`;
      case ChatContextType.Global: return `Global`;
      case ChatContextType.KolProfile: return `Profile`;

      default:
        throw new UnreachableCaseError(chat?.context);
    }
  }, [chat?.context]);
}

const Root = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Bucket = styled.div`
  margin-bottom: 20px;
`;

const BucketTitle = styled('div')(({ theme }) => `
  margin-bottom: 5px;
  font-family: ${theme.fonts.semiBold};
  color: ${theme.palette.black.light1};
  white-space: nowrap;
  font-size: 15px;
  padding: 0 ${Constants.Sidebar.HorizontalPadding}px;
`);

const HistoryLabel = styled.h1`
  color: ${props => props.theme.palette.text.primary};
  font-size: 22px;
  font-family: ${props => props.theme.fonts.semiBold};
  padding: 0 ${Constants.Sidebar.HorizontalPadding}px;
  margin-bottom: 10px;
`;

const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  height: 35px;
  box-sizing: border-box;
  margin: 0 ${Constants.Sidebar.ItemHorizontalPadding}px;
`;