import type { ReactNode } from 'react';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as $api from '@/api';
import { LinkContext } from '@/components/BrandInsights/context';
import { ActiveChatSessionContext, AuthorContext } from '@/components/Chat';
import { ChatStateDispatchContext } from '@/components/Chat.State';
import { FetchMoreHistoryContext, ChatHistoryManagerContext, ChatHistoryItemsContext } from './context';

type Props = {
  children: ReactNode;
};

export const ChatHistoryContainer = (props: Props) => {
  const historyManager = useContext(ChatHistoryManagerContext);
  const historyItems = useContext(ChatHistoryItemsContext);
  const dispatch = useContext(ChatStateDispatchContext);

  const author = useContext(AuthorContext);
  const chat = useContext(ActiveChatSessionContext);

  const link = useContext(LinkContext);

  const getIncludeAdditional = useCallback(() => {
    if (!link?.initialized && link?.chatIdentifier && !historyItems.map(i => i.identifier).includes(link.chatIdentifier)) {
      return [link.chatIdentifier];
    }
  }, [historyItems, link?.initialized, link?.chatIdentifier]);

  const queryKey = useMemo(() => {
    return [`chat:get:history`, {
      mode: historyManager.mode,
      cursor: historyManager.cursor,
      pageSize: 20,
      userId: author.id,
      includeAdditional: getIncludeAdditional(),
    }, chat.context] as const;
  }, [historyManager.mode, historyManager.cursor, author.id, getIncludeAdditional, chat.context]);

  const { refetch } = useQuery({
    queryKey, queryFn: ({ queryKey }) => {
      dispatch({ type: 'chat-loading-started' });
      return $api.getChatHistory({
        mode: queryKey[1].mode,
        cursor: queryKey[1].cursor,
        pageSize: queryKey[1].pageSize,
        userId: queryKey[1].userId,
        includeAdditional: queryKey[1].includeAdditional,
        context: queryKey[2],
      });
    },
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 1,
  });

  const fetchHistory = useCallback(() => {
    refetch().then(({ data }) => {
      const cursor = data.items.length ? data.items[data.items.length - 1].id : null;
      dispatch({
        type: queryKey[1].cursor ? 'chat-history-page-loaded' : 'chat-history-loaded',
        payload: {
          cursor,
          hasMore: data.hasMore,
          items: data.items,
          shared: data.shared,
        },
      });
    }).catch(() => {
      dispatch({ type: 'chat-loading-errored' });
    });
  }, [dispatch, refetch, queryKey]);

  useEffect(() => {
    if (!historyManager.initialized) {
      fetchHistory();
    }
  }, [historyManager.mode, historyManager.initialized, queryKey, fetchHistory]);

  return (
    <FetchMoreHistoryContext.Provider value={refetch}>
      {props.children}
    </FetchMoreHistoryContext.Provider>
  );
};