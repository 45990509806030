import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { HorizonalTrinity } from '@/components/Branding/Logo';
import { AuthStatus } from './Auth';

export const NavBar = () => {

  return (
    <>
      <Root>
        <Wrap>
          <Link to="/">
            <HorizonalTrinity />
          </Link>
          <AuthStatus />
        </Wrap>
      </Root>
      <TopPadding />
    </>
  );
};

const navbar = {
  height: 60,
  maxWidth: 1280,
};

const Root = styled.div(({ theme }) => ({
  top: 0,
  width: '100%',
  position: 'fixed',

  boxShadow: '0 0 0 0 #0000001a,0 1px 0 0 #0000001a,0 0 0 0 #0000001f',

  backgroundColor: 'white',

  borderBottom: `1px solid ${theme.palette.gray.light2}`,
}));

const Wrap = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 10px',
  height: navbar.height,

  maxWidth: navbar.maxWidth,
  margin: '0 auto',
});

const TopPadding = styled.div({
  height: navbar.height,
});