export enum ChatContextType {
  // Brand = 'brand',
  Document = 'document',
  Folder = 'folder',
  Global = 'global',
  // Project = 'project',
  Transcript = 'transcript',
  KolProfile = 'kol-profile',
}

export enum ChatInstanceItemType {
  Query = 1,
  // FileUpload = 2,
  KolSearch = 3,
}

export enum ChatFileUploadStatus {
  Errored = 'errored',
  Initiated = 'initiated',
  Started = 'started',
  Ready = 'ready',
  Uploaded = 'uploaded',
}

export enum ChatHintType {
  AnalysisTags = 'analysis-tags',
  FindQuotes = 'find-quotes',
  Text = 'text',
}

export enum ChatCitationType {
  KolProfile = 'kol-profile',
  Document = 'document',
  Transcript = 'transcript',
  CrmNote = 'crm-note',
}

export enum ChatQueryStatus {
  Error = 'error',
  Loading = 'loading',
  LoadingExtended = 'loading-extended',
  Complete = 'complete',
  FollowupsAvailable = 'followups-available',
}