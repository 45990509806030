import { useMemo, useState } from 'react';
import { MediaOrientation } from '@/enums';
import { useDimensions } from '@/hooks/useDimensions';

type Params = {
  horizontal: string;
  square: string;
};

export const useNavbarLogo = (logos: Params) => {
  const { width } = useDimensions();

  return useMemo(() => {
    const horizontal = {
      orientation: MediaOrientation.Horizontal,
      url: logos?.horizontal,
    };

    const square = {
      orientation: MediaOrientation.Square,
      url: logos?.square,
    };

    if (width >= 800) {
      const large = horizontal.url
        ? horizontal
        : square.url
          ? square
          : null;

      return large;
    } else {
      const small = square.url
        ? square
        : null;

      return small;
    }
  }, [
    logos,
    width,
  ]);
};