import type { Dispatch, SetStateAction } from 'react';
import { useCallback } from 'react';
import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import { Chip, PrimaryButton, Textarea } from '@/components/presentation';
import type { StructuredFeedback } from '@/enums/structured-feedback';
import { StructuredFeedbackNameMap } from '@/enums/structured-feedback';
import type { Chat } from '@/types';
import type { BaseModalProps } from '@/hooks/useModal';
import { useModal } from '@/hooks/useModal';
import { useUpdateQueryFeedbackMutation } from './hooks';
import { StarRating } from './StarRating';

type Props = {
  chatInstanceId: number;
  form: Chat.QueryFeedback;
  setForm: Dispatch<SetStateAction<Chat.QueryFeedback>>;
  queryIdentifier: string;
} & BaseModalProps;

export const QueryFeedbackModal = ({ chatInstanceId, queryIdentifier, open, onClose, form, setForm }: Props) => {

  const mutation = useUpdateQueryFeedbackMutation({
    chatInstanceId,
    queryIdentifier,
    onSuccess: onClose,
  });

  const handleNotesChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setForm(f => ({
      ...f,
      notes: event.target.value,
    }));
  }, [setForm]);

  const handleRatingChange = useCallback((value: Chat.Rating) => {
    setForm(f => ({
      ...f,
      rating: value,
    }));
  }, [setForm]);

  const handleItemToggled = useCallback((v: StructuredFeedback) => () => {
    setForm(form => {
      const selected = form.structuredFeedback.includes(v);
      const structuredFeedback = selected
        ? form.structuredFeedback.filter(f => f !== v)
        : [...form.structuredFeedback, v];
      return {
        ...form,
        structuredFeedback,
      };
    });
  }, [setForm]);

  const handleSubmit = useCallback(() => {
    return mutation.mutateAsync(form);
  }, [form, mutation]);

  const renderStructuredFeedback = useCallback(() => {
    return (
      <ChipsContainer>
        {Object.keys(StructuredFeedbackNameMap).map(enumString => {
          const item = +enumString as StructuredFeedback;
          const selected = form.structuredFeedback.includes(item);
          return (
            <StyledChip
              key={item}
              selected={selected}
              onClick={handleItemToggled(item)}>
              {StructuredFeedbackNameMap[item]}
            </StyledChip>
          );
        })}
      </ChipsContainer>
    );
  }, [
    handleItemToggled,
    form.structuredFeedback,
  ]);

  const canSubmit = !!form.rating && !mutation.isLoading;

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      onClose={onClose}
      open={open}>

      <Title>Provide Feedback</Title>

      <DialogContent>

        <Section>
          <SectionHeader>Overall Quality</SectionHeader>
          <StarRating
            value={form.rating}
            onChange={handleRatingChange} />
        </Section>

        <Section>
          <SectionHeader>Please tell us why you didn&apos;t like the answer</SectionHeader>
          {renderStructuredFeedback()}
        </Section>

        <Section>
          <SectionHeader>Additional Feedback</SectionHeader>
        </Section>

        <Box
          component="form"
          noValidate
          autoComplete="off">
          <Textarea
            minRows={5}
            onChange={handleNotesChange}
            value={form.notes} />
        </Box>
      </DialogContent>

      <Footer>
        <PrimaryButton disabled={!canSubmit} onClick={handleSubmit}>Save</PrimaryButton>
      </Footer>

    </Dialog>
  );
};

const Title = styled(DialogTitle)`
  margin-bottom: 10px;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 22px;
  text-align: center;
  color: ${({ theme }) => theme.palette.black.main};
`;

const SectionHeader = styled('div')`
  margin-bottom: 10px;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: 18px;
`;

const Section = styled('div')`
  margin-bottom: 20px;
`;

const StyledChip = styled(Chip)`
  margin-right: 10px;
  margin-bottom: 10px; 
`;

const ChipsContainer = styled('div')`

`;

const Footer = styled(DialogActions)`
  display: 'flex';
  justify-content: center;
`;

export const useFeedbackModal = () => useModal(QueryFeedbackModal);