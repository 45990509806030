/** @jsxImportSource @emotion/react */
import { useCallback, useContext, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { Constants } from '@/components/Theme';
import { Tooltip } from '@/components/presentation';
import type { Chat } from '@/types/chat';
import { AssignCitationRefContext, OpenCrmNoteContext, OpenSnippetContext, SetOpenCrmNoteContext } from '@/components/Chat/context';
import { CitationNumber } from '@/components/presentation/CitationNumber';
import { ChatCitationType } from '@/enums';
import { toAlphabetic, toTitleCase } from '@/utils';
import { useDownloadKolProfile } from '@/components/Chat/hooks/useDownloadKolProfile';
import { ReportFormat } from '@/enums/reports';
import { MessageCitationMaybeLink } from './Citation.MaybeLink';
import { MessageCitationScore } from './Citation.Score';
import { MessageCitationSnippetGroupSelector } from './Citation.SnippetGroupSelector';
import { MessageCitationSnippet } from './Citation.Snippet';
import { getSnippetLink, getSnippetDisplayValue } from './utils';

type Props = {
  className?: string;
  item: Chat.Citation;
};

function buildCitationKey(item: Chat.Citation) {
  return `${item.type}-${item.id}`;
}

export const MessageCitation = ({ className, item }: Props) => {

  const ref = useRef<HTMLDivElement>(null);

  const [openSnippet, handleSnippetSelection] = useContext(OpenSnippetContext);
  const assignCitationRef = useContext(AssignCitationRefContext);

  const openCrmNote = useContext(OpenCrmNoteContext);
  const setOpenCrmNote = useContext(SetOpenCrmNoteContext);

  const highestScore = useMemo(() => {
    if (!item.snippets.length) return null;
    return Math.max(...item.snippets.map(snippet => snippet.score || 0));
  }, [item.snippets]);

  const onSnippetSelected = useCallback((snippetId: string) => () => {
    handleSnippetSelection([buildCitationKey(item), snippetId]);
  }, [handleSnippetSelection, item]);

  const selectedSnippet = useMemo(() => {
    if (!openSnippet) return null;
    const itemKey = buildCitationKey(item);
    if (itemKey !== openSnippet[0]) return null;

    return item.snippets.find(snippet => snippet.id === openSnippet[1]);
  }, [item, openSnippet]);

  const setCitationRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      const itemKey = buildCitationKey(item);
      assignCitationRef(itemKey, node);
      ref.current = node;
    }
  }, [assignCitationRef, item]);

  return (
    <Root className={className} ref={setCitationRef}>
      <Wrap>
        <Header>
          {!!item.ordinal && <CitationOrdinal>{item.ordinal}</CitationOrdinal>}
          <Layers>
            <Layer1>
              <FlexRow>
                <StyledLink to={item.link}>
                  <Tooltip
                    title={item.title}
                    enterDelay={1000}
                    enterNextDelay={500}>
                    <Title>{item.title}</Title>
                  </Tooltip>
                </StyledLink>
                {item.type === ChatCitationType.KolProfile && item.metadata && (
                  <DownloadProfile data={item.metadata as Chat.KolCitationMetadata} />
                )}
                {item.type === ChatCitationType.CrmNote && item.metadata && (
                  <ToggleCrmNote
                    id={item.id}
                    openCrmNote={openCrmNote}
                    setOpenCrmNote={setOpenCrmNote} />
                )}
              </FlexRow>
              {!!highestScore && <MessageCitationScore score={highestScore} />}
            </Layer1>
            <Layer2>
              {item.type === ChatCitationType.CrmNote && item.metadata && openCrmNote === item.id && (
                <FlexRow>
                  <CrmNote data={item.metadata as Chat.CrmNoteCitationMetadata} />
                </FlexRow>
              )}
              {!!item.snippets.length && (
                <SnippetGroupSelectorContainer>
                  {item.snippets.map((snippet, i) => {
                    const isSelected = selectedSnippet?.id === snippet.id;
                    const displayValue = getSnippetDisplayValue(snippet, item.type);
                    return (
                      <SnippetWrapper key={i}>
                        {!!snippet.ordinal && <SnippetOrdinal>{toAlphabetic(snippet.ordinal).toLocaleLowerCase()}</SnippetOrdinal>}
                        <MessageCitationSnippetGroupSelector
                          value={displayValue}
                          selected={isSelected}
                          onClick={onSnippetSelected(snippet.id)} />
                      </SnippetWrapper>
                    );
                  })}
                </SnippetGroupSelectorContainer>
              )}
            </Layer2>
          </Layers>
        </Header>
        {selectedSnippet && (
          <MessageCitationSnippet
            citationType={item.type}
            item={selectedSnippet}
            to={getSnippetLink(item, selectedSnippet)} />
        )}
      </Wrap>
    </Root>
  );
};

type DownloadProfileProps = {
  data: Chat.KolCitationMetadata;
};

const DownloadProfile = ({ data }: DownloadProfileProps) => {

  const { download } = useDownloadKolProfile({
    kol: {
      id: data.kolId,
      name: data.kolName,
    },
  });

  const handleDownload = useCallback(() => {
    download({
      title: `Generating Export`,
      name: `${data.kolName} Profile`,
      extension: ReportFormat.Word,
    });
  }, [download, data.kolName]);

  return (
    <StyledDownloadButton
      onClick={handleDownload}>
      [Download Profile]
    </StyledDownloadButton>
  );
};

type ToggleCrmNoteProps = {
  id: string;
  openCrmNote: string;
  setOpenCrmNote: React.Dispatch<React.SetStateAction<string>>;
};

const ToggleCrmNote = ({ id, openCrmNote, setOpenCrmNote }: ToggleCrmNoteProps) => {
  const handleToggle = useCallback(() => {
    setOpenCrmNote(prev => prev === id ? null : id);
  }, [id, setOpenCrmNote]);

  return (
    <StyledDownloadButton
      onClick={handleToggle}>
      [{openCrmNote === id ? 'Hide' : 'Show'} Interaction]
    </StyledDownloadButton>
  );
};

type CrmNoteProps = {
  data: Chat.CrmNoteCitationMetadata;
};

const CrmNote = ({ data }: CrmNoteProps) => {
  return (
    <Container css={{ margin: '10px 0 0 0', width: '100%' }}>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`MSL`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.accountName}</Attribute></AttributeContainer>
      </ItemContainer>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`HCP Name`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.kolName}</Attribute></AttributeContainer>
      </ItemContainer>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`Topics`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{[data.therapeuticArea, data.therapeuticSubArea].join(', ')}</Attribute></AttributeContainer>
      </ItemContainer>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`Sentiment`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{toTitleCase(data.sentiment)}</Attribute></AttributeContainer>
      </ItemContainer>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`Practice Setting`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.practiceSetting}</Attribute></AttributeContainer>
      </ItemContainer>
      <ItemContainer>
        <AttributeContainer><LabelAttribute>{`Note`}</LabelAttribute></AttributeContainer>
        <AttributeContainer><Attribute>{data.note}</Attribute></AttributeContainer>
      </ItemContainer>
    </Container>
  );
};

const Root = styled.div`
  box-sizing: border-box;
`;

const Wrap = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div({
  padding: `12px 5px`,
  display: 'flex',
  alignItems: 'flex-start',
});

const Layer1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Layer2 = styled.div({});

const FlexRow = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const Title = styled.div`
  font-family: ${props => props.theme.fonts.semiBold};
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const SnippetGroupSelectorContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;

  margin-top: 10px;
`;

const SnippetWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const StyledLink = styled(MessageCitationMaybeLink)`
  width: calc(100% - ${Constants.Message.CitationScoreWidth + 5}px);
`;

const Layers = styled.div({
  width: 'calc(100% - 30px)',
  display: 'flex',
  flexDirection: 'column',
});

const CitationOrdinal = styled(CitationNumber)({
  marginRight: 10,
});

const SnippetOrdinal = styled(CitationOrdinal)({
  marginRight: 5,
});

const StyledDownloadButton = styled.div(({ theme }) => ({
  marginLeft: 5,
  cursor: 'pointer',
  color: theme.palette.hyperlink,
  fontFamily: theme.fonts.semiBold,
  fontSize: 13,
}));

const ItemContainer = styled.div(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'grid',
  gridTemplateColumns: '125px auto',
}));

const AttributeContainer = styled.div(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(var(--column-width-min), 1fr))',
}));

const Container = styled.ol(({ theme }) => ({
  boxSizing: 'border-box',
  border: `2px solid ${theme.palette.gray.light1}`,
  borderRadius: 4,
  margin: 0,
  padding: 0,
}));

const Attribute = styled.div(({ theme }) => ({
  boxSizing: 'border-box',
  borderRight: `1px solid ${theme.palette.gray.light2}`,
  borderBottom: `1px solid ${theme.palette.gray.light2}`,
  padding: 8,
  fontSize: 14,
  overflow: 'hidden',
  height: '100%',
}));

const LabelAttribute = styled(Attribute)(({ theme }) => ({
  backgroundColor: theme.palette.gray.light3,
  fontFamily: theme.fonts.semiBold,
  fontSize: 14,
}));