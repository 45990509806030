import * as React from 'react';
import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  enabled: process.env.ERROR_TRACKING as unknown as boolean,
  environment: process.env.APP_ENV,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.dedupeIntegration(),
    Sentry.replayIntegration(),
  ],
  sampleRate: 1,
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  // tracesSampleRate: process.env.APP_ENV === 'production' ? 0.2 : 0.5,
  release: process.env.SENTRY_RELEASE,
});