import { memo, useMemo } from 'react';
import styles from './style/AnimatedLoader.css';

type Props = {
  className?: string;
  color?:     string;
  size?:      number;
};

const AnimatedLoader = memo(({
  className,
  color = 'var(--gray)',
  size = 15,
}: Props) => {

  const styleProps = useMemo(() => {
    return {
      backgroundColor: color,
      height: size,
      width: size,
    };
  }, [color, size]);

  return (
    <div className={className}>
      <span className={styles.first} style={styleProps} />
      <span className={styles.second} style={styleProps} />
      <span className={styles.third} style={styleProps} />
    </div>
  );
});

export type AnimatedLoaderProps = Props;

export { AnimatedLoader };
export default AnimatedLoader;