import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import * as $session from '@/auth/session';
import { sharedActions } from './shared-actions';

export const appStateSlice = createSlice({
  name: 'app-state',
  initialState: {
    authenticated: $session.isAuthenticated(),
    error: false,
    initialized: false,
    initializing: false,
  },
  reducers: {
    authChange: state => {
      return {
        ...state,
        authenticated: $session.isAuthenticated(),
      };
    },
    appStateLoadStarted: state => {
      return {
        ...state,
        error: false,
        initialized: false,
        initializing: true,
      };
    },
    appStateLoadError: (state, action) => {
      return {
        ...state,
        error: true,
        initialized: false,
        initializing: false,
      };
    },
    logout: (state, action: PayloadAction) => {
      const authenticated = $session.isAuthenticated();

      if (authenticated) {
        $session.destroy();

        return {
          error: false,
          authenticated: false,
          initialized: false,
          initializing: false,
        };
      }

      return state;
    },
  },
  extraReducers: builder => {
    builder.addCase(sharedActions.appStatedLoaded, state => {
      return {
        ...state,
        error: false,
        initialized: true,
        initializing: false,
      };
    });
  },
});

export default appStateSlice.reducer;