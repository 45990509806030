/** @jsxImportSource @emotion/react */
import { memo, useCallback, useEffect, useState, useMemo } from 'react';
import { ExternalLink } from 'react-feather';
import styled from '@emotion/styled';
import type { KolSearchRecommendation } from '@/types/kol';
import * as Presentation from '@/components/presentation/Message.QueryHint/presentation';
import { TextQueryHint } from '@/components/presentation/Message.QueryHint';
import { TablePagination } from '@/components/presentation/TablePagination';
import { Tooltip } from '@/components/presentation/Tooltip';
import { buildKolMailTo } from '@/utils';
import { createOneTapLink, formatPhoneNumber } from '@/utils/phone';
import { KolAddress } from './KolSearch.Address';

type Props = {
  className?: string;
  onSelectProfile: (kol: Pick<KolSearchRecommendation, 'id' | 'name'>) => void;
  selectionDisabled?: boolean;
  records: KolSearchRecommendation[];
  pageSize?: number;
};

export const KolResultsTable = ({ className, onSelectProfile, selectionDisabled, records, pageSize = 5 }: Props) => {
  const { setTotal, ...pagination } = useTablePagination({
    index: 0,
    pageSize,
    total: records?.length,
  });

  useEffect(() => {
    setTotal(records?.length);
  }, [
    setTotal,
    records?.length,
  ]);

  const page = useMemo(() => {
    const start = pagination.index * pagination.size;
    const end = start + pagination.size;

    const items = (records || []);
    return items.slice(start, end);
  }, [
    records,
    pagination.index,
    pagination.size,
  ]);

  return (
    <Container className={className}>
      <ItemContainer>
        <NameAttributeContainer>
          <Attribute>Name</Attribute>
        </NameAttributeContainer>
        <AffiliationAttributeContainer>
          <Attribute>Primary Affiliation</Attribute>
        </AffiliationAttributeContainer>
        <AddressAttributeContainer>
          <Attribute>Location</Attribute>
        </AddressAttributeContainer>
        <EmailAttributeContainer>
          <Attribute>Email</Attribute>
        </EmailAttributeContainer>
        <PhoneAttributeContainer>
          <Attribute>Phone</Attribute>
        </PhoneAttributeContainer>
        <ViewProfileAttributeContainer>
          <Attribute>Profile</Attribute>
        </ViewProfileAttributeContainer>
      </ItemContainer>
      {page.map((result, index) => {
        const primaryAfilliation = result.affiliations[0];
        return (
          <ItemContainer key={index}>
            <NameAttributeContainer>
              <Attribute>{result.name}{result.isKol && <KolBadge />}</Attribute>
            </NameAttributeContainer>
            <AffiliationAttributeContainer>
              <AffiliationAttribute>
                {primaryAfilliation ? (
                  <Tooltip
                    title={primaryAfilliation.name}
                    enterDelay={1000}
                    enterNextDelay={500}>
                    <div css={{ overflow: 'hidden' }}>{primaryAfilliation.name}</div>
                  </Tooltip>
                ) : (
                  <PlaceholderText>None</PlaceholderText>
                )}
              </AffiliationAttribute>
            </AffiliationAttributeContainer>
            <AddressAttributeContainer>
              <AddressAttribute>
                {primaryAfilliation ? (
                  <KolAddress
                    city={primaryAfilliation.city}
                    state={primaryAfilliation.state}
                    zip={primaryAfilliation.zip}
                    country={primaryAfilliation.country}
                    street={primaryAfilliation.street} />
                ) : (
                  <KolAddress
                    city={result.city}
                    state={result.state}
                    zip={result.zipCode}
                    country={result.country}
                    street={result.streetAddress} />
                )}
              </AddressAttribute>
            </AddressAttributeContainer>
            <EmailAttributeContainer>
              <EmailAttribute>
                {result.email ? (
                  <Tooltip
                    title={result.email}
                    enterDelay={1000}
                    enterNextDelay={500}>
                    <EmailMailTo
                      href={buildKolMailTo({ email: result.email })}
                      target="_blank"
                      rel="noreferrer">
                      <EmailLinkText>{result.email}</EmailLinkText>
                      <StyledLinkIcon size={14} />
                    </EmailMailTo>
                  </Tooltip>
                ) : (
                  <PlaceholderText>None</PlaceholderText>
                )}
              </EmailAttribute>
            </EmailAttributeContainer>
            <PhoneAttributeContainer>
              <Attribute>
                {result.phone ? (
                  <EmailMailTo
                    href={createOneTapLink(result.phone)}
                    target="_blank"
                    rel="noreferrer">
                    {formatPhoneNumber(result.phone)}
                  </EmailMailTo>
                ) : (
                  <PlaceholderText>None</PlaceholderText>
                )}
              </Attribute>
            </PhoneAttributeContainer>
            <ViewProfileAttributeContainer>
              <ViewProfileAttribute>
                <StyledViewProfileButton
                  disabled={selectionDisabled}
                  onClick={() => onSelectProfile(result)}
                  value="View" />
              </ViewProfileAttribute>
            </ViewProfileAttributeContainer>
          </ItemContainer>
        );
      })}
      {pagination.total > pageSize && (
        <Footer>
          <TablePagination
            canNextPage={!selectionDisabled && pagination.canNextPage}
            canPreviousPage={!selectionDisabled && pagination.canPreviousPage}
            nextPage={pagination.nextPage}
            previousPage={pagination.previousPage}
            pageIndex={pagination.index}
            pageSize={pagination.size}
            totalCount={pagination.total} />
        </Footer>
      )}
    </Container>
  );
};

type TablePaginationProps = {
  index: number;
  pageSize: number;
  total: number;
};

export const useTablePagination = (props: TablePaginationProps) => {
  const [index, setIndex] = useState(props.index);
  const [size, setSize] = useState(props.pageSize);
  const [total, setTotal] = useState(props.total);

  const pageCount = useMemo(() => Math.ceil(total / size), [total, size]);

  const nextPage = useCallback(() => {
    setIndex(prev => Math.min(prev + 1, pageCount - 1));
  }, [pageCount]);

  const previousPage = useCallback(() => {
    setIndex(prev => Math.max(prev - 1, 0));
  }, []);

  return useMemo(() => ({
    index,
    size,
    total,
    setPageSize: setSize,
    setTotal,
    nextPage,
    previousPage,
    canNextPage: index + 1 < pageCount,
    canPreviousPage: index - 1 >= 0,
  }), [
    index, size, total, pageCount,
    setSize, setTotal, nextPage, previousPage,
  ]);
};

const KolBadge = memo(() => (
  <Badge>KOL</Badge>
));

const Badge = styled.span(({ theme }) => {
  const { Text } = Presentation.Colors(theme);
  return {
    marginLeft: 5,
    fontSize: 12,
    fontFamily: theme.fonts.semiBold,
    padding: '1px 4px',
    borderRadius: 6,
    color: theme.palette.primary.main,
    backgroundColor: Text.enabled.backgroundColor,
  };
});

const Container = styled.ol(({ theme }) => ({
  boxSizing: 'border-box',
  border: `2px solid ${theme.palette.gray.light1}`,
  borderRadius: 4,
  margin: 0,
  padding: 0,

  [`> ${String(ItemContainer)}:first-of-type`]: {
    backgroundColor: theme.palette.gray.light2,
    fontFamily: theme.fonts.semiBold,
    fontSize: 14,
  },
}));

const ItemContainer = styled.div(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'grid',
  gridTemplateColumns: '1.0fr 1.4fr 1.25fr 1.3fr 1.0fr 95px',
}));

const AttributeContainer = styled.div(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(var(--column-width-min), 1fr))',
}));

const Attribute = styled.div(({ theme }) => ({
  boxSizing: 'border-box',
  borderRight: `1px solid ${theme.palette.gray.light2}`,
  borderBottom: `1px solid ${theme.palette.gray.light2}`,
  padding: 8,
  fontSize: 14,
  overflow: 'hidden',
  height: '100%',
}));

const StyledLinkIcon = styled(ExternalLink)(({ theme }) => ({
  color: theme.palette.hyperlink,
  transition: 'all 150ms',
  flexShrink: 0,
  marginLeft: 4,
}));

const NameAttributeContainer = styled(AttributeContainer)({
  ['--column-width-min']: '6em',
});

const AffiliationAttributeContainer = styled(AttributeContainer)({
  ['--column-width-min']: '7em',
});

const AddressAttributeContainer = styled(AttributeContainer)({
  ['--column-width-min']: '7em',
});

const EmailAttributeContainer = styled(AttributeContainer)({
  ['--column-width-min']: '7em',
});

const PhoneAttributeContainer = styled(AttributeContainer)({
  ['--column-width-min']: '9em',
});

const ViewProfileAttributeContainer = styled(AttributeContainer)({
  maxWidth: 85,
});

const ViewProfileAttribute = styled(Attribute)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
}));

const StyledViewProfileButton = styled(TextQueryHint)({
  minWidth: 75,
  maxWidth: 75,
});

const AffiliationAttribute = styled(Attribute)({
  display: 'flex',
  overflow: 'hidden',
  height: '3.7em',
});

const AddressAttribute = styled(Attribute)({
  display: 'flex',
  alignItems: 'center',
  transition: 'all 150ms',
  height: '3.7em',
});

const EmailAttribute = styled(Attribute)({
  display: 'flex',
  alignItems: 'center',
  transition: 'all 150ms',
  height: '3.7em',
});

const EmailMailTo = styled.a(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  transition: 'all 150ms',
  overflow: 'hidden',
  height: '100%',

  ':hover': {
    color: theme.palette.hyperlink,
  },

  [`> ${String(StyledLinkIcon)}`]: {
    opacity: 0,
  },

  [`:hover > ${String(StyledLinkIcon)}`]: {
    opacity: 1,
  },
}));

const EmailLinkText = styled.div({
  height: '100%',
  wordBreak: 'break-all',
});

const Footer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: 4,
});

const PlaceholderText = styled.div(({ theme }) => ({
  color: theme.palette.gray.main,
}));