import { createContext } from 'react';
import type { Chat, ChatHistory } from '@/types';

type ChatHistoryItemContextValue = {
  isActive: boolean;
  item: Chat.Instance;
};

type FetchMoreHistoryContextValue = () => void;

type ChatHistoryManagerContextValue = {
  mode: ChatHistory.Mode;
  cursor: number;
  hasMore: boolean;
  loading: boolean;
  initialized: boolean;
};

// type ChatHistoryContextMenuVisibilityContextValue = [boolean, Dispatch<SetStateAction<boolean>>];

export const ChatHistoryItemsContext = createContext<Chat.Instance[]>(null);
export const ChatHistorySharedContext = createContext<Chat.Instance[]>(null);
export const ChatHistoryItemContext = createContext<ChatHistoryItemContextValue>(null);
export const FetchMoreHistoryContext = createContext<FetchMoreHistoryContextValue>(null);
export const ChatHistoryManagerContext = createContext<ChatHistoryManagerContextValue>(null);
// export const ChatHistoryContextMenuVisibilityContext = createContext<ChatHistoryContextMenuVisibilityContextValue>(null);