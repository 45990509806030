import axios from 'axios';
import { nullableDate } from '@/utils/date';
// import { csrfHeaderRequestInterceptor } from '@/api/interceptors';
import type { SessionHttp } from './interfaces';

const xhr = axios.create({
  baseURL: process.env.BRAND_INSIGHTS_API_URL,
  withCredentials: true,
});

// xhr.interceptors.request.use(csrfHeaderRequestInterceptor(xhr));

export function oauth2(data: SessionHttp.OAuth2.Params): Promise<SessionHttp.OAuth2.Response> {
  return xhr.post<SessionHttp.OAuth2.Response<string>>(`/auth/oauth2`, data)
    .then(({ data }) => ({
      authenticated: data.authenticated,
      expiry: nullableDate(data.expiry),
      userId: data.userId,
      returnUrl: data.returnUrl,
      endSessionUrl: data.endSessionUrl,
    }));
}

export const getMetadata = <A extends SessionHttp.Metadata.Action>(data: SessionHttp.Metadata.Get.Params<A>): Promise<SessionHttp.Metadata.Get.Response<A>> => {
  return xhr.post(`/auth/metadata`, data)
    .then(res => res.data);
};

export const login = (data: SessionHttp.Login.Request): Promise<SessionHttp.Login.Response<Date>> => {
  return xhr.post<SessionHttp.Login.Response>(`/auth/login`, {
    ...data,
    username: data.email,
  })
    .then(res => ({
      ...res.data,
      expiry: nullableDate(res.data.expiry),
    }));
};

export const logout = (): Promise<void> => {
  return xhr.post(`/auth/logout`, {});
};

export function getSessionInfo(): Promise<SessionHttp.GetSessionInfo.Response> {
  return xhr.get<SessionHttp.GetSessionInfo.Response<string>>(`/auth/session`)
    .then(({ data }) => ({
      authenticated: data.authenticated,
      expiry: nullableDate(data.expiry),
      userId: data.userId,
    }));
}

export function refreshCSRF(): Promise<void> {
  return xhr.get(`/auth/csrf`, {});
}