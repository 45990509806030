import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ChatContextType } from '@/enums/chat';
import type { BrandInsightsCapabilities, Chat } from '@/types';
import { ChatStateContainer } from '@/components/Chat.State';
import { LinkStateContainer } from '@/components/BrandInsights/Link.Container';
import { ChatPageContextDataContext, DispatchPageContextDataContext } from '@/components/Chat/context';
import type { BrandInsightsPageContext } from '@/types';
import { ChatContainer } from '@/components/BrandInsights/Chat.Container';

type Props = {
  author: Chat.Creator;
  capabilities: BrandInsightsCapabilities;
} & ChildrenProps;

type RouteParams = {
  chatIdentifier: string;
};

export const ChatScreenContainer = (props: Props) => {

  const [contextData, setContextData] = useState<BrandInsightsPageContext.Item>({
    type: ChatContextType.KolProfile,
    data: { id: null },
    metadata: { name: '' },
  });

  return (
    <DispatchPageContextDataContext.Provider value={setContextData}>
      <ChatPageContextDataContext.Provider value={contextData}>
        <ChatStateContainer>
          <ChatIdentifierContainer>
            <ChatContainer author={props.author} capabilities={props.capabilities}>
              {props.children}
            </ChatContainer>
          </ChatIdentifierContainer>
        </ChatStateContainer>
      </ChatPageContextDataContext.Provider>
    </DispatchPageContextDataContext.Provider>
  );
};

function ChatIdentifierContainer(props: ChildrenProps) {
  const { chatIdentifier } = useParams<RouteParams>();

  return (
    <LinkStateContainer initialChatIdentifier={chatIdentifier}>
      {props.children}
    </LinkStateContainer>
  );
}