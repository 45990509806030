// eslint-disable-next-line import/newline-after-import
require('./sentry');
import { withProfiler } from '@sentry/react';
import Toast from '@/components/Toast';
import '@/static/css/global/style.css';
import { DocumentHelmet } from './Helmet';
import { Layout } from './Layout';

const UnprofiledApp = () => {
  return (
    <>
      <DocumentHelmet />
      <Layout />
      <Toast />
    </>
  );
};

export const App = withProfiler(UnprofiledApp);