import { useQuery } from '@tanstack/react-query';
import * as $api from '@/api/chat';
import type { ChatCustomFilter } from '@/enums/chat.filters';
import useDebounceValue from '@/hooks/useDebounceValue';

type Props = {
  excluded: (number | string)[];
  type: ChatCustomFilter;
  value: string;
};

export const useFilterSearchQuery = (props: Props) => {

  const debouncedProps = useDebounceValue(props, 300);

  const query = useQuery({
    queryKey: ['get:chat/filters', debouncedProps],
    queryFn: ({ queryKey: [_, data] }) => {
      return $api.searchChatFilters(data as Props);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    placeholderData: {
      results: [],
    },
  });

  return query;
};