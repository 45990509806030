import { useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { ChatInstance } from '@/components/Chat';
import { GetStartedContext, ChatGetStarted } from '@/components/Chat.GetStarted';
import { ChatHeader } from '@/components/Chat.Header';
import { AppThemingContext } from '@/containers/AppTheming/Context';
import chatBackgroundImg from '@/static/images/chat-background.png';
import { useAppSelector } from '@/store';
import { Permission } from '@/enums/permission';
import { ChatScreenContainer } from './Container';
import { ChatFooter, FooterHeight } from './Footer';
import { ChatSidebar } from './Sidebar';
import { useMonitorChatIdentifier } from './hooks';

const BrandInsightsChatScreen = () => {

  // const [showGetStarted, dismissGetStarted] = useContext(GetStartedContext);

  useMonitorChatIdentifier();

  const { theme } = useContext(AppThemingContext);

  const style = theme?.enabled ? {
    backgroundImage: 'none',
    backgroundColor: '#fafafa',
  } : {};

  return (
    <Root>

      <MainChat>
        <ChatSidebar />

        <Wrap style={style}>
          <ChatHeader />

          <ChatInstance />

          {/* {showGetStarted
            ? <StyledGetStarted onClick={dismissGetStarted} />
            : <ChatInstance />} */}

        </Wrap>
      </MainChat>

      {/* <ChatFooter /> */}
    </Root>
  );
};

export const ChatScreen = () => {

  const author = useAppSelector(state => state.user);

  const capabilities = useMemo(() => ({
    canApplyFilters: author.permissions.includes(Permission.ApplyFilters),
    canViewTraces: author.permissions.includes(Permission.ViewChatTraces),
    canViewExternalLinks: true,
  }), [author.permissions]);

  return (
    <ChatScreenContainer author={author} capabilities={capabilities}>
      <BrandInsightsChatScreen />
    </ChatScreenContainer>
  );
};

const Root = styled.div({
  height: `calc(100vh - var(--navbar-height))`,
  width: '100%',
});

const Wrap = styled.div({
  boxSizing: 'border-box',
  width: '100%',
  background: `url(${chatBackgroundImg}) no-repeat`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
});

const StyledGetStarted = styled(ChatGetStarted)(({
  marginTop: 30,
}));

const MainChat = styled.div({
  display: 'flex',
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  // height: `calc(100% - ${FooterHeight}px)`,
});