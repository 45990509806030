import { memo } from 'react';
import styled from '@emotion/styled';
import Skeleton from '@mui/material/Skeleton';
import { Constants } from '@/components/Theme';

type Props = {
  className?: string;
};

export const ItemPlaceholder = memo(({ className }: Props) => {
  return (
    <Root className={className}>
      <Wrap>
        <StyledSkeleton
          variant="rectangular"
          width="100%"
          height={86} />
      </Wrap>
    </Root>
  );
});

const Root = styled.div`
  margin-bottom: ${Constants.Sidebar.ItemVerticalPadding}px;
`;

const Wrap = styled.div`
  box-sizing: border-box;
  padding: ${Constants.Sidebar.ItemVerticalPadding}px ${Constants.Sidebar.ItemHorizontalPadding}px;
`;

const StyledSkeleton = styled(Skeleton)`
  box-sizing: border-box;
  border-radius: 6px;
`;