import { ChatCustomFilter, ChatDateFilter, ChatFilterOperator } from '@/enums/chat.filters';
import type { CustomFilter } from '@/types/chat.filters';
import { toTitleCase } from '@/utils/format';

export function getPracticeSettings() {
  return [
    {
      id: '1',
      name: 'Option 1',
    },
    {
      id: '2',
      name: 'Option 2',
    },
  ];
}

export function getInsightTypes() {
  return [
    'COVID-19',
    'Category Management',
    'Clinical',
    'Competitor Product',
    'Cost/Reimbursement',
    'DE&I',
    'Disease State',
    'Emerging Stakeholders',
    'Healthcare Landscape',
    'Healthcare reform',
    'J&J Product',
    'Med Expert Input',
    'New Business Opportunities',
    'Other',
    'Pipeline / Clinical Development',
    'Quality',
    'RWE',
    'Research',
    'Safety',
    'Tools',
  ].sort((a, b) => a.localeCompare(b)).map((m, i) => ({ id: (i + 1).toString(), name: m }));
}

export function getTherapeuticAreas() {
  return [
    'Cardiovascular',
    'Dermatology',
    'Gastroenterology',
    'Infectious Disease',
    'Infectious Disease - Vaccines',
    'Metabolism',
    'Neuroscience - Mood',
    'Neuroscience - SCZ',
    'Oncology - Genitourinary',
    'Oncology - Hematology',
    'Oncology - Heme',
    'Oncology - Lung',
    'Oncology - Lymphoma/Myeloid',
    'Oncology - Multiple Myeloma',
    'Oncology - Solid',
    'Population Health',
    'Puerto Rico',
    'Pulmonary Hypertension',
    'Retina',
    'Rheumatology',
    'V&ESE',
  ].sort((a, b) => a.localeCompare(b)).map((m, i) => ({ id: (i + 1).toString(), name: m }));
}

enum Sentiment {
  Positive = 'positive',
  Negative = 'negative',
  Neutral = 'neutral',
}

export function getSentimentValues() {
  return [
    Sentiment.Positive,
    Sentiment.Neutral,
    Sentiment.Negative,
  ].map(m => ({ id: m, name: toTitleCase(m) }));
}

export function getCustomFilterOperators(type: ChatCustomFilter) {
  const operatorsMap: Record<ChatCustomFilter, ChatFilterOperator[]> = {
    [ChatCustomFilter.InteractionDate]: [ChatFilterOperator.MoreRecentThan, ChatFilterOperator.PriorTo],
    [ChatCustomFilter.PracticeSetting]: [ChatFilterOperator.Eq, ChatFilterOperator.Neq],
    [ChatCustomFilter.InsightType]: [ChatFilterOperator.Eq, ChatFilterOperator.Neq],
    [ChatCustomFilter.Product]: [ChatFilterOperator.Eq, ChatFilterOperator.Neq],
    [ChatCustomFilter.Source]: [ChatFilterOperator.Eq, ChatFilterOperator.Neq],
    [ChatCustomFilter.TherapeuticArea]: [ChatFilterOperator.Eq, ChatFilterOperator.Neq],
    [ChatCustomFilter.TherapeuticSubArea]: [ChatFilterOperator.Eq, ChatFilterOperator.Neq],
    [ChatCustomFilter.Sentiment]: [ChatFilterOperator.Eq, ChatFilterOperator.Neq],
  };

  return operatorsMap[type] || [];
}

export function getDefaultOperator(type: ChatCustomFilter) {
  return getCustomFilterOperators(type)[0];
}

export function getDefaultCustomFilterValue<T extends ChatCustomFilter>(type: T): CustomFilter.Item {
  switch (type) {
    case ChatCustomFilter.InteractionDate:
      return {
        type: ChatCustomFilter.InteractionDate,
        operator: ChatFilterOperator.MoreRecentThan,
        value: ChatDateFilter.Last30Days,
      };

    case ChatCustomFilter.PracticeSetting:
    case ChatCustomFilter.InsightType:
    case ChatCustomFilter.Product:
    case ChatCustomFilter.Source:
    case ChatCustomFilter.TherapeuticArea:
    case ChatCustomFilter.TherapeuticSubArea:
    case ChatCustomFilter.Sentiment:
      return {
        operator: ChatFilterOperator.Eq,
        value: [],
        type,
      };

    default:
      throw new UnreachableCaseError(type);
  }
}

export function validateCustomFilterValue<T extends ChatCustomFilter>(value: CustomFilter.Item<T>) {
  switch (value.type) {
    case ChatCustomFilter.InteractionDate:
      return !!value.value;

    case ChatCustomFilter.PracticeSetting:
    case ChatCustomFilter.InsightType:
    case ChatCustomFilter.Product:
    case ChatCustomFilter.Source:
    case ChatCustomFilter.TherapeuticArea:
    case ChatCustomFilter.TherapeuticSubArea:
    case ChatCustomFilter.Sentiment:
      return !!value.value.length;

    default:
      throw new UnreachableCaseError(value);
  }
}