import { useCallback, useContext } from 'react';
import type { Chat } from '@/types';
import {
  ChatSessionSelectedQueryContext,
} from '../context';

export const useSelectedQueryParentIdentifier = () => {
  const selectedQuery = useContext(ChatSessionSelectedQueryContext);

  return useCallback(() => {
    return getParentQueryIdentifier(selectedQuery);
  }, [selectedQuery]);
};

function getParentQueryIdentifier(query: Chat.Query) {
  if (!query) return;
  // if (query.status === 'error') {
  //   return query.mapping.parentIdentifier;
  // }
  return query.identifier;
}