/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const authorWrapWidth = 70;
export const authorSize = 50;
export const authorSpacing = 4;
export const messageLaneWidth = `calc(100% - ${(authorWrapWidth + authorSpacing) * 2}px)`;
export const messageLaneLeftPadding = 20;
export const messageLaneRightPadding = 27;

export const messageWidth = 450;
export const extendedMessageWidth = 550;
export const fullChatExtendedMessageWidth = 700;

const authorWrapCss = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: authorWrapWidth,
});

const authorCss = css({
  width: authorSize,
  height: authorSize,
  boxSizing: 'border-box',
});

const rootCss = css({
  display: 'flex',
  alignItems: 'flex-start',
});

const bodyCss = css({
  padding: 15,
  borderRadius: 6,
  maxWidth: messageWidth,
  whiteSpace: 'pre-wrap',
  overflowWrap: 'break-word',
  boxSizing: 'border-box',
});

export const messageStyles = {
  author: authorCss,
  authorWrap: authorWrapCss,
  body: bodyCss,
  root: rootCss,
};