import type { ChatHistoryState, ChatState } from './interfaces';

export function chatHistoryReducer(state: ChatHistoryState.State, action: ChatState.Action): ChatHistoryState.State {
  return {
    manager: chatHistoryManagerReducer(state.manager, action),
    items: chatHistoryItemsReducer(state.items, action),
    shared: chatHistorySharedReducer(state.shared, action),
  };
}

export function chatHistoryManagerReducer(state: ChatHistoryState.State['manager'], action: ChatState.Action): ChatHistoryState.State['manager'] {
  switch (action.type) {
    case 'instance-context-changed':
      return {
        ...state,
        cursor: null,
        hasMore: false,
        initialized: false,
      };

    case 'chat-history-loaded':
    case 'chat-history-page-loaded':
      return {
        ...state,
        cursor: action.payload.cursor,
        hasMore: action.payload.hasMore,
        loading: false,
        initialized: true,
      };

    case 'chat-history-mode-changed':
      return {
        ...state,
        cursor: null,
        hasMore: false,
        initialized: false,
        mode: action.payload.mode,
      };

    case 'chat-loading-started':
      return {
        ...state,
        loading: true,
        initialized: false,
      };

    case 'chat-loading-errored':
      return {
        ...state,
        loading: false,
        initialized: false,
      };

    default:
      return state;
  }
}

export function chatHistoryItemsReducer(state: ChatHistoryState.State['items'], action: ChatState.Action): ChatHistoryState.State['items'] {

  switch (action.type) {
    case 'instance-context-changed':
    case 'chat-history-mode-changed':
      return [];

    case 'chat-history-loaded':
      return action.payload.items;

    case 'chat-history-page-loaded':
      return state.concat(action.payload.items);

    case 'chat-instance-removed':
      return state.filter(h => h.id !== action.payload.chatInstance.id);

    case 'chat-instance-renamed': {
      return state.reduce((acc, x) => {
        if (x.id === action.payload.chatInstance.id) {
          return acc.concat({
            ...x,
            name: action.payload.chatInstance.name,
          });
        }

        return acc.concat(x);
      }, []);
    }

    case 'new-chat-added':
      return state.concat(action.payload.chatInstance);

    case 'kol-search-submitted':
    case 'query-submitted': {
      const existing = state.find(h => h.identifier === action.payload.chatIdentifier);

      if (existing) {
        return state;
      }

      return [
        {
          id: null,
          identifier: action.payload.chatIdentifier,
          name: 'Placeholder',
          createdOn: new Date(),
          currentQueryIdentifier: null,
          context: null,
          shared: null,
        },
        ...state,
      ];
    }

    case 'kol-search-results-available':
    case 'kol-search-profile-available':
    case 'query-response-available': {
      return state.reduce((acc, item) => {
        if (item.identifier === action.payload.chatInstance.identifier) {
          return acc.concat({
            ...item,
            ...action.payload.chatInstance,
          });
        }
        return acc.concat(item);
      }, []);
    }

    case 'chat-loaded': {
      if (action.payload.chatInstance.shared) {
        return state;
      }

      const existing = state.find(h => h.id === action.payload.chatInstance.id);

      if (existing) {
        return state;
      }

      return state.concat(action.payload.chatInstance);
    }

    default:
      return state;
  }

}

export function chatHistorySharedReducer(state: ChatHistoryState.State['shared'], action: ChatState.Action): ChatHistoryState.State['shared'] {
  switch (action.type) {
    case 'instance-context-changed':
    case 'chat-history-mode-changed':
      return [];

    case 'chat-history-loaded':
      return action.payload.shared;

    case 'chat-history-page-loaded':
      return state.concat(action.payload.shared);

    case 'chat-instance-removed':
      return state.filter(h => h.id !== action.payload.chatInstance.id);

    case 'chat-loaded': {
      if (!action.payload.chatInstance.shared) {
        return state;
      }

      const existing = state.find(h => h.id === action.payload.chatInstance.id);

      if (existing) {
        return state;
      }

      return state.concat(action.payload.chatInstance);
    }

    default:
      return state;
  }
}