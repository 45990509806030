import { createContext } from 'react';
import { Chat } from '@/types';

export type OpenFeedbackModalProps = {
  chatInstanceId: number;
  queryIdentifier: string;
  rating: Chat.Rating;
};

type OpenQueryFeedbackModalContextValue = (props: OpenFeedbackModalProps) => void;

export const OpenQueryFeedbackModalContext = createContext<OpenQueryFeedbackModalContextValue>(null);