import { useEffect, useState } from 'react';
import type { Socket } from 'socket.io-client';
import { SocketNamespace } from '@/enums/websocket';
import { getSocket } from '@/websocket/socket';
import { useSetupDefaultSocketListeners } from './useSetupDefaultListeners';

export const useChatQuerySocket = (queryIdentifier: string) => {
  const [socket, setSocket] = useState<Socket>();

  useEffect(() => {
    if (queryIdentifier) {
      const newSocket = getSocket(SocketNamespace.Queries);

      setSocket(newSocket);

      newSocket.emit('subscribe', queryIdentifier);

      return () => {
        newSocket.emit('unsubscribe', queryIdentifier);
        newSocket.disconnect();
      };
    }
  }, [queryIdentifier]);

  useSetupDefaultSocketListeners(socket, SocketNamespace.Queries);

  return socket;
};