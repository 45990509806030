import './types/index';
import { createRoot } from 'react-dom/client';
import { App } from './app';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const container = document.getElementById('root');

const root = createRoot(container);

root.render(<App />);

serviceWorkerRegistration.unregister();