/** @jsxImportSource @emotion/react */
import { useContext } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { KolSearchStatus } from '@/enums/kol';
import { AnalysisQueryHint } from '@/components/presentation/Message.QueryHint';
import { Spinner } from '@/components/presentation/Spinner';
import {
  KolSearchFormBeginContext,
  KolSearchRecommendationProfileSelectedContext,
  KolSearchRecommendationsContext,
  KolSearchStatusContext,
} from './context';
import { BasicSystemUserMessage } from './Message.System.Basic';
import { KolResultsTable } from './KolSearch.Table';
import { messageLaneRightPadding, messageLaneWidth, messageLaneLeftPadding } from './Message.styles';

export const KolRecommendations = () => {
  const status = useContext(KolSearchStatusContext);
  const selectProfile = useContext(KolSearchRecommendationProfileSelectedContext);
  const formBegin = useContext(KolSearchFormBeginContext);
  const recommendations = useContext(KolSearchRecommendationsContext);

  const copy = getCopy();

  const disabled = {
    profileSelection: status !== KolSearchStatus.ProfileRecommendations,
    search: status !== KolSearchStatus.ProfileRecommendations,
  };

  return (
    <MessageContainer>
      <BasicSystemUserMessage bodyStyles={css({
        minWidth: messageLaneWidth,
        maxWidth: messageLaneWidth,
      })}>
        <Body>
          {copy.header}
          {!recommendations || ['uninitialized', 'loading'].includes(recommendations.status)
            ? <StyledSpinner />
            : <StyledRecommendationsTable
              selectionDisabled={disabled.profileSelection}
              onSelectProfile={selectProfile}
              records={recommendations?.items} />}

          {copy.alt}
          <StyledAnalysisQueryHint
            disabled={disabled.search}
            onClick={formBegin}
            value="I'd like to prepare for an upcoming HCP call." />
        </Body>
      </BasicSystemUserMessage>
    </MessageContainer>
  );
};

function getCopy() {
  return {
    header: `We recommend following up with the following HCPs, given it has been a while since you last interacted with them.`,
    alt: `Or if you're looking for a different HCP, get started with an HCP search.`,
  };
}

const MessageContainer = styled.div({
  paddingLeft: messageLaneLeftPadding,
  paddingRight: messageLaneRightPadding,
  // padding: `15px ${messageLaneRightPadding}px 15px ${messageLaneLeftPadding}px`,
});

const Body = styled.div({
  // width: 725,
});

const StyledRecommendationsTable = styled(KolResultsTable)({
  marginTop: 15,
  marginBottom: 15,
});

const StyledAnalysisQueryHint = styled(AnalysisQueryHint)({
  marginTop: 15,
});

const StyledSpinner = styled(Spinner)({
  margin: '50px 0',
});