/** @jsxImportSource @emotion/react */
import { memo, useMemo } from 'react';
import styled from '@emotion/styled';
import isMobile from 'ismobilejs';
import Skeleton from '@mui/material/Skeleton';
import { SystemAuthor } from './Message.Author';
import { usePlaceholderStyles } from './Message.System.styles';

type Props = {
  className?: string;
  isTakingLong?: boolean;
};

export const SystemUserPlaceholderMessage = memo(({ className, isTakingLong }: Props) => {
  const device = isMobile();
  const styles = usePlaceholderStyles();

  const skeleton = useMemo(() => ({
    width1: 95,
    width3: 60,
  }), []);

  return (
    <div css={styles.root} className={className}>
      {!device.phone && (
        <div css={styles.authorWrap}>
          <SystemAuthor
            animate={true}
            css={styles.author} />
        </div>
      )}
      <div css={styles.body}>
        {isTakingLong && (
          <TakingTooLongMessage>
            {`Your request returned a wide variety of sources, this may take a bit longer.`}
          </TakingTooLongMessage>
        )}
        <div css={styles.skeleton}>
          <Skeleton
            css={styles.skeletonItem}
            variant="text"
            width={`${skeleton.width1}%`} />
          <Skeleton
            css={styles.skeletonItem}
            variant="text"
            width={`${skeleton.width1}%`} />
          <Skeleton
            css={styles.skeletonItem}
            variant="text"
            width={`${skeleton.width3}%`} />
        </div>
      </div>
    </div>
  );
});

const TakingTooLongMessage = styled.div({
  marginBottom: 10,
});
