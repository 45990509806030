import styled from '@emotion/styled';
import { Download } from 'react-feather';
import { BaseMessageButton } from './Message.Button';

type Props = {
  className?: string;
  label?: string;
  onClick: () => void;
};

export const DownloadButton = ({ className, label = 'Download', onClick }: Props) => {

  return (
    <BaseMessageButton className={className} onClick={onClick}>
      <StyledIcon size={18} />
      <ButtonCopy>{label}</ButtonCopy>
    </BaseMessageButton>
  );
};

const ButtonCopy = styled.div({
  marginLeft: 5,
});

const StyledIcon = styled(Download)(({ theme }) => ({
  fontSize: 18,
}));