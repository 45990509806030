import { getAuthenticatedClient } from './client';
import type * as KolApi from './interfaces/kol';
import { transformDates } from './transformers';

export const submitKolSearch = async (data: KolApi.SubmitKolSearch.Request) => {
  const res = await getAuthenticatedClient().post<KolApi.SubmitKolSearch.Response<Date>>(`/chat/${data.chatIdentifier}/kol-search`, {
    identifier: data.identifier,
    form: data.form,
    recommendations: data.recommendations,
  }, {
    transformResponse: transformDates,
  });

  return res.data;
};

export const cancelKolSearch = async (data: KolApi.CancelKolSearch.Request) => {
  await getAuthenticatedClient().patch<KolApi.CancelKolSearch.Response>(`/chat/${data.chatIdentifier}/kol-search/${data.kolSearchId}/cancel`);
};

export const updateKolSearchProfile = async (data: KolApi.UpdateKolSearchProfile.Request) => {
  const res = await getAuthenticatedClient().patch<KolApi.UpdateKolSearchProfile.Response<Date>>(`/chat/${data.chatIdentifier}/kol-search/${data.kolSearchId}/update-profile`, {
    kolId: data.kolId,
  }, { transformResponse: transformDates });
  return res.data;
};

export const downloadKolProfile = async (data: KolApi.DownloadKolProfile.Request) => {
  return getAuthenticatedClient().get<Blob>(`/chat/kol/${data.kolId}/report`, {
    responseType: 'blob',
  });
};

export const getKolSearchRecommendations = async () => {
  const res = await getAuthenticatedClient().get<KolApi.GetKolSearchRecommendations.Response>(`/chat/kol-search/recommendations`, {
    transformResponse: transformDates,
  });
  return res.data;
};