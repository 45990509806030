import { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import { PrimaryButton } from '@/components/presentation';
import { useEnterpriseSubmitMutation } from '@/containers/Auth';
import useEventListener from '@/hooks/useEventListener';

export const EnterpriseLogin = () => {

  const [email, setEmail] = useState('');

  const loginMutation = useEnterpriseSubmitMutation();

  const canSubmit = useMemo(() => {
    return !loginMutation.isLoading && email.includes('@') && email.includes('.');
  }, [email, loginMutation]);

  const handleLogin = useCallback(() => {
    return loginMutation.mutateAsync({
      email,
    });
  }, [email, loginMutation]);

  const handleKeydown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Enter' && canSubmit) {
      handleLogin();
    }
  }, [canSubmit, handleLogin]);

  useEventListener('keydown', handleKeydown);

  const handleEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }, [setEmail]);

  return (
    <div>

      <TextField
        autoFocus
        fullWidth
        placeholder="Enter your email"
        onChange={handleEmailChange}
        value={email}
        variant="standard" />

      <Footer>
        <PrimaryButton
          disabled={!canSubmit}
          onClick={handleLogin}>
          Log In
        </PrimaryButton>
      </Footer>
    </div>
  );
};

const Footer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 20,
});