import { useCallback, useState } from 'react';
import { usePopper } from 'react-popper';
import styled from '@emotion/styled';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { Constants } from '@/components/Theme';
import { useZIndexModifier } from '@/components/presentation/popper-modifiers';
import { DropdownMenu, DropdownMenuAnchor } from '@/components/presentation';
import { useMenuRender } from './SimpleContextSelector.Renderer';

export const SimpleContextSelector = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);

  const zIndexModifier = useZIndexModifier();
  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      zIndexModifier,
    ],
    placement: 'bottom-start',
  });

  const handleBlur = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleItemClick = useCallback(() => {
    handleBlur();
  }, [handleBlur]);

  const menu = useMenuRender({
    onClick: handleItemClick,
  });

  const handleClick = useCallback((e: React.MouseEvent) => {
    if (!menu.menuItems) return;

    setOpen(prev => !prev);
    e.stopPropagation();
  }, [menu.menuItems]);

  return (
    <Root>
      <Wrap>
        <StyledDropdownMenuAnchor
          ref={setReferenceElement}
          onClick={handleClick}
          open={open}>
          {menu.label?.()}
        </StyledDropdownMenuAnchor>
        {menu.detail?.()}
      </Wrap>
      {open && (
        <ClickAwayListener onClickAway={handleBlur}>
          <StyledDropdownMenu
            ref={setPopperElement}
            style={popperStyles.popper}
            {...attributes.popper}>
            {menu.menuItems?.()}
          </StyledDropdownMenu>
        </ClickAwayListener>
      )}
    </Root>
  );
};

const Root = styled.div({});

const Wrap = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const StyledDropdownMenu = styled(DropdownMenu)({
  maxWidth: '400px',
});

const StyledDropdownMenuAnchor = styled(DropdownMenuAnchor)(({ theme }) => ({
  height: Constants.Header.ItemHeight,
  width: '110px',
  color: theme.palette.black.main,
}));