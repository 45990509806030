import { useCallback } from 'react';
import { type UseMutationOptions, useMutation } from '@tanstack/react-query';
import * as api from '@/api';
import type * as App from '@/api/interfaces/app';
import { useQueryDataAccessor } from './useQueryDataAccessor';

export const useUpdateColorMutation = (options: UpdateSurveyPaletteColor.Options = {}) => {
  const mutationFn = useMutationFn();
  const helpers = useMutationHelpers();

  const mutation = useMutation({
    mutationKey: [`put:app/theming/color`],
    mutationFn,
    ...options,
    onMutate: variables => {
      helpers.onMutate(variables);
      options.onMutate?.(variables);
    },
  });

  return mutation;
};

const useMutationFn = () => {
  const accessor = useQueryDataAccessor();

  const updatePaletteColor = (params: UpdateSurveyPaletteColor.Variables) => {
    const data = accessor.getQueryData();

    return api.updateThemeColor({
      ...data.color,
      ...params,
    });
  };

  return useCallback(updatePaletteColor, [
    accessor,
  ]);
};

const useMutationHelpers = () => {
  const accessor = useQueryDataAccessor();

  const onMutate = useCallback((variables: UpdateSurveyPaletteColor.Variables) => {
    accessor.setQueryData(data => {
      return {
        ...data,
        color: {
          ...data.color,
          ...variables,
        },
      };
    });
  }, [accessor]);

  return {
    onMutate,
  };
};

declare namespace UpdateSurveyPaletteColor {
  export type Palette = App.UpdateThemeColor.Request;

  type Color = keyof Palette;

  type ColorParam<C extends Color = Color, V extends Palette[C] = Palette[C]>  = {
    color:   C;
    variant: keyof V;
  };

  export type Variables =
    | { bubble: string }
    | { primary: string }
    | { prompt: string };

  export type Options =
    UseMutationOptions<
      App.UpdateThemeColor.Response,
      unknown,
      Variables>;
}
