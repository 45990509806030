import qs from 'qs';
import type { AxiosError, InternalAxiosRequestConfig } from 'axios';

export function logResponseErrorInterceptor(e: AxiosError) {
  if (!process.env.__DEV__) return;

  const locationString = [
    e.config?.url,
    '',
    '',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    e.request?.data,
    '',
  ].filter(Boolean).join('\n');

  console.error(
    `%cerror %c${e.response?.status} %c${e.response?.statusText}\n\n${locationString}`,
    'color: #646464',
    'color: #5fafff',
    'color: #ffffff',
  );
}

export function logRequestInterceptor(config: InternalAxiosRequestConfig) {
  if (!process.env.__DEV__) return config;

  const pic = {
    patch: '◆',
    post: '◆',
    put: '◆',
    get: '■︎',
    delete: '✕',
  }[config.method];
  const method = config.method.toUpperCase().concat(' '.repeat(7 - config.method.length));
  const q = config.params ? `?${qs.stringify(config.params)}` : '';

  console.info(
    `%c${' '.repeat(8)}[brand-insights] ${method} %c${pic} ${config.url}${q}`,
    'color: #646464; font-weight: bold;',
    'color: #646464;'
  );

  return config;
}