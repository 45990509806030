import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as $api from '@/api';
import { ChatStateDispatchContext } from '@/components/Chat.State';

type Props = {
  chatInstanceId: number;
  onSuccess?: () => void;
};

export const useDeleteChatInstanceMutation = ({ chatInstanceId, onSuccess }: Props) => {
  const dispatch = useContext(ChatStateDispatchContext);

  const mutation = useMutation(['chat:delete'], () => {
    return $api.deleteChatInstance({
      chatInstanceId,
    });
  }, {
    onSuccess: res => {
      onSuccess?.();
      dispatch({
        type: 'chat-instance-removed',
        payload: {
          chatInstance: {
            id: chatInstanceId,
          },
        },
      });
    },
  });

  return mutation;
};