import { forwardRef, memo, useCallback, useContext, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { usePopper } from 'react-popper';
import { MoreHorizontal } from 'react-feather';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LinkIcon from '@mui/icons-material/Link';
import ReplyIcon from '@mui/icons-material/Reply';
import { useZIndexModifier } from '@/components/presentation/popper-modifiers';
import { Portal } from '@/components/Portal';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import { buildBrandInsightsChatLink } from '@/utils';
import { useIsFullPageChat } from '@/components/Chat/hooks';
import { PopperMenu } from '@/components/Popper';
import { ReportFormat } from '@/enums/reports';
import { useDeleteChatModal } from './Modal.DeleteChat';
import { useRenameChatModal } from './Modal.RenameChat';
import { useShareChatModal } from './Modal.ShareChat';
import { useDownloadChatInstance } from './hooks/useDownloadChatInstance';
import { ChatHistoryItemContext } from './context';

type Props = {
  open: boolean;
  refElement: HTMLDivElement;
  setOpen: (open: boolean) => void;
};

type MenuAnchorProps = {
  className?: string;
  open: boolean;
  onClick: (e: React.MouseEvent) => void;
};

export const ChatActionsAnchor = memo(forwardRef<HTMLDivElement, MenuAnchorProps>(({ className, open, onClick }, ref) => (
  <div
    className={className}
    ref={ref}
    onClick={onClick}>
    <Anchor
      open={open} />
  </div>
)));

export const ChatActionsMenu = ({ open, refElement, setOpen }: Props) => {

  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);
  const { item } = useContext(ChatHistoryItemContext);

  const zIndexModifier = useZIndexModifier();

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(refElement, popperElement, {
    modifiers: [
      zIndexModifier,
    ],
    placement: 'bottom-start',
  });

  const [toggleRenameModal, RenameModal] = useRenameChatModal();
  const [toggleDeleteModal, DeleteModal] = useDeleteChatModal();
  const [toggleShareModal, ShareModal] = useShareChatModal();
  const { download } = useDownloadChatInstance({
    chatInstanceId: item.id,
    name: item.name,
  });
  const copy = useCopyToClipboard();
  const [copied, setCopied] = useIconFlipper(2500);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleRenameClick = useCallback((e: React.MouseEvent) => {
    toggleRenameModal();
    setOpen(false);
  }, [setOpen, toggleRenameModal]);

  const handleDeleteClick = useCallback((e: React.MouseEvent) => {
    toggleDeleteModal();
    setOpen(false);
  }, [setOpen, toggleDeleteModal]);

  const handleShareClick = useCallback((e: React.MouseEvent) => {
    toggleShareModal();
    setOpen(false);
  }, [setOpen, toggleShareModal]);

  const handleDownloadClick = useCallback((e: React.MouseEvent) => {
    download({
      title: `Generating Export`,
      name: item.name,
      extension: ReportFormat.Word,
    });
    setOpen(false);
  }, [download, item.name, setOpen]);

  const isStandaloneChat = useIsFullPageChat();

  const handleCopyClick = useCallback((e: React.MouseEvent) => {
    copy(buildBrandInsightsChatLink(({
      chatIdentifier: item.identifier,
      isStandaloneChat,
    })));
    setCopied();
  }, [copy, isStandaloneChat, item.identifier, setCopied]);

  return (
    <>
      {open && (
        <Portal>
          <ClickAwayListener
            onClickAway={handleClose}>
            <div
              ref={setPopperElement}
              style={popperStyles.popper}
              {...popperAttributes.popper}>
              <PopperMenu>
                {!item.shared && (
                  <StyledPopperMenuItem onClick={handleRenameClick}>
                    <StyledRenameIcon /> Rename
                  </StyledPopperMenuItem>
                )}
                <StyledPopperMenuItem onClick={handleDownloadClick}>
                  <StyledDownloadIcon /> Download
                </StyledPopperMenuItem>
                {!item.shared && (
                  <StyledPopperMenuItem onClick={handleShareClick}>
                    <StyledShareIcon /> Share
                  </StyledPopperMenuItem>
                )}
                <StyledPopperMenuItem onClick={handleCopyClick}>
                  {copied
                    ? <StyledDoneIcon />
                    : <StyledLinkIcon />}
                  Copy
                </StyledPopperMenuItem>
                {!item.shared && (
                  <StyledPopperMenuItem onClick={handleDeleteClick}>
                    <StyledDeleteIcon /> Delete
                  </StyledPopperMenuItem>
                )}
              </PopperMenu>
            </div>
          </ClickAwayListener>
        </Portal>
      )}
      <ShareModal chatInstance={item} />
      <DeleteModal chatInstance={item} />
      <RenameModal chatInstance={item} />
    </>
  );
};

type AnchorProps = {
  open: boolean;
};

const Anchor = memo(forwardRef<HTMLDivElement, AnchorProps>(({ open, ...props }, ref) => {
  return (
    <AnchorRoot open={open} ref={ref}>
      <StyledMoreHorizontal />
    </AnchorRoot>
  );
}));

const AnchorRoot = styled('div', {
  shouldForwardProp: prop => prop !== 'open',
})<AnchorProps>(({ theme, open }) => ({
  cursor: 'pointer',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  color: open ? theme.palette.black.light1 : undefined,
  backgroundColor: open ? theme.palette.gray.light2 : undefined,

  ':hover': {
    color: theme.palette.black.light1,
    backgroundColor: theme.palette.gray.light2,
  },
}));

const StyledMoreHorizontal = styled(MoreHorizontal)({
  width: 25,
  height: 25,
  padding: 5,
  boxSizing: 'border-box',
});

const iconMargin = 8;

const StyledRenameIcon = styled(DriveFileRenameOutlineIcon)(({ theme }) => ({
  color: theme.palette.black.light1,
  marginRight: iconMargin,
}));

const StyledDeleteIcon = styled(DeleteIcon)(({ theme }) => ({
  color: theme.palette.black.light1,
  marginRight: iconMargin,
}));

const StyledDownloadIcon = styled(FileDownloadIcon)(({ theme }) => ({
  color: theme.palette.black.light1,
  marginRight: iconMargin,
}));

const StyledLinkIcon = styled(LinkIcon)(({ theme }) => ({
  color: theme.palette.black.light1,
  marginRight: iconMargin,
}));

const StyledShareIcon = styled(ReplyIcon)(({ theme }) => ({
  color: theme.palette.black.light1,
  transform: 'scaleX(-1)',
  marginRight: iconMargin,
}));

const StyledDoneIcon = styled(DoneIcon)(({ theme }) => ({
  color: theme.palette.green.main,
  marginRight: iconMargin,
}));

const StyledPopperMenuItem = styled('div')(({ theme }) => ({
  display: 'flex',
  fontSize: 14,
  alignItems: 'center',
  cursor: 'pointer',
  color: theme.palette.black.light1,
  height: 40,
  boxSizing: 'border-box',
  padding: '0 20px',

  '&:hover': {
    color: theme.palette.sentiment.primary.main,

    // @ts-ignore
    [StyledRenameIcon]: {
      color: theme.palette.sentiment.primary.main,
    },
    // @ts-ignore
    [StyledDeleteIcon]: {
      color: theme.palette.sentiment.primary.main,
    },
    // @ts-ignore
    [StyledDownloadIcon]: {
      color: theme.palette.sentiment.primary.main,
    },
    // @ts-ignore
    [StyledLinkIcon]: {
      color: theme.palette.sentiment.primary.main,
    },
    // @ts-ignore
    [StyledShareIcon]: {
      color: theme.palette.sentiment.primary.main,
    },
  },
}));

export function useIconFlipper(timeoutMs: number) {
  const [flipped, setFlipped] = useState(false);
  const timer = useRef<NodeJS.Timeout>();

  const handleSetFlipped = useCallback(() => {
    setFlipped(true);

    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setFlipped(false);
    }, timeoutMs);
  }, [timeoutMs]);

  return [flipped, handleSetFlipped] as const;
}