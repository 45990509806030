import { useRef, useEffect } from 'react';
import type { AppStorageEvent, BaseStorageProvider } from '@/lib/storage';

export default function useStorageListener(
  storage: BaseStorageProvider,
  handler: (event: AppStorageEvent) => void,
) {
  const savedHandler = useRef<(event: AppStorageEvent) => void>();

  useEffect(() => {
    if (!storage) {
      return;
    }

    if (savedHandler.current !== handler) {
      savedHandler.current = handler;
    }

    const eventListener = (event: AppStorageEvent) => {
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!savedHandler?.current) {
        savedHandler.current(event);
      }
    };

    storage.addListener(eventListener);

    return () => {
      storage.removeListener(eventListener);
    };
  }, [storage, handler]);
}